<script setup lang="ts">
import { useL4Modules } from './useL4Modules'
import WizardContainer from './WizardContainer.vue'
import type { WizardStep } from './useWizard'

const props = defineProps<{
  steps: WizardStep[] | (() => Promise<WizardStep[]>)
}>()

const { redirectToNextModule, redirectToPreviousModule } = useL4Modules()
</script>

<template>
  <WizardContainer :steps="props.steps" :redirectAfterLastStep="redirectToNextModule" :redirectOnQuit="redirectToPreviousModule" />
</template>
