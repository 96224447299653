import AppSettings, { redirectRouteAfterRecover } from '@app/AppSettings'
import router from '@router/index'
import { useBrowserLocation } from '@vueuse/core'
import axios from 'axios'
import semver from 'semver/preload'

import Global from '@shared/utils/global'
import { store } from '@app/store'

import {
  AVAILABLE_STATUS,
  LOCAL_STORAGE_REDIRECT_URL,
  MAINTENANCE_ROUTE,
  ROLE_TYPE,
  UNAVAILABLE_ROUTE,
} from '@shared/data/constants'
import http from '@app/utils/http-client'
import type { Router } from 'vue-router'

let newHealthcheckEndpointWorks = true
const newVersionAlertShowed = false
export const SYSTEM_AVAILABILITY_POLLING_DELAY = 10000 // ms
export const FAST_SYSTEM_AVAILABILITY_POLLING_DELAY = 5000 // ms

interface StatusObject {
  client_version: string
  ui: {
    maintenance: boolean
    readonly: boolean
  }
}

export default class SystemAvailabilityService {
  static available: Ref<AVAILABLE_STATUS> = ref<AVAILABLE_STATUS>(AVAILABLE_STATUS.ONLINE)
  static statusObject = reactive<StatusObject>({ client_version: '', ui: { maintenance: false, readonly: false } })

  static pollingTimer: number = Number.NaN

  static async isStrapiAvailable(): Promise<AVAILABLE_STATUS> {
    let response
    let result: AVAILABLE_STATUS = AVAILABLE_STATUS.OFFLINE

    if (newHealthcheckEndpointWorks) {
      try {
        response = await http.get<StatusObject>(http.defaults.baseURL ? `${http.defaults.baseURL}/status` : 'localhost:1337/api/status', { cache: false })
        result = response.status < 400 && response.status !== 0 ? AVAILABLE_STATUS.ONLINE : AVAILABLE_STATUS.OFFLINE

        SystemAvailabilityService.statusObject.client_version = response.data.client_version
        SystemAvailabilityService.statusObject.ui.maintenance = response.data.ui.maintenance
        SystemAvailabilityService.statusObject.ui.readonly = response.data.ui.readonly

        // check for client version
        const currentClientVersion = `v${PORTAL_VERSION}`
        if (
          Global.notification
          && !newVersionAlertShowed
          && SystemAvailabilityService.statusObject.client_version
          && SystemAvailabilityService.statusObject.client_version !== 'unknown'
          && currentClientVersion
          && semver.gt(SystemAvailabilityService.statusObject.client_version, currentClientVersion)
        ) {
          // Global.showVersionUpdateRequest()
          // newVersionAlertShowed = true
        }

        // check for maintenance mode
        if (SystemAvailabilityService.statusObject.ui.maintenance) {
          const location = useBrowserLocation()
          const locationURL: string = (location.value?.pathname || '') + (location.value?.search || '') + location.value.hash
          localStorage.setItem(LOCAL_STORAGE_REDIRECT_URL, locationURL)
          await router.push({ name: MAINTENANCE_ROUTE })
        }

        store.commit('isReadonlyMode', SystemAvailabilityService.statusObject.ui.readonly || store.getters['user/is_read_only'] || store.getters['user/role'].type === ROLE_TYPE.READER || store.getters.isReadonlyMode)
      }
      catch (e) {
        newHealthcheckEndpointWorks = false
        // result = AVAILABLE_STATUS.OFFLINE
      }
    }

    if (!newHealthcheckEndpointWorks) {
      try {
        response = await axios.head(http.defaults.baseURL ? `${http.defaults.baseURL}/ping` : 'localhost:1337/api/ping')
        result = response.status < 400 && response.status !== 0 ? AVAILABLE_STATUS.ONLINE : AVAILABLE_STATUS.OFFLINE
      }
      catch (e) {
        result = AVAILABLE_STATUS.OFFLINE
      }
    }

    return result
  }

  static startPolling() {
    if (SystemAvailabilityService.pollingTimer) { clearTimeout(SystemAvailabilityService.pollingTimer) }
    SystemAvailabilityService.pollingTimer = window.setTimeout(
      async () => {
        SystemAvailabilityService.available.value = await SystemAvailabilityService.isStrapiAvailable()
        SystemAvailabilityService.startPolling()
      },
      SystemAvailabilityService.available.value === AVAILABLE_STATUS.OFFLINE
        ? FAST_SYSTEM_AVAILABILITY_POLLING_DELAY
        : SYSTEM_AVAILABILITY_POLLING_DELAY,
    )
  }

  static registerVueRouterHook(router: Router) {
    router.beforeEach((to, from) => {
      if (
        to.name !== UNAVAILABLE_ROUTE // Avoid infinite loop
        && SystemAvailabilityService.available.value === AVAILABLE_STATUS.OFFLINE // If system is offline
      ) {
        return { name: UNAVAILABLE_ROUTE }
      }

      if (to.name === UNAVAILABLE_ROUTE && SystemAvailabilityService.available.value === AVAILABLE_STATUS.ONLINE) {
        return AppSettings.redirectRouteAfterRecover.value
      }
    })
  }

  static startWatching() {
    // THIS WATCHER TRIGGERS EVERY TIME AVAILABLE CHANGES
    // IT STORES PREVIOUS ROUTE AND REDIRECTS TO UNAVAILABLE ROUTE
    // IF SERVICE IS BACK ONLINE, IT REDIRECTS TO THE PREVIOUS ROUTE
    watch(SystemAvailabilityService.available, async (isAvailable) => {
      if (isAvailable === AVAILABLE_STATUS.OFFLINE) {
        redirectRouteAfterRecover.value = router.currentRoute.value.fullPath
        await router.push({ name: UNAVAILABLE_ROUTE })
      }
      else {
        const url = redirectRouteAfterRecover.value
        redirectRouteAfterRecover.value = ''
        await router.push({ path: url })
      }
    })
  }
}
