<script lang="ts" setup>
import {type Client} from '@level3/types/client'
import {NAvatar, NButton} from 'naive-ui'
import {UseClipboard} from '@vueuse/components'

type Props = {
  client: Client
  userClients: Client[]
  className?: string
  showActionsTrigger?: 'hover' | 'always'
}
withDefaults(defineProps<Props>(), { showActionsTrigger: 'hover', })

const emit = defineEmits<{
  addClient: [Client]
  removeClient: [Client]
}>()

const isMyClient = computed(() => {
  return __props.userClients.some(
    (currentClient: Client) => currentClient.name === __props.client.name,
  )
})

const nameInitials = computed(() =>
  __props.client.displayName.slice(0, 2).toUpperCase(),
)

// TODO: Remove this function when level3 v2 is finished, use NLink instead
const handleSelectClient = (clientName: string) => {
  window.location.href = `/use-cases?org=${clientName}&pipeline=default`
}

const isHovered = ref(false)
const shouldShowActions = computed(() => {
  return (
    __props.showActionsTrigger === 'always' ||
    (__props.showActionsTrigger === 'hover' && isHovered.value)
  )
})
</script>

<template>
  <div
    class="flex cursor-pointer justify-between rounded-md py-2 pl-4 pr-1"
    hover="bg-neutral-50"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
    @click="handleSelectClient(client.name)"
  >
    <div class="flex items-center gap-4">
      <NAvatar
        class="select-none"
        round
        :style="{
          color: 'black',
          backgroundColor: 'transparent',
          border: '1px solid #d1d5db',
        }"
        >{{ nameInitials }}</NAvatar
      >
      <div class="flex flex-col items-start">
        <p
          class="cursor-pointer font-semibold"
          hover="text-primary-main underline"
        >
          {{ client.displayName }}
        </p>
        <UseClipboard v-slot="{copy, copied}">
          <NTooltip placement="right">
            <template #trigger>
              <div
                class="group flex items-center gap-1 text-xs text-neutral-400 underline-offset-2 hover:underline"
                :class="{'!text-blue-500': copied}"
                @click.stop="() => copy(client.name)"
              >
                {{ copied ? 'copied!' : client.name }}
                <div
                  v-if="!copied"
                  class="i-solar-copy-outline hidden text-7px group-hover:block"
                />
              </div>
            </template>
            Copy client slug
          </NTooltip>
        </UseClipboard>
      </div>
    </div>
    <div v-if="shouldShowActions" class="flex items-center gap-2" @click.stop>
      <NTooltip v-if="isMyClient" placement="left">
        <template #trigger>
          <NButton
            class="!text-neutral-500"
            quaternary
            circle
            @click="emit('removeClient', client)"
          >
            <template #icon>
              <div class="i-solar-minus-circle-outline" />
            </template>
          </NButton>
        </template>
        <p>Remove from my clients list</p>
      </NTooltip>
      <NTooltip v-else-if="!isMyClient" placement="left">
        <template #trigger>
          <NButton
            secondary
            round
            size="small"
            type="primary"
            @click="emit('addClient', client)"
          >
            Add
          </NButton>
        </template>
        <p>Add to my clients list</p>
      </NTooltip>
    </div>
  </div>
</template>
