import * as levenshtein from 'fast-levenshtein'
import type { GeneratedVariantsOption } from '@/level4/stores/useExperimenterVariantsFormsStore'

export function getTooSimilarVariants(variants: GeneratedVariantsOption[], similarVariants: GeneratedVariantsOption[], removeFromOriginal: boolean = true) {
  const processedVariants: GeneratedVariantsOption[] = []
  const getVariantsCopy = () => variants.concat(similarVariants)
  // function that removes only one copy of the string from the array
  const removeOneVariantCopy = (arr: GeneratedVariantsOption[], label: string) => {
    const index = arr.findIndex(variant => variant.label === label)
    if (index > -1) {
      arr.splice(index, 1)
    }
    return arr
  }
  variants.forEach((variant) => {
    const variantsCopy = removeOneVariantCopy(getVariantsCopy(), variant.label)
    const distance = variantsCopy?.reduce((m, nextVariant) => Math.min(m, levenshtein.get(nextVariant.label, variant.label)), Number.POSITIVE_INFINITY) ?? Number.POSITIVE_INFINITY
    processedVariants.push({
      ...variant,
      minDistance: distance,
      variantLink: variant,
    })
  })

  const sumLetters = processedVariants.reduce((t, nextVariant) => t + nextVariant.label.length, 0)
  // Must differ by at least 10% - where at least 10% of average count character must be changed
  const mustDifferBy = (sumLetters / processedVariants.length) * 0.1

  const tooSimilarVariants = processedVariants.filter(v => ((v.minDistance ?? Number.POSITIVE_INFINITY) < mustDifferBy)).map(v => v.variantLink as GeneratedVariantsOption)

  if (removeFromOriginal) {
    tooSimilarVariants.forEach((variant) => {
      variants.splice(variants.findIndex(v => v.id === variant?.id), 1)
    })
  }

  return tooSimilarVariants.concat(similarVariants)
}
