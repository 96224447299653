<script setup lang="tsx">
import { usePermissions } from "@/auth/composables/usePermissions";
import type { DataTableColumns } from 'naive-ui'
import { NButton, NTag, NImage, NPopconfirm, NInput } from 'naive-ui'
import EmailTemplatesConfirmVariantsTable from '@/level4/components/email-templates/EmailTemplatesConfirmVariantsTable.vue'
import BaseEmailDetails from "./BaseEmailDetails.vue";
export interface EmailTemplateWithVariantsRow {
  id: string
  previewThumbnailUrl: string
  title: string
  subtitle: string
  elementsToVaryList: string[]
  description?: string
  templateVariants: {
    subjectLine: string
    ctaText: string
    heroImageData: {
      imageUrl: string
      imageTitle: string
      imageSize: string
    }
  }[]
}

interface Props {
  emailRows: EmailTemplateWithVariantsRow[]
  selectedVariantIds?: string[] | undefined
  isEditable: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
  "update:select-variant-ids": [variantIds: string[]]
  "click:create-variants": [campaignTemplateId: string]
  "click:edit-variants": [campaignTemplateId: string]
  "click:preview-variant": [campaignTemplateId: string, variantId: string]
  "click:delete-variant": [campaignTemplateId: string, variantId: string]
  "click:delete-email": [campaignTemplateId: string]
  "update:template-description": [campaignTemplateId: string, description: string]
}>()

const { isAIEorAdmin } = usePermissions()

const expandedRowKeys = ref([])
function toggleExpandedRowKeys(rowIdx: number) {
  const idx = expandedRowKeys.value.indexOf(rowIdx)
  if (idx === -1) expandedRowKeys.value.push(rowIdx)
  else expandedRowKeys.value.splice(idx, 1)
}

const columns : DataTableColumns<EmailTemplateWithVariantsRow> = computed(() => {
  const tableColumns = [
    {
      type: "expand",
      width: 0,
      maxWidth: 0,
      renderExpand(row) {
        const HideButton = () => (
          <NButton
            quaternary
            type="primary"
            strong
            onClick={() => toggleExpandedRowKeys(row.id)}
          >
            {{
              default: "Hide",
              icon: () => (
                <div class="i-solar-round-alt-arrow-up-outline"></div>
              ),
            }}
          </NButton>
        )

        return (
          <div flex="~ col">
            <div
              class="expanded-email-variant-table-header"
              p="2"
              flex="~"
              items="center"
              justify="between"
            >
              <span text="2xl">Template Variants</span>
              <HideButton />
            </div>
            <EmailTemplatesConfirmVariantsTable
              data={row.templateVariants}
              pagination={{ simple: true, pageSize: 5}}
              selected-variant-ids={props.selectedVariantIds}
              isEditable={props.isEditable}
              onUpdate:select-variant-ids={(variantIds) => { emit('update:select-variant-ids', variantIds) }}
              onClick:preview-variant={(variantId: string) => { emit('click:preview-variant', row.id,variantId) }}
              onClick:delete-variant={(variantId: string) => { emit('click:delete-variant', row.id, variantId) }}
            />
          </div>
        )
      },
    },
    {
      title: "Preview",
      key: "previewThumbnailUrl",
      render(row) {
        return (
          <div
            class="image-wrapper"
            aspect="4/3"
            overflow="hidden"
            rounded="md"
            border="~"
            w="35"
            title={ isAIEorAdmin ? `Template id ${row.id}` : '' }
          >
            <NImage
              src={row.previewThumbnailUrl}
              object-fit="cover"
              img-props={{w: 'full'}}
            />
          </div>
        )
      },
    },
    {
      title: "Base email details",
      key: "title",
      render(row) {
        return(
          <BaseEmailDetails row={row} onUpdate:template-description={(id, description)=> emit('update:template-description', id, description)} ></BaseEmailDetails>
        )
      },
    },
    {
      title: "Elements to vary",
      key: "elementsToVaryList",
      maxWidth: 100,
      render(row) {
        if(!row.elementsToVaryList?.length) return (<div text="gray-500">No elements varied</div>)
        const VariantTags = () =>
          row.elementsToVaryList?.map((element) => (
            <NTag round bordered={false}>
              {element}
            </NTag>
          ))

        return (
          <div flex="~ wrap" gap="1">
            <VariantTags />
          </div>
        )
      },
    },
    {
      title: "Variants",
      key: "numberOfVariants",
      render(row, rowIdx) {
        const numberOfVariantsLabel = () => {
          if (row.templateVariants?.length === 1) return "1 Variant"
          if (row.templateVariants?.length > 1)
            return `${row.templateVariants?.length} Variants`
          return "No variants"
        }
        const NumberOfVariantsButton = () => (
          <NButton quaternary type="tertiary" class={row.templateVariants?.length ? '' : '!text-gray-300'}>
            {{
              default: numberOfVariantsLabel(),
              icon: () => <div class="i-solar-copy-bold"></div>,
            }}
          </NButton>
        )

        return (
          <div class="flex items-center gap-2">
            <NumberOfVariantsButton />
          </div>
        )
      },
    },
    {
      key: "edit",
      render(row) {
        const isShowingMore = expandedRowKeys.value.includes(row.id)
        const ShowMoreButton = () => {
          if (!row.templateVariants?.length) { return null }

          return (
              <NButton
                  quaternary={!isShowingMore}
                  secondary={isShowingMore}
                  type="primary"
                  strong
                  onClick={() => toggleExpandedRowKeys(row.id)}
              >
                {{
                  default: "Show variants",
                  icon: () => (
                      <div class="i-solar-round-alt-arrow-down-outline"></div>
                  ),
                }}
              </NButton>
          )
        }

        return (
            <ShowMoreButton />
        )
      },
    },
  ]

  if(props.isEditable) {
    tableColumns.push(    {
      key: "edit",
      render(row) {
        const CreateOrEditVariantsButton = () => (
          <NButton
            quaternary
            type="primary"
            strong
            onClick={() => {
              if (row.templateVariants?.length > 0) {
                emit("click:edit-variants", row.id)
              } else {
                emit("click:create-variants", row.id)
              }
            }}
          >
            {row.templateVariants?.length > 0
              ? {
                  default: "Edit variants",
                  icon: () => <div class="i-solar-pen-2-outline"></div>,
                }
              : {
                  default: "Create variants",
                  icon: () => <div class="i-solar-add-square-outline"></div>,
                }}
          </NButton>
        )

        return (

            <CreateOrEditVariantsButton />

        )
      },
    })

    tableColumns.push({
      key: "delete",
      render(row) {
        return (
          <div flex="~" gap="1" items="center" justify="end">
            <NPopconfirm
              v-if={!!row}
              onPositiveClick={() => emit('click:delete-email', row.id)}
            >
              {{
                activator: () => <DeleteButton  />,
                default: () => 'Are you sure you want to delete this email and all its variants?'
              }}
            </NPopconfirm>
          </div>
        )
      },
    })
  }

  return tableColumns
})

const DeleteButton = () => <NButton quaternary type="primary" strong>
  {{
    icon: () => <span class="i-solar-trash-bin-trash-outline" />,
  }}
</NButton>
</script>

<template>
  <NDataTable :expanded-row-keys="expandedRowKeys" :columns="columns" :data="emailRows" :row-key="row => row.id" :render-expand-icon="() => null"></NDataTable>
</template>

<style scoped></style>
