import { useExperimenterFormState } from './useExperimenterFormState'
import type { VariantsCartesianProduct } from '@/level4/utils/variants/variantsCartesianProduct'

export function useEmailVariantPreview() {
  const { experimenterFormState } = useExperimenterFormState()

  const showEmailVariantPreviewModal = ref(false)
  const selectedEmailVariant = ref<VariantsCartesianProduct>()
  const selectedTemplateId = ref<string>()
  const selectedCTAHtmlElementIdentifiers = ref<{
    cta: string | undefined
    image: string | undefined
  }>({ cta: undefined, image: undefined })

  const selectedTemplate = computed(() => {
    const templates = experimenterFormState.value?.currentImportedTemplates
    if (!templates) { return {} }
    return templates.find(template => template.id === selectedTemplateId.value)
  })

  function previewVariantClickHandler(templateId: string, variantId: string) {
    selectedTemplateId.value = templateId
    const variantData = experimenterFormState.value?.variantsDataMap[templateId]
    if (!variantData) {
      throw new Error(`No variant data found for templateId ${templateId}`)
    }
    selectedCTAHtmlElementIdentifiers.value = variantData.selectedCTAHtmlElementIdentifiers
    const selectedVariant = variantData.generatedVariants.find(variant => variant.id === variantId)
    if (!selectedVariant) {
      throw new Error(`No variant found with id ${variantId}`)
    }
    selectedEmailVariant.value = selectedVariant
    showEmailVariantPreviewModal.value = true
  }

  const templateHtml = computed(() => selectedTemplate.value?.html_content ?? '')

  const replaceMap = computed(() => {
    const variantTypeIdentifierValueMap = {
      cta: {} as Record<string, string>,
      image: {} as Record<string, string>,
      subjectLine: selectedEmailVariant.value?.subjectLine?.label,
    }

    if (selectedCTAHtmlElementIdentifiers.value?.cta) {
      variantTypeIdentifierValueMap.cta = {
        [selectedCTAHtmlElementIdentifiers.value.cta]: selectedEmailVariant.value?.cta?.label,
      }
    }

    if (selectedCTAHtmlElementIdentifiers.value?.image) {
      variantTypeIdentifierValueMap.image = {
        [selectedCTAHtmlElementIdentifiers.value.image]: selectedEmailVariant.value?.image,
      }
    }

    return variantTypeIdentifierValueMap
  })

  return {
    showEmailVariantPreviewModal,
    templateHtml,
    previewVariantClickHandler,
    replaceMap,
  }
}
