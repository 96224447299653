<script lang="ts">
const NEW_CREDENTIALS: NEW_CREDENTIALS_OPTION_TYPE = 'new'
</script>
<script setup lang="ts">
import AppSettings from '@app/AppSettings.ts'
import { maskPassword, maskUuid } from 'maskdata'
import omit from 'lodash/omit'
import { NScrollbar } from 'naive-ui'
import type { FormExposedProperties } from '@/level4/utils/level4Forms.ts'
import { INTEGRATION_PLATFORM, PLATFORM_DISPLAY_NAME } from '@/level4/data/l4_constants.ts'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState.ts'

import PlatformIntegrationService from '@/level4/services/PlatformIntegrationService.ts'

const { experimenterFormState, setBrazeIntegrationCredentials, setKlaviyoIntegrationCredentials, setSFMCIntegrationCredentials } = useExperimenterFormState()
const currentPlatform = computed(() => experimenterFormState.value?.platform)

type NEW_CREDENTIALS_OPTION_TYPE = 'new'


const { data: credentialsData } = useQuery({
  queryKey: ['existingCredentials', currentPlatform],
  queryFn: async () => {
    if (!currentPlatform.value) { return Promise.resolve([]) }
    return PlatformIntegrationService.getExistingPlatformIntegrationCredentials(currentPlatform.value, AppSettings.clientName.value)
  },
})

function getEmailNameAndAddress(email?: string): { name: string, address: string } | undefined {
  if (!email) {
    return
  }

  const splitFullEmail = email.split('<')
  return {
    name: splitFullEmail[0].trim(),
    address: splitFullEmail?.[1].replace('>', '').trim(),
  }
}

const selectedOption = ref<number | NEW_CREDENTIALS_OPTION_TYPE | null>(null)

const selectedCredentials = computed(() => {
  if (!selectedOption.value || !credentialsData.value) {
    return
  }
  return omit(credentialsData.value.find(credentialsItem => credentialsItem.id === selectedOption.value), 'id')
})

const isDataComplete = computed(() => selectedOption.value !== null)

defineExpose<FormExposedProperties>({
  dataIsComplete: isDataComplete,
  submitHandler: async () => {
    if (selectedOption.value === NEW_CREDENTIALS) {
      return
    }

    if (!credentialsData.value || !selectedCredentials.value) {
      throw new Error('Selected credentials not found')
    }

    if (currentPlatform.value === INTEGRATION_PLATFORM.BRAZE) {
      await setBrazeIntegrationCredentials(selectedCredentials.value)
    }
    else if (currentPlatform.value === INTEGRATION_PLATFORM.KLAVIYO) {
      await setKlaviyoIntegrationCredentials(selectedCredentials.value)
    }
    else if (currentPlatform.value === INTEGRATION_PLATFORM.SFMC) {
      await setSFMCIntegrationCredentials(selectedCredentials.value)
    }
  },
})
</script>

<template>
  <WizardFormPageLayout containerSize="large" headerSize="small" footerSize="large" :supertitle="`I’ve found previously connected ${PLATFORM_DISPLAY_NAME[currentPlatform || '']} credentials`" title="Please select if you want to connect new credentials or re-use existing ones.">
    <NRadioGroup v-model:value="selectedOption" size="large" class="w-full">
      <div class="mb-8">
        <NCard class="cursor-pointer overflow-y-auto !rounded-md hover:shadow-lg" @click="selectedOption = NEW_CREDENTIALS">
          <NRadio :value="NEW_CREDENTIALS">
            <span class="font-semibold">Connect new credentials</span>
          </NRadio>
        </NCard>
      </div>
      <div class="my-4 text-center">
        <div class="flex text-sm text-gray-500">
          <div class="mr-2 mt-2 h-1px w-full border-t-1" />
          OR
          <div class="ml-2 mt-2 h-1px w-full border-t-1" />
        </div>
      </div>
      <div class="w-full">
        <h2 class="mb-4 text-center text-xl">
          Re-use existing credentials saved to OfferFit:
        </h2>

        <NScrollbar class="max-h-160">
          <div class="flex flex-col gap-y-4 mb-4">
            <template v-for="credentialsItem in credentialsData" :key="credentialsItem.id">
              <NCard v-if="currentPlatform === INTEGRATION_PLATFORM.BRAZE && credentialsItem.id" class="cursor-pointer overflow-y-auto !rounded-md hover:shadow-lg" @click="selectedOption = credentialsItem.id">
                <NRadio v-if="'brazeDashboardUrl' in credentialsItem" :value="credentialsItem.id" :checkedValue="selectedOption === credentialsItem.id" :checked="selectedOption === credentialsItem.id">
                  <div class="ml-2 flex flex-col gap-y-3">
                    <p><strong>Dashboard URL:</strong><br>{{ credentialsItem.brazeDashboardUrl || '-' }}</p>
                    <p><strong>API key:</strong><br>{{ maskUuid(credentialsItem.brazeApiKey, { maskWith: '*', unmaskedStartCharacters: 4, unmaskedEndCharacters: 4 }) || '-' }}</p>
                    <p><strong>App ID:</strong><br>{{ credentialsItem.brazeAppId || '-' }}</p>
                    <p><strong>Sender name:</strong><br>{{ getEmailNameAndAddress(credentialsItem.brazeEmailFrom)?.name || '-' }}</p>
                    <p><strong>Sender address:</strong><br>{{ getEmailNameAndAddress(credentialsItem.brazeEmailFrom)?.address || '-' }}</p>
                  </div>
                </NRadio>
              </NCard>

              <NCard v-if="currentPlatform === INTEGRATION_PLATFORM.KLAVIYO && credentialsItem.id" class="cursor-pointer overflow-y-auto !rounded-md hover:shadow-lg" @click="selectedOption = credentialsItem.id">
                <NRadio v-if="'klaviyoApiKey' in credentialsItem" :value="credentialsItem.id">
                  <div class="ml-2">
                    <p><strong>API key:</strong><br>{{ `${(credentialsItem.klaviyoApiKey ?? '').substring(0, 8)}****************` || '-' }}</p>
                  </div>
                </NRadio>
              </NCard>

              <NCard v-if="currentPlatform === INTEGRATION_PLATFORM.SFMC && credentialsItem.id" class="cursor-pointer overflow-y-auto !rounded-md hover:shadow-lg" @click="selectedOption = credentialsItem.id">
                <NRadio v-if="'sfmcClientId' in credentialsItem" :value="credentialsItem.id">
                  <div class="ml-2 flex flex-col gap-y-3">
                    <p><strong>Client ID:</strong><br>{{ credentialsItem.sfmcClientId || '-' }}</p>
                    <p><strong>Client Secret:</strong><br>{{ maskPassword(credentialsItem.sfmcClientSecret, { maskWith: '*', unmaskedStartCharacters: 4 }) || '-' }}</p>
                    <p><strong>Auth Base URI:</strong><br>{{ credentialsItem.sfmcAuthBaseUri || '-' }}</p>
                    <p><strong>REST Base URI:</strong><br>{{ credentialsItem.sfmcRestBaseUri || '-' }}</p>
                    <p><strong>SOAP Base URI:</strong><br>{{ credentialsItem.sfmcSoapBaseUri || '-' }}</p>
                  </div>
                </NRadio>
              </NCard>
            </template>
          </div>
        </NScrollbar>
      </div>
    </NRadioGroup>
  </WizardFormPageLayout>
</template>
