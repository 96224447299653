<script setup lang="ts">
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'
import ConfigureUrl from './configure-url/Form.vue'
import ConfigureApi from './configure-api/Form.vue'
import ConfigureAppId from './configure-appid/Form.vue'
import ConfigureCampaign from './configure-campaign/Form.vue'
import ConfigureMessageVarId from './configure-messageid/Form.vue'
import ConfigureEmailFrom from '@/level4/views/modules/braze/configure-email/Form.vue'
import { BrazeSteps } from '@/level4/data/forms'

const formSteps = [
    {
        name: BrazeSteps.PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_URL,
        component: ConfigureUrl,
    },
    {
        name: BrazeSteps.PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_API,
        component: ConfigureApi,
    },
    {
        name: BrazeSteps.PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_CAMPAIGN,
        component: ConfigureCampaign,
    },
    {
        name: BrazeSteps.PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_MESSAGE_VAR_ID,
        component: ConfigureMessageVarId,
    },
    {
        name: BrazeSteps.PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_APP_ID,
        component: ConfigureAppId,
    },
    {
        name: BrazeSteps.PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_EMAIL_FROM,
        component: ConfigureEmailFrom,
    },
]

</script>

<template>
  <div>
    <L4WizardContainer :steps="formSteps" />
  </div>
</template>
