export enum PeriodGuardrailEnum {
  DAYS_7 = 7,
  DAYS_14 = 14,
  DAYS_30 = 30,
}

export const PeriodGuardrailMap: Record<string, PeriodGuardrailEnum> = {
  '7 days': PeriodGuardrailEnum.DAYS_7,
  '14 days': PeriodGuardrailEnum.DAYS_14,
  '30 days': PeriodGuardrailEnum.DAYS_30,
}

export type OFSSGuardrailFeature = 'variant_id' | 'template_id' | 'subject_text'

export interface EmailGuardrail {
  feature: OFSSGuardrailFeature
  selected?: boolean
  period?: PeriodGuardrailEnum
  times?: number
}

export interface UseCaseCfgFinalRecsFeatures {
  final_recs_features: {
    agg_type: 'count'
    days_back: [number]
    dimension: 'email'
    action_bank_group_by_column: OFSSGuardrailFeature
  }[]
}
