import { vueQueryClient } from '@app/utils/vueQueryClient.ts'
import UsecaseService from '../services/UsecaseService'
import { UseCaseStatus } from '@/shared/data/constants'
import type { DimensionConfig } from '@/usecases/models/UseCaseConfigModel'
import type { ConfigDimensionActionBank } from '@/usecases/models/server/ActionModel'
import type { DimensionKey } from '@/shared/data/constants'

export function useLvl4Usecase(clientName: string, pipelineName: string, experimenterName: string) {
  const { isPending: isLoading, data: usecase, error } = useQuery({
    queryKey: ['usecase', clientName, pipelineName, experimenterName],
    queryFn: async () => UsecaseService.getUsecase(clientName, pipelineName, experimenterName),
  })

  const controlledDimensionNames: ComputedRef<DimensionKey[] | undefined> = computed(() => usecase.value?.controlledDimensions?.map(({ dimension }) => dimension))

  const uncontrolledDimensions = computed(() => {
    if (usecase.value) {
      const dimensions = usecase.value.config.prediction.dimension
      return Object.keys(dimensions).reduce((dimension: DimensionConfig, dimensionName: string) => {
        if (!controlledDimensionNames.value?.includes(dimensionName)) {
          dimension[dimensionName] = dimensions[dimensionName]
        }
        return dimension
      }, {})
    }
    else {
      return {}
    }
  })

  const onGetUncontrolledDimensionActionBank: (dimension: string) => ConfigDimensionActionBank | null = (dimensionName: string) => {
    if (usecase.value) {
      return usecase.value.config.actionBanks?.find(dimension => dimension.name === dimensionName) || null
    }
    else {
      return null
    }
  }

  const isSkipTemplateUpsertFlagTrue = computed(() =>
    !!usecase.value?.config?.marketing_platform_connection?.platform_params?.skip_template_upsert)

  async function reloadUseCaseData() {
    await vueQueryClient.invalidateQueries({ queryKey: ['usecase', clientName, pipelineName, experimenterName] })
  }

  const variantActionMap = computed(() => {
    if (!usecase.value?.config?.actionBanks) { return }
    const map = new Map<string, number>()

    usecase.value.config.actionBanks.filter((item: ConfigDimensionActionBank) => item.name === 'email')[0].actions.forEach(
      (action) => {
        map.set(
          action.fields.find(
            field => field.name === 'variant_id',
          )?.value as string,
          action.fields.find(
            field => field.name === 'action_id',
          )?.value as number,
        )
      },
    )
    return map
  })

  const isLive = computed(() => usecase.value?.status === UseCaseStatus.LIVE)

  return { usecase, isLive, isLoading, error, uncontrolledDimensions, controlledDimensionNames, onGetUncontrolledDimensionActionBank, isSkipTemplateUpsertFlagTrue, reloadUseCaseData, variantActionMap }
}
