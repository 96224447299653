<script setup lang="ts">
import ChooseType from './choose/Form.vue'
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'
import { TypeSteps } from '@/level4/data/forms'

const steps = [
    {
        name: TypeSteps.CHOOSE,
        component: ChooseType,
    },
]

</script>
<template>
<div>
    <L4WizardContainer :steps="steps" />
  </div>
</template>
