<script setup lang="ts">
const emit = defineEmits<{}>()

const slots = defineSlots<{
  default: () => any
}>()

const getSlotChildrenText = children => children.map(node => {
  if (!node.children || typeof node.children === 'string') return node.children || ''
  else if (Array.isArray(node.children)) return getSlotChildrenText(node.children)
  else if (node.children.default) return getSlotChildrenText(node.children.default())
}).join('')

const slotInnerText = computed(() => {
  return getSlotChildrenText(slots.default()) || ''
})

const { copy, copied, isSupported } = useClipboard()
</script>

<template>  
  <div class="copyable-content" flex="~" gap="2" items="center">
    <slot></slot>
    <NTooltip>{{ copied ? 'Copied!' : 'Copy' }}
      <template #trigger>
        <div class="copy-icon" text="xs" cursor="pointer" op="30" @click="copy(slotInnerText)">
          <div class="i-solar-copy-bold"></div>
        </div>
      </template>
    </NTooltip>
  </div>
</template>

<style scoped>

</style>
