<script setup lang="ts">
import { handlePortalError } from '@shared/utils/errorHandling.ts'
import AppSettings from '@app/AppSettings.ts'
import type { AudienceModel } from '@/level4/models/braze/AudienceModel.ts'
import type { FormExposedProperties } from '@/level4/utils/level4Forms.ts'
import { PLATFORM_DISPLAY_NAME } from '@/level4/data/l4_constants.ts'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState.ts'
import AudienceSingleSelectRadio from '@/shared/components/basic/AudienceSingleSelectRadio.vue'

const {
  experimenterFormState,
  validateAudience,
  loadAudiences,
  updateBAUReportingConfiguration,
} = useExperimenterFormState()
const { clientName, experimenterName, pipelineName } = AppSettings

const currentSelectedBAUAudienceId = ref<string>()
const search = ref<string>('')

syncRefs(() => toRaw(experimenterFormState.value?.selectedBAUAudienceId), currentSelectedBAUAudienceId, { immediate: true })

const loadAudiencesQuery = useQuery({
  queryKey: ['get-audiences-bau', clientName.value, experimenterName.value, pipelineName.value],
  queryFn: async () => getAudiences(),
  enabled: !!experimenterName.value,
})

const isValidationEnabled = computed(() => !!currentSelectedBAUAudienceId.value && loadAudiencesQuery.data?.value?.length > 0)

async function getAudiences(useCache: boolean = true): Promise<AudienceModel[] | undefined> {
  try {
    const res = await loadAudiences(useCache)
    return res.data.attributes.importedAudiences
  }
  catch (e: any) {
    handlePortalError(e, { defaultUserErrorText: `Couldn't load audiences. ${experimenterFormState.value?.importedAudiences.length ? 'You can see audiences taken from cache. ' : ''}Please check that the integration data is correct` })
    return experimenterFormState.value?.importedAudiences
  }
}

const audienceErrorMap = ref<Map<string, Pick<AudienceModel, 'error' | 'errorCode'>>>(new Map())

const { data: audienceSizeMap, isLoading: isLoadingAudienceDetail } = useQuery({
  queryKey: ['validate-audiences', clientName, experimenterName, pipelineName, currentSelectedBAUAudienceId],
  queryFn: async () => {
    const audienceSizeMap: Map<string, number> = new Map()

    const audiences = loadAudiencesQuery.data?.value?.filter((audience) => {
      return currentSelectedBAUAudienceId.value === audience.id
    })

    if (!audiences?.length) {
      return
    }

    for (const audience of audiences) {
      if (audienceSizeMap.get(audience.id) === undefined) {
        const validationData = await validateAudience(audience)
        if (validationData?.error_msg) {
          audienceErrorMap.value.set(audience.id, { error: validationData.error_msg, errorCode: validationData.error_code })
        }
        if (validationData && validationData.result.size !== null) {
          audienceSizeMap.set(audience.id, validationData.result.size)
        }
      }
    }

    return audienceSizeMap
  },
  enabled: isValidationEnabled,
})

async function getAudiencesWithoutCache(): Promise<void> {
  await getAudiences(false)
}

const title = computed(() => `Select your BAU audience from ${PLATFORM_DISPLAY_NAME[experimenterFormState.value?.platform]}`)

const filteredImportedAudiences = computed(() => {
  const filteredAudiences = loadAudiencesQuery.data?.value?.filter(audience => !experimenterFormState.value?.selectedAudienceIds?.includes(audience.id))
  return search.value
    ? filteredAudiences?.filter((audience) => {
      return audience.name.toLowerCase().includes(search.value.toLowerCase())
    })
    : filteredAudiences
})

async function refreshDataClickHandler(): Promise<void> {
  audienceErrorMap.value.clear()
  await getAudiencesWithoutCache()
}

const invalidAudiences = computed(() => {
  return loadAudiencesQuery.data?.value?.filter((audience) => {
    return currentSelectedBAUAudienceId.value === audience.id
  })?.filter((audience) => {
    return audience.error
  }) ?? []
})

function modelValueChangeHandler(value: string) {
  currentSelectedBAUAudienceId.value = value
}

const isDataLoading = computed(() => loadAudiencesQuery.isPending.value && isLoadingAudienceDetail.value)
const hasInvalidAudiences = computed(() => !!invalidAudiences.value?.length)
const dataIsComplete = computed(() => !isDataLoading.value && !!currentSelectedBAUAudienceId.value && !hasInvalidAudiences.value)

defineExpose<FormExposedProperties>({
  dataIsComplete,
  submitHandler: async () => {
    await updateBAUReportingConfiguration(
      experimenterFormState.value?.isAddingBauCampaign || true,
      experimenterFormState.value?.selectedBAUCampaignIds,
      currentSelectedBAUAudienceId.value,
    )
  },
})
</script>

<template>
  <WizardFormPageLayout
    containerSize="full" headerSize="medium" :title="title"
    subtitle="Which of these groups represents the audience receiving Business as Usual communications?"
  >
    <div class="flex justify-center">
      <div class="max-w-[42rem] w-full flex flex-col gap-6">
        <div class="flex justify-center">
          <BasicLink
            href="https://app.tango.us/app/workflow/-WIP--Reporting-on-BAU-in-the-OfferFit-Self-Serve-portal-18311ba85feb47f3adaf138f38d1b8d8"
            target="_blank"
            class="cursor-pointer text-link"
            withExternalIcon
          >
            More information about reporting on BAU campaigns
          </BasicLink>
        </div>
        <AudienceSingleSelectRadio
          :selectedValue="currentSelectedBAUAudienceId"
          :listData="filteredImportedAudiences"
          :errorMap="audienceErrorMap"
          :infoMap="audienceSizeMap"
          :isLoading="loadAudiencesQuery.isPending.value"
          :queryIsSuccess="loadAudiencesQuery.isSuccess.value"
          noDataMessage="No audiences found."
          dataErrorMessage="Error fetching audiences. Please try again later or contact an OfferFit representative."
          searchPlaceholder="Search for more audiences by group name..."
          @refresh="refreshDataClickHandler"
          @modelValueChange="modelValueChangeHandler"
        />
      </div>
    </div>
  </WizardFormPageLayout>
</template>
