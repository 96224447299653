  <script setup lang="ts">
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'
import { GuardRailSteps } from '@/level4/data/forms'
import ChooseGuardrails from './choose/Form.vue'


const formSteps = [
    {
        name: GuardRailSteps.CHOOSE,
        component: ChooseGuardrails,
    },
]
</script>

<template>
  <div>
    <L4WizardContainer :steps="formSteps" />
  </div>
</template>
