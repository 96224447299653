<script setup lang="tsx">
import type { DataTableColumns, PaginationProps } from 'naive-ui'
import { NButton, NImage, NTag, NThing, NPopconfirm } from 'naive-ui'

export interface VariantAction {
  actionId?: number
  active?: boolean
}

export interface SingleVariantRow {
  id?: number
  variantId?: string
  subjectLineText?: string
  subjectLineTags: string[]
  ctaTags: string[]
  ctaText?: string
  heroImageData: {
    imageUrl?: string
  }
}

export interface Props {
  data: SingleVariantRow[]
  pagination: PaginationProps
  selectedVariantIds?: string[]
  isEditable: boolean
}

const props = defineProps<Props>()
const currentSelectedVariantIds = ref<string[] | undefined>(props.selectedVariantIds)

const emit = defineEmits<{
  'update:select-variant-ids': [ids: string[]]
  'click:preview-variant': [id: string]
  'click:delete-variant': [id: string]
}>()

watch(currentSelectedVariantIds, (variantIds) => {
  emit('update:select-variant-ids', variantIds!)
})

function createColumns(): DataTableColumns<SingleVariantRow> {
  const result: DataTableColumns<SingleVariantRow> = !!props.selectedVariantIds ? [
    {type: 'selection'},
  ] : []

  const isSubjectLinePersisted = props.data?.some(row => row.subjectLineText)
  const isCtaPersisted = props.data?.some(row => row.ctaText)
  const isHeroImagePersisted = props.data?.some(row => row.heroImageData.imageUrl)


  if (isSubjectLinePersisted) {
    result.push({
      title: 'Subject line',
      key: 'subjectLineText',
      sorter: 'default',
      width: 200,
      ellipsis: {
        tooltip: true
      },
      render(row) {
        return <span font="bold">{row.subjectLineText}</span>
      },
    })
  }

  if (isCtaPersisted) {
    result.push({
      title: 'CTA',
      key: 'ctaText',
      sorter: 'default',
      width: 200,
      render(row) {
        if (!row.ctaText) return ''

        return <NTag
          round
          bordered={false}
          size="small"
        >{row.ctaText}</NTag>
      },
    })
  }

  if (isHeroImagePersisted) {
    result.push({
      title: 'Hero image',
      key: 'heroImageData',
      sorter: 'default',
      width: 150,
      render(row) {
        return <NThing>{{
          avatar: () => <NImage
            src={row.heroImageData.imageUrl}
            alt={row.heroImageData.imageTitle}
            width="120" />,
          header: () => <span>{row.heroImageData.imageTitle}</span>,
          description: () => <span>{row.heroImageData.imageSize}</span>,
        }}</NThing>
      },
    })
  }

  result.push({
    key: 'preview',
    width: 100,
    render(row) {
      const PreviewButton = ({variantId} : {variantId: string}) => <NButton onClick={() => emit('click:preview-variant', variantId)} quaternary type="primary" strong>
        {{
          default: () => 'Preview',
          icon: () => <span class="i-solar-eye-outline" />,
        }}
      </NButton>

      return <PreviewButton variantId={row.variantId!} />
    },
  })

  if(props.isEditable) {
    result.push({
    key: 'delete',
    width: 100,
    render(row) {

      const DeleteButton = () => <NButton quaternary type="primary" strong>
        {{
          icon: () => <span class="i-solar-trash-bin-trash-outline" />,
        }}
      </NButton>

      return (
            <NPopconfirm
                v-if="!!row && props.isEditable"
                onPositiveClick={() => emit('click:delete-variant', row.variantId!)}
            >
              {{
                activator: () => <DeleteButton />,
                default: () => 'Are you sure you want to delete this variant?'
              }}
            </NPopconfirm>
      )
    },
  })
  }

  return result
}
</script>

<template>
  <div class="email-templates-confirm-variants-table-wrapper">
    <NDataTable
      v-model:checked-row-keys="currentSelectedVariantIds"
      :data="data"
      :columns="createColumns()"
      :bordered="false"
      :row-key="(row) => row.variantId"
      :pagination="pagination"
    >
      <template #empty>
        No variants defined
      </template>
    </NDataTable>
  </div>
</template>
