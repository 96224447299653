import { handlePortalError } from '@shared/utils/errorHandling.ts'
import { useExperimenterFormState } from './useExperimenterFormState'

export function useIntegrationsSync() {
  const { createIntegrationDataInVault, updateOFEConfigs } = useExperimenterFormState()

  async function createDataInVault() {
    try {
      await createIntegrationDataInVault()
    }
    catch (e: any) {
      handlePortalError(e, { defaultUserErrorText: 'Error creating connection between OfferFit and platform. Please try again later or contact support.' })
      return Promise.reject(e)
    }

    try {
      await updateOFEConfigs()
      return await Promise.resolve()
    }
    catch (e: any) {
      handlePortalError(e, { defaultUserErrorText: 'Error updating experimenter configuration. Please try again later or contact support.' })
      return Promise.reject(e)
    }
  }

  return {
    createDataInVault,
  }
}
