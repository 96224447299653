<script setup lang="ts">
import { xssPlugin } from '@shared/utils/xss'

interface Props {
  containerSize?: 'tiny' | 'small' | 'medium' | 'large' | 'full'
  headerSize?: 'tiny' | 'small' | 'medium' | 'large' | 'full'
  footerSize?: 'tiny' | 'small' | 'medium' | 'large' | 'full'
  supertitle?: string
  title?: string
  subtitle?: string
  thirdSectionText?: string
}

withDefaults(defineProps<Props>(), { containerSize: 'medium',headerSize: 'medium',footerSize: 'medium', })

const sizesMap = {
  tiny: 'max-w-xs',
  small: 'max-w-md',
  medium: 'max-w-2xl',
  large: 'max-w-3xl',
  full: '',
}

const selectedHeaderMaxWidth = computed(() => {
  return sizesMap[__props.headerSize]
})

const selectedContainerSize = computed(() => {
  return sizesMap[__props.containerSize]
})

const selectedFooterSize = computed(() => {
  return sizesMap[__props.footerSize]
})
</script>

<template>
  <section class="container" flex="~ col" items="center" gap="10" m="x-auto">
    <slot name="header">
      <div class="header-info text-center" mx="auto" :class="selectedHeaderMaxWidth">
        <slot name="supertitle">
          <h3 v-if="$slots.supertitle || supertitle" class="header mb-4" v-html="xssPlugin.process(supertitle || '')" />
        </slot>
        <slot name="title">
          <h1 v-if="$slots.title || title" class="header mb-4 text-2xl" v-html="xssPlugin.process(title || '')" />
        </slot>
        <slot name="subtitle">
          <h2 v-if="$slots.subtitle || subtitle" class="post-header mb-4" v-html="xssPlugin.process(subtitle || '')" />
        </slot>
        <slot name="thirdSection">
          <div
            v-if="$slots.thirdSection || thirdSectionText" class="third-section mb-4"
            v-html="xssPlugin.process(thirdSectionText || '')"
          />
        </slot>
      </div>
    </slot>
    <slot name="header-extra" />
    <div class="main-container" :class="selectedContainerSize" w="full">
      <slot />
    </div>
    <div v-if="$slots.footer" class="footer-container" :class="selectedFooterSize" w="full">
      <slot name="footer" />
    </div>
  </section>
</template>
