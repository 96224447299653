import AppSettings from '@app/AppSettings.ts'
import http from '@app/utils/http-client'
import { vueQueryClient } from '@app/utils/vueQueryClient.ts'
import type { MaybeRef } from '@vue/reactivity'
import type { BaseEmailTemplate } from '@/level4/composables/useExperimenterFormState'
import type { INTEGRATION_PLATFORM } from '@/level4/data/l4_constants.ts'
import type { ExperimenterFormState, FormsStateStrapiModel } from '@/level4/models/server/FormStateModel.ts'

// temporary types that are based on form-state model fields
export type KlaviyoIntegrationData = Pick<ExperimenterFormState, 'klaviyoApiKey'> & { id?: number }
export type BrazeIntegrationData = Pick<ExperimenterFormState, 'brazeApiKey' | 'brazeAppId' | 'brazeEmailFrom' | 'brazeDashboardUrl'> & { id?: number }
export type SFMCIntegrationData = Pick<ExperimenterFormState, 'sfmcClientId' | 'sfmcClientSecret' | 'sfmcAuthBaseUri' | 'sfmcRestBaseUri' | 'sfmcSoapBaseUri'> & { id?: number }

export interface SFMCIntegrationValidationResponse {
  result: {
    journey: {
      status: boolean
      execution_mode: boolean
      api_event_trigger: boolean
      multi_criteria_decision: boolean
      email_template_nodes: boolean
    }
    base_data_extension: boolean
    engagement_data_extension: boolean
    recommendations_data_extension: boolean
  }
  error_code: string
  error_msg: string
}

export interface KlaviyoIntegrationValidationResponse {
  result: {
    flow_id: string
    flow_status_live: boolean
    flow_metric_triggered: boolean
    flow_not_archived: boolean
    num_trigger_splits: boolean
    num_expected_trigger_splits: number
    num_actual_trigger_splits: number
    num_email_nodes: boolean
    num_expected_email_nodes: number
    num_actual_email_nodes: number
    no_smart_sending: boolean
    email_nodes_live: boolean
  }
  error_code: string
  error_msg: string
}

export default class PlatformIntegrationService {
  static EXISTING_CREDENTIALS_KEY = 'existingCredentials'
  static INTEGRATION_ESTABLISHED = 'isIntegrationComplete'
  static IS_INTEGRATION_VALID = 'isIntegrationValid'

  static async isIntegrationDataInVault(experimenter: string, client: string): Promise<{ success: boolean }> {
    return (await http.get<{ success: boolean }>(`secrets/ofe/platform/integration?client=${client}&usecase=${experimenter}`)).data
  }

  static async getExistingPlatformIntegrationCredentials<T = KlaviyoIntegrationData | BrazeIntegrationData | SFMCIntegrationData>(platform: INTEGRATION_PLATFORM, client: string) {
    return (await http.get<T[]>(`secrets/ofe/client/${client}/platform/${platform}`)).data
  }

  static async createIntegrationDataInVault(experimenter: string, client: string) {
    return http.post<FormsStateStrapiModel>('secrets/ofe/platform/integration', { client, usecase: experimenter })
  }

  static async validateIntegration(experimenter: string, client: string, pipeline: string, templatesData: BaseEmailTemplate[]) {
    return (await http.post<SFMCIntegrationValidationResponse | KlaviyoIntegrationValidationResponse>(`/ofe/validate?experimenter=${experimenter}&client=${client}&pipelineName=${pipeline}`, { templatesData, client })).data
  }
}

export async function getExistingIntegrationCredentials(platform: MaybeRef<INTEGRATION_PLATFORM | undefined>) {
  const platformValue = unref(platform)
  return vueQueryClient.ensureQueryData({
    queryKey: [PlatformIntegrationService.EXISTING_CREDENTIALS_KEY, platformValue],
    queryFn: async () => {
      if (!platformValue) {
        return Promise.resolve([])
      }
      return PlatformIntegrationService.getExistingPlatformIntegrationCredentials(platformValue, AppSettings.clientName.value)
    },
  })
}

export async function hasIntegrationEstablished(experimenter: string, client: string) {
  return vueQueryClient.ensureQueryData({
    queryKey: [PlatformIntegrationService.INTEGRATION_ESTABLISHED, experimenter, client],
    queryFn: async () => {
      if (!experimenter || !client) {
        return Promise.resolve(false)
      }
      return PlatformIntegrationService.isIntegrationDataInVault(experimenter, client).then(data => data.success)
    },
  })
}
