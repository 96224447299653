
export function getTextByIdentifier(html: string, identifier?: string): string {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  const element = getElementByIdentifier(doc, identifier)
  let text = getElementByIdentifier(doc, identifier)?.textContent ?? ''

  //  take first child of this element
  if (element && text === '') {
    const firstChild = element.firstChild
    if (firstChild /* && fistChild is an image */) {
      text = firstChild.getAttribute('alt') ?? ''
    }
  }

  return text
}

function getElementByIdentifier(doc: Document, identifier: string): Element | null {
  try {
    return doc.evaluate(identifier, doc, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue as Element
  }
  catch (error) {
    console.error('Invalid XPath', identifier, error)
    return null
  }
}

export function replaceInnerContentFromIdentifier(
  html: string,
  identifier: string,
  newContent: string,
): string {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  const element = getElementByIdentifier(doc, identifier)
  if (element) {
    element.textContent = newContent
  }
  return doc.documentElement.innerHTML
}

export function replaceImageSrcFromIdentifier(
  html: HTMLStringWithIdentifiers,
  identifier: string,
  newSrc: string,
): HTMLStringWithIdentifiers {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  const element = getElementByIdentifier(doc, identifier)
  if (element) { element.setAttribute('src', newSrc) }

  return doc.documentElement.innerHTML
}
