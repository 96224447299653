<script setup lang="ts">
import {
  elementIsImageCandidate,
  elementIsTextCandidate,
  generateXpath,
} from '@/level4/utils/emailTemplates/candidateIdentification.ts'

interface Props {
  html: string
  subjectLine: string
  loading: boolean
}

withDefaults(defineProps<Props>(), { html: '',subjectLine: 'No subject Line', })

const emit = defineEmits<{
  'selectedElement': [HTMLElement | undefined]
}>()

const varySubjectLine = defineModel<boolean>('varySubjectLine', { required: true })
const selectedCtaHtmlIdentifiers = defineModel<{
  cta?: string
  image?: string
}>('selectedCtaHtmlIdentifiers', { required: true })

const loadedDocument = ref<DocumentFragment>()
const hoveredElement = ref<HTMLElement>()
const allLoadedElements = computed(() => [...loadedDocument.value?.querySelectorAll('*').values() ?? []])

const candidateElementsBoundingBoxes = ref<DOMRect[]>([])
const emailTemplatePreviewPlaygroundElement = ref<HTMLElement>()
const emailTemplatePreviewPlaygroundElementBoundingBox = useElementBounding(emailTemplatePreviewPlaygroundElement)
function updateBoundingBoxes() {
  candidateElementsBoundingBoxes.value = allLoadedElements.value.map(element => element.getBoundingClientRect())
}

const candidateElementsBoundingBoxesStyles = computed(() => {
  return candidateElementsBoundingBoxes.value.map(boundingBox => generateBoundingBoxStyle(boundingBox))
})

const { alt: metaMode } = useMagicKeys()

const candidateTypesMap = {
  cta: {
    label: 'CTA',
    icon: 'i-solar-cursor-outline',
  },
  image: {
    label: 'Image',
    icon: 'i-solar-gallery-circle-outline',
  },
}

const mappedAllowListedDomElements = computed(() => {
  if (!loadedDocument.value) { return [] }
  return allLoadedElements.value.map((loadedElement, index) => {
    const isTextCandidate = elementIsTextCandidate(loadedElement)
    const isImageCandidate = elementIsImageCandidate(loadedElement)
    const candidateType = isImageCandidate ? 'image' : 'cta' as const
    const candidateBoxLabel = candidateTypesMap[candidateType].label
    const labelIcon = candidateTypesMap[candidateType].icon
    // Small tweak to remove what shadow dom adds. it renders inside a root div instead a root body. Sorry for putting it here.
    const elementXPath = generateXpath(loadedElement).replace('//div[1]/', '//')
    return {
      candidateBoxLabel,
      candidateType,
      labelIcon,
      element: loadedElement,
      xPath: elementXPath,
      isIdentifiedCandidate: isTextCandidate || isImageCandidate,
      boundingBox: candidateElementsBoundingBoxes.value[index],
      style: candidateElementsBoundingBoxesStyles.value[index],
      tagName: loadedElement.tagName,
      isSelected: selectedCtaHtmlIdentifiers.value[candidateType] === elementXPath,
    }
  })
})

function generateBoundingBoxStyle(boundingBox: DOMRect) {
  return {
    top: `${boundingBox.top}px`,
    left: `${boundingBox.left}px`,
    width: `${boundingBox.width}px`,
    height: `${boundingBox.height}px`,
  }
}

onMounted(() => {
  window.addEventListener('resize', updateBoundingBoxes)
  window.addEventListener('scroll', updateBoundingBoxes)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateBoundingBoxes)
  window.removeEventListener('scroll', updateBoundingBoxes)
})

watch(
  () => [loadedDocument, emailTemplatePreviewPlaygroundElementBoundingBox],
  updateBoundingBoxes,
  { deep: true, immediate: true },
)

// If element does not have the attribute yy=$anyCandidates, then it is not hoverable
function handleElementHover(element: Element) {
  hoveredElement.value = element
}

function handleElementClick(allowListedDomEl: typeof mappedAllowListedDomElements.value[number]) {
  const { xPath, candidateType } = allowListedDomEl

  if (selectedCtaHtmlIdentifiers.value[candidateType] === xPath) {
    selectedCtaHtmlIdentifiers.value[candidateType] = undefined
    return
  }

  selectedCtaHtmlIdentifiers.value[candidateType] = xPath
}

function handleDocumentLoading(doc: Document) {
  loadedDocument.value = doc
}

</script>

<template>
  <div v-if="loading" min-w="500px" flex="~ col" gap="8">
    <NSkeleton container="~" height="80px" />
    <NSkeleton container="~" height="500px" />
  </div>
  <div
    v-else
    class="email-template-preview-playground"
    flex="~ col"
    gap="8"
  >
    <div
      class="subject-line-container"
      border="1"
      rounded="md"
    >
      <NCheckbox
        v-model:checked="varySubjectLine"
        :class="[varySubjectLine ? 'bg-offerfit-bright-purple/10 border-2 border-offerfit-bright-purple' : 'border-2 border-transparent hover:bg-gray-100']"
        relative="~"
        rounded="md"
        flex="~"
        items="center"
        gap="2"
        p="4"
        z="10"
        w="full"
      >
        <div
          class="subject-line-label"
          position="absolute"
          top="-5"
          left="0"
          p="x-3 y-px"
          rounded="sm"
          :bg="varySubjectLine ? 'brand-purple-bright' : 'gray-600'"
          text="white xs"
          font="medium"
          tracking="wider"
          flex="~"
          items="center"
          gap="1"
        >
          <div class="i-solar-plain-2-line-duotone" />
          <span>Subject line</span>
        </div>
        <div
          class="subject-line"
          text="lg"
        >
          {{ subjectLine }}
        </div>
      </NCheckbox>
    </div>
    <div
      v-if="!loading"
      border="1"
      rounded="md"
      position="relative"
    >
      <ShadowDomEmailTemplatePreview
        id="email-template-preview-playground"
        ref="emailTemplatePreviewPlaygroundElement"
        :html="html"
        @element-hover="handleElementHover"
        @document-loaded="doc => handleDocumentLoading(doc)"
        :filter="!mappedAllowListedDomElements.length ? 'blur-10 grayscale' : ''"
      />
      <div
        v-if="!mappedAllowListedDomElements.length"
        class="bg-hero-texture-white/50 absolute inset-0"
      />
      <NSpin
        v-if="!mappedAllowListedDomElements.length"
        data-test="loading-spinner"
        show
        class="absolute inset-x-0 top-20"
      />
    </div>
    <div class="selector-overlay">
      <div class="candidates-boxes">
        <template
          v-for="(allowListedDomEl, index) in mappedAllowListedDomElements"
          :key="index"
        >
          <div
            v-if="allowListedDomEl.isSelected || metaMode || allowListedDomEl.isIdentifiedCandidate"
            :style="[allowListedDomEl.style]"
            :is-selected="allowListedDomEl.isSelected"
            :is-identified-candidate="allowListedDomEl.isIdentifiedCandidate"
            class="candidate-box"
            :data-test="`candidate-box type-${allowListedDomEl.candidateType}`"
            @click="() => handleElementClick(allowListedDomEl)"
            @mouseover="() => handleElementHover(allowListedDomEl.element)"
          >
            <div
              v-if="hoveredElement === allowListedDomEl.element || allowListedDomEl.isSelected"
              class="select-box-tag"
              position="absolute"
              top="-5"
              left="-1"
              p="x-1 y-px"
              bg="primary"
              text="white xs"
              font="medium"
              flex="~"
              items="center"
              gap="1"
              @click.stop.prevent
            >
              <div :class="allowListedDomEl.labelIcon" w="4" />
              {{ allowListedDomEl.candidateBoxLabel }}
<!--              {{allowListedDomEl.xPath}} {{ selectedCtaHtmlIdentifiers[allowListedDomEl.candidateType] }}-->
              <NTooltip v-if="metaMode">
                <template #trigger>
                  <div class="flex items-center gap-1 lowercase opacity-60 hover:underline">
                    <{{ allowListedDomEl.tagName }}/>
                  </div>
                </template>
                <span text="sm">Code Preview</span>
                <CodeBlock
                  class="code-block"
                  max="w-2xl"
                  :content="allowListedDomEl.element.outerHTML"
                />
              </NTooltip>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.code-block:deep(pre) {
  @apply text-xs;
}

#email-template-preview-playground {
  &[is-loading="true"] {
    @apply grayscale-100 pointer-events-none;
  }
}

.candidate-box {
  @apply fixed z-10 border-1 cursor-pointer;

  &[is-selected="false"] {
    @apply border-transparent;
  }

  &:not(:hover)[is-identified-candidate="true"][is-selected="false"]{
    @apply border-gray shadow ring-gray border-dashed;
  }

  &:hover[is-selected="false"]{
    @apply border-primary ring-primary border-2;
  }

  &[is-selected="true"] {
    @apply ring-2 border-0 ring-primary ring-offset-2;
    & .select-box-tag {
      @apply bg-primary;
    }

    &:hover {
      @apply bg-primary/10 bg-hero-diagonal-lines-white/10;
    }
  }
}

</style>
