<script setup lang="tsx">
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'

const { experimenterFormState, setMarketingMessageVariationId } = useExperimenterFormState()

const currentBrazeMessageVariationId = ref('')
syncRefs(() => toRaw(experimenterFormState.value?.brazeMessageVariationId), currentBrazeMessageVariationId, { immediate: true })

const hasDataChanged = computed(() => experimenterFormState.value?.brazeMessageVariationId !== currentBrazeMessageVariationId.value)
const isStateDataNotEmpty = computed(() => !!experimenterFormState.value?.brazeMessageVariationId)
const isFormDataFilled = computed(() => !!currentBrazeMessageVariationId.value)

defineExpose<FormExposedProperties>({
  dataIsComplete: computed(() => isFormDataFilled.value),
  skipIsAvailable: computed(() => isStateDataNotEmpty.value && !hasDataChanged.value),
  submitHandler: () => {
    setMarketingMessageVariationId(currentBrazeMessageVariationId.value)
  },
})
</script>

<template>
  <WizardFormPageLayout containerSize="small" headerSize="small" footerSize="large" supertitle="Let's connect OfferFit with Braze" title="Please copy your campaign's message <span class='font-bold'>variation ID.</span>">
    <template #thirdSection>
      <BasicLink
        href="https://app.tango.us/app/workflow/Creating-a-Braze-API-Email-Campaign-for-OfferFit-241fcc8a8fa24172b15dac6ec9f2154b"
        target="_blank"
        class="cursor-pointer text-link"
        withExternalIcon
      >
        How to find your campaign's message variation ID
      </BasicLink>
    </template>
    <form flex="~ col" gap="4" items="center" w="full">
      <BasicInput v-model="currentBrazeMessageVariationId" label="Message variation ID" placeholder="paste your message variation ID here" data-sentry-mask />
    </form>
  </WizardFormPageLayout>
</template>

<style scoped>
</style>
