<script setup lang="ts">
import type {AssetStatus, AssetType} from '@level3/types/asset.ts'
import AssetDeleteModal from '@level3/components/AssetsList/AssetDeleteModal.vue'

type Props = {
  assetStatus: AssetStatus
  assetType: AssetType
  assetName: string
}

withDefaults(defineProps<Props>(), {  })

const isDeleteModalOpen = ref(false)

const isRunActionDisabled = computed(
  () => __props.assetStatus === 'concluded' || __props.assetStatus === 'running',
)

const isCustomerPopulationAsset = computed(
  () => __props.assetType === 'customer_population',
)

const handleOpenDeleteModal = () => {
  isDeleteModalOpen.value = true
}
</script>

<template>
  <AssetDeleteModal v-model:show="isDeleteModalOpen" :asset-name="assetName" />
  <div class="flex justify-end gap-2">
    <NTooltip trigger="hover" placement="bottom">
      <template #trigger>
        <NButton circle size="tiny" quaternary :disabled="isRunActionDisabled">
          <template #icon>
            <div class="i-solar-play-linear" />
          </template>
        </NButton>
      </template>
      Run changes
    </NTooltip>
    <NTooltip
      v-if="!isCustomerPopulationAsset"
      trigger="hover"
      placement="bottom"
    >
      <template #trigger>
        <NButton circle size="tiny" quaternary>
          <template #icon>
            <div class="i-solar-copy-linear" />
          </template>
        </NButton>
      </template>
      Duplicate
    </NTooltip>
    <NTooltip
      v-if="!isCustomerPopulationAsset"
      trigger="hover"
      placement="bottom"
    >
      <template #trigger>
        <NButton circle size="tiny" quaternary @click="handleOpenDeleteModal">
          <template #icon>
            <div class="i-solar-trash-bin-2-linear" />
          </template>
        </NButton>
      </template>
      Delete
    </NTooltip>
  </div>
</template>
