<script setup lang="ts">
import type { NullString } from '@shared/utils/Types.ts'
import type { AudienceModel } from '@/level4/models/braze/AudienceModel.ts'
import { SHORT_ERROR_MSG } from '@/level4/services/OFEService.ts'
import type { EmailCampaign } from '@/level4/composables/useExperimenterFormState.ts'

interface Props {
  selectedValue?: string
  listData: AudienceModel[] | EmailCampaign[] | undefined
  errorMap?: Map<string, Pick<AudienceModel, 'error' | 'errorCode'>>
  infoMap?: Map<string, number>
  noDataMessage: string
  dataErrorMessage: string
  isLoading: boolean
  searchPlaceholder: string
  queryIsSuccess?: boolean
}

withDefaults(defineProps<Props>(), { queryIsSuccess: true, })
const emit = defineEmits(['refresh', 'modelValueChange'])

const search = ref<NullString>()
const searchFilteredData = computed(() => {
  return search.value
    ? __props.listData?.filter((item) => {
      return item?.name?.toLowerCase().includes(search.value?.toLowerCase())
    })
    : __props.listData
})

// puts selected items at the top of the list for better UX
const sortedFilteredData = computed(() => {
  return searchFilteredData.value?.sort((a, b) => {
    if (__props.selectedValue === a.id && __props.selectedValue !== b.id) {
      return -1
    }
    if (__props.selectedValue !== a.id && __props.selectedValue === b.id) {
      return 1
    }
    return 0
  })
})

function refreshDataClickHandler() {
  emit('refresh')
}

function getHelpText(item: AudienceModel): string {
  const errorCode = __props.errorMap?.get(item.id)?.errorCode
  const shortErrorMsg = errorCode && SHORT_ERROR_MSG[errorCode]
  if (__props.infoMap) {
    if (errorCode && shortErrorMsg) {
      return shortErrorMsg
    }
    if (__props.infoMap?.get(item.id) !== undefined) {
      return `${__props.infoMap?.get(item.id) ?? 0} recipients`
    }
    if (__props.infoMap?.get(item.id) === undefined && __props.errorMap?.get(item.id) === undefined && __props.selectedValue === item.id) {
      return 'Loading...'
    }
  }
  return ''
}
</script>

<template>
  <div class="w-full">
    <div v-if="isLoading">
      <NSpin class="mt-4 h-250px w-full" />
    </div>

    <div v-else-if="queryIsSuccess">
      <BasicInput v-model="search" type="text" :placeholder="searchPlaceholder" isSearchBar class="mb-4" />
      <div v-if="sortedFilteredData?.length > 0">
        <NCard embedded class="overflow-y-auto">
          <NRadioGroup
            :value="selectedValue"
            class="max-h-[18rem] flex! flex-col gap-4"
            @update:value="(e) => emit('modelValueChange', e)"
          >
            <RadioInput
              v-for="item in sortedFilteredData" :key="`${item.id}_${errorMap?.get(item.id)?.error}_${infoMap?.get(item.id)}`"
              :isSelected="selectedValue === item.id" :value="item.id" :label="item.name"
              :helperText="getHelpText(item)" :errorText="errorMap?.get(item.id)?.error"
              class="bg-white" data-test="radio-select-item"
            />
          </NRadioGroup>
        </NCard>
      </div>
      <div v-if="sortedFilteredData?.length <= 0" class="h-[18rem] p-6 flex flex-col items-center justify-center gap-2 border-1 border-gray-100 rounded-md text-gray-400">
        {{ noDataMessage }}
      </div>
    </div>
    <div v-else class="max-w-[42rem] h-[18rem] p-6 flex flex-col items-center justify-center gap-2 border-1 border-gray-100 rounded-md text-gray-400">
      {{ dataErrorMessage }}
    </div>
    <div class="flex cursor-pointer justify-center text-sm font-medium text-offerfit-bright-purple mt-4" @click="refreshDataClickHandler">
      <div class="i-solar-refresh-bold mr-2 h-5 w-5" />
      Refresh list
    </div>
  </div>
</template>
