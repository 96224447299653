<script setup lang="ts">
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState.ts'
import { useSFMC } from '@/level4/composables/useSFMC'
import NameExternalKeyPairTable
  from '@/level4/views/modules/launch-sfmc/sfmc-data-queries/NameExternalKeyPairTable.vue'

const { getOFEBaseDataExtensionQuery } = useSFMC()
const { baseAudienceExtensionNameToOverrwrite } = useExperimenterFormState()
</script>

<template>
  <p>
    3.1 Drag and drop "SQL Query" to the Step 1 column, then press the “Choose” button to set up the first data query.
    <NImage src="/integration-instructions/sfmc/launch-sfmc/data-queries/5_workflow_sql_query.png" />
  </p>
  <p>
    3.2 Press “Create New Query Activity” button.
    <NImage src="/integration-instructions/sfmc/launch-sfmc/data-queries/6_create_new_query.png" />
  </p>
  <p>
    3.3 At the “Create New Query Activity” screen enter "OFE_Subscribers_query" for the Name and External Key fields, or any other name as long as both fields match. Press “Next” to continue.
    <NameExternalKeyPairTable :data="[{ name: 'OFE_Subscribers_query', externalKey: 'OFE_Subscribers_query' }]" />
    <NImage src="/integration-instructions/sfmc/launch-sfmc/data-queries/7_create_new_query_activity.png" />
  </p>
  <p>
    3.4. At the “Query” step, copy and paste the below SQL Query into the "SQL Query" textbox. Press “Next” to continue.
    <NImage src="/integration-instructions/sfmc/launch-sfmc/data-queries/8_copy_query_textarea.png" />
    <CodeBlock :content="getOFEBaseDataExtensionQuery()" language="sql" />
  </p>
  <p>
    3.5 At the “Target Data Extension” step select the data extension with following name. Set the Data Action to "Overwrite". Press “Next” to continue.`
    <NameExternalKeyPairTable :data="[{ name: baseAudienceExtensionNameToOverrwrite }]" />
    <NImage src="/integration-instructions/sfmc/launch-sfmc/data-queries/9_data_extension_overwrite.png" />
  </p>
  <p>
    3.6 Press “Finish” to save the first SQL Query Activity.
    <NImage src="/integration-instructions/sfmc/launch-sfmc/data-queries/10_save.png" />
  </p>
</template>
