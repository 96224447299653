<script setup lang="ts">
import NewAutomationInstructions from './NewAutomationInstructions.md'
import ScheduleForAutomationInstructions from './ScheduleForAutomationInstructions.md'
import CreateSubscribersQueryInstructions from './CreateSubscribersQueryInstructions.vue'
import CreateEngagementQueryInstructions from './CreateEngagementQueryInstructions.vue'
import RunOnceCreatedInstructions from './RunOnceCreatedInstructions.md'
import ResourcesToUseQueryInstructions from './ResourcesToUseQueryInstructions.vue'
import TokensMinimapSfmcLaunch from '@/level4/views/modules/launch-sfmc/TokensMinimapSfmcLaunch.vue'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

interface Props {
  stepName: string
}

const props = defineProps<Props>()

const { updateExperimenterFormState } = useExperimenterFormState()

defineExpose<FormExposedProperties>({
  submitHandler: async () => {
    await updateExperimenterFormState({ sfmcQuerySubmitClicked: true })
  },
})

const helpSteps = [
  {
    label: 'Go to SFMC and create new Automation',
    content: NewAutomationInstructions,
  },
  {
    label: 'Set up a schedule for new Automation',
    content: ScheduleForAutomationInstructions,
  },
  {
    label: 'Create new Subscribers Query',
    content: CreateSubscribersQueryInstructions,
  },
  {
    label: 'Create new Engagement Query',
    content: CreateEngagementQueryInstructions,
  },
  {
    label: 'Run once created automation',
    content: RunOnceCreatedInstructions,
  },
]

const introductoryStep
  = {
    label: '*Resources to use',
    content: ResourcesToUseQueryInstructions,
  }
</script>

<template>
  {{ props.stepName }}
  <WizardFormPageLayout
    containerSize="full"
    supertitle="Finish setting up your experimenter"
    title="The experimenter will be ready to go live after completing the last few SFMC steps."
  >
    <TokensMinimapSfmcLaunch :stepName="props.stepName" />
    <MultipleStepsHelp
      header="Set up a scheduled data export for 2 data queries"
      :helpSteps="helpSteps"
      :fullMode="true"
      :introductoryStep="introductoryStep"
    />
  </WizardFormPageLayout>
</template>
