<script lang="ts" setup>
import { NCard, NRadio } from 'naive-ui'

defineProps({
  isChecked: {
    type: Boolean,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
})

const emit = defineEmits<{
  (e: 'on-change', event: Event): void
}>()
</script>

<template>
  <NCard :bordered="false">
    <div class="flex flex-col">
      <NRadio
        :checked="isChecked"
        :value="value"
        :name="name"
        @change="(e: Event) => emit('on-change', e)"
      >
        {{ label }}
      </NRadio>
      <p class="ml-6">
        {{ description }}
      </p>
    </div>
  </NCard>
</template>

<style scoped>
:deep(.n-radio__label) {
    @apply font-medium;
}
</style>
