<script setup lang="ts">
import OpenJourneyBuilderInstructions
  from './OpenJourneyBuilderInstructions.vue'
import DragAndDropDecisionBuilderInstructions
  from './DragAndDropDecisionBuilderInstructions.vue'
import CreatePathsForBaseEmailsInstructions
  from './CreatePathsForBaseEmailsInstructions.vue'
import SetEmailNodeInstructions from './SetEmailNodeInstructions.vue'
import ActivateJourneyInstructions from './ActivateJourneyInstructions.md'
import TokensMinimapSfmcLaunch from '@/level4/views/modules/launch-sfmc/TokensMinimapSfmcLaunch.vue'
import ResourcesToUseInstructions
  from '@/level4/views/modules/launch-sfmc/sfmc-finish-journey/ResourcesToUseInstructions.vue'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { PLATFORM_DISPLAY_NAME } from '@/level4/data/l4_constants'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

const props = defineProps<{
  stepName: string
}>()

const { experimenterFormState, updateExperimenterFormState } = useExperimenterFormState()
const helpSteps = [
  {
    label: 'Go to SFMC and open Journey Builder',
    content: OpenJourneyBuilderInstructions,
  },
  {
    label: 'Drag and drop a Decision Split node',
    content: DragAndDropDecisionBuilderInstructions,
  },
  {
    label: 'Create Paths for base emails',
    content: CreatePathsForBaseEmailsInstructions,
  },
  {
    label: 'Set Email node for each Path',
    content: SetEmailNodeInstructions,
  },
  {
    label: 'Activate the journey',
    content: ActivateJourneyInstructions,
  },
]

const introductoryStep = {
  label: '* Resources to use',
  content: ResourcesToUseInstructions,
}

const pageTitle = experimenterFormState.value?.platformNeedsUpdateFlag
  ? `You've made changes to your templates, please make sure you make the appropriate changes in ${PLATFORM_DISPLAY_NAME[experimenterFormState.value?.platform || '']}`
  : `Please complete a few last ${PLATFORM_DISPLAY_NAME[experimenterFormState.value?.platform || '']} steps and you’re ready to go live!`

defineExpose<FormExposedProperties>({
  submitHandler: async () => {
    await updateExperimenterFormState({ sfmcJourneySubmitClicked: true })
  },
  nextFormButtonText: 'Validate results',
})
</script>

<template>
  <WizardFormPageLayout
    containerSize="full"
    :title="pageTitle"
  >
    <TokensMinimapSfmcLaunch :stepName="props.stepName" />
    <MultipleStepsHelp
      header="Finish setting up the Journey that OfferFit created"
      :helpSteps="helpSteps"
      :fullMode="true"
      :introductoryStep="introductoryStep"
    />
  </WizardFormPageLayout>
</template>
