import * as Sentry from '@sentry/vue'
import hat from 'hat'
import { handlePortalError } from '@shared/utils/errorHandling.ts'
import Global from '@shared/utils/global'
import router from '@router/index'
import { useExperimenterFormState } from '../composables/useExperimenterFormState'
import { useClientConfig } from '@/data-assets/utils/config'
import { SERVICE_ERROR_GLOBAL_MESSAGE } from '@/level4/data/l4_constants'
import type {
  VariantsCartesianProductOutput,
} from '@/level4/utils/variants/variantsCartesianProduct'
import {
  campaignVariantsCartesianProduct,
} from '@/level4/utils/variants/variantsCartesianProduct'
import {
  getTextByIdentifier,
} from '@/level4/utils/LLM/assignIdentifiersToHtmlString'
import type { LLMServiceGenerateVariantsOptions } from '@/level4/services/LLMService'
import { LLMService } from '@/level4/services/LLMService'
import { extractTextFromHtml } from '@/level4/utils/LLM/extractTextFromHtml'
import AppSettings from '@app/AppSettings'

export interface GeneratedVariantsOption {
  id: string
  label: string
  tags: string[]
  // When creating new variants, this hold the minimum levenshtein in comparison to all *existing* variants
  minDistance?: number
  variantLink?: GeneratedVariantsOption
}

export interface LLMParsedCtaAndHeroCandidates {
  cta: {
    main: number
    candidates: number[]
  }
  hero_img: {
    main: number
    candidates: number[]
  }
}

export interface ExperimenterVariantsFormStateType {
  varySubjectLine: boolean
  varyCTA: boolean
  varyHeroImage: boolean
  selectedCTAHtmlElementIdentifiers: {
    cta: string | undefined
    image: string | undefined
  }
  htmlCandidates: LLMParsedCtaAndHeroCandidates | undefined
  toneGuidanceFeedback: string | undefined
  feedbackForGeneratedSubjectLines: string | undefined
  generatedSubjectLinesVariants: GeneratedVariantsOption[]
  tooSimilarSubjectLinesVariants: GeneratedVariantsOption[]
  feedbackForGeneratedCta: string | undefined
  generatedCtaVariants: GeneratedVariantsOption[]
  tooSimilarCtaVariants: GeneratedVariantsOption[]
  selectedImagesForVariants: string[]
  generatedVariants: VariantsCartesianProductOutput

}

export const useExperimenterVariantsFormsStore = defineStore('ExperimenterVariantsFormStore', () => {
  const { clientName, usecaseName } = AppSettings
  const campaignTemplateId = ref('')
  const state = reactive<ExperimenterVariantsFormStateType>(createDefaultState())
  const { experimenterFormState, updateExperimenterFormState, generatedVariantsCombinatoryList } = useExperimenterFormState()
  const { clientObject } = useClientConfig()
  const isLoaded = ref(false)

  function createDefaultState(): ExperimenterVariantsFormStateType {
    return {
      tooSimilarCtaVariants: [],
      tooSimilarSubjectLinesVariants: [],
      varySubjectLine: false,
      varyCTA: false,
      varyHeroImage: false,
      selectedCTAHtmlElementIdentifiers: {
        cta: undefined,
        image: undefined,
      },
      toneGuidanceFeedback: undefined,
      feedbackForGeneratedSubjectLines: undefined,
      generatedSubjectLinesVariants: [],
      feedbackForGeneratedCta: undefined,
      generatedCtaVariants: [],
      selectedImagesForVariants: [],
      generatedVariants: [],
      htmlCandidates: undefined,
    }
  }

  const currentTemplate = computed(() => {
    const templates = experimenterFormState.value?.currentImportedTemplates
    if (!templates) { return undefined }
    return templates.find(template => template.id === campaignTemplateId.value)
  })

  watch(() => [router.currentRoute.value?.params?.templateId], () => {
    if (router.currentRoute.value?.params?.templateId === undefined) {
      return
    }

    if (Array.isArray(router.currentRoute.value?.params?.templateId)) {
      campaignTemplateId.value = router.currentRoute.value?.params?.templateId[0]
    }
    else {
      campaignTemplateId.value = router.currentRoute.value?.params?.templateId
    }
  }, { immediate: true })

  watch(currentTemplate, async () => {
    if (!campaignTemplateId.value) { return }
    if (!currentTemplate.value) { return }

    const hasStartedVariantsCreation = !!(experimenterFormState.value?.variantsDataMap[String(campaignTemplateId.value)]?.varySubjectLine || experimenterFormState.value?.variantsDataMap[String(campaignTemplateId.value)]?.varyCTA || experimenterFormState.value?.variantsDataMap[String(campaignTemplateId.value)]?.varyHeroImage)

    if (!hasStartedVariantsCreation) {
      // experimenterFormState.value.variantsDataMap[String(campaignTemplateId.value)] = createDefaultState()
      const variantsDataMap = toRaw(experimenterFormState.value!.variantsDataMap)
      variantsDataMap[campaignTemplateId.value] = createDefaultState()
      await updateExperimenterFormState({ variantsDataMap })
    }

    Object.assign(state, toRaw(experimenterFormState.value?.variantsDataMap[String(campaignTemplateId.value)]))
    isLoaded.value = true
  }, { immediate: true })

  async function updateVariantsDataMap() {
    if (isLoaded.value && campaignTemplateId.value) {
      // experimenterFormStore.patchVariant(String(campaignTemplateId.value), { ...state })
      const variantsDataMap = toRaw(experimenterFormState.value!.variantsDataMap)
      variantsDataMap[campaignTemplateId.value] = { ...state }
      await updateExperimenterFormState({ variantsDataMap })
    }
  }

  const templateHtml = computed(() => currentTemplate.value?.html_content ?? '')

  const emailBody = computed(() => {
    return extractTextFromHtml(templateHtml.value)
  })

  const subjectLine = computed(() => {
    if (!currentTemplate.value) { return '' }
    return currentTemplate.value?.subject_line
  })

  const selectedCtaTextContent = computed(() => {
    return getTextByIdentifier(templateHtml.value, state.selectedCTAHtmlElementIdentifiers?.cta).replace(/\s\s+/g, ' ').trim()
  })

  async function generateVariantsFor(type: 'subject_line' | 'cta', user_feedback?: string, previous_variants?: GeneratedVariantsOption[]) {
    const variants = await generateLLMVariants({
      type_of_variant: type,
      user_feedback: user_feedback ?? state.toneGuidanceFeedback,
      previous_variants: previous_variants?.map(v => v.label).join(','),
    })

    return variants?.map((variant) => {
      return {
        id: hat(), // not sure if we use it
        label: variant.text,
        tags: variant.tags,
      } as GeneratedVariantsOption
    })
  }

  async function loadSubjectLineVariants() {
    return generateVariantsFor('subject_line', state.feedbackForGeneratedSubjectLines, state.generatedSubjectLinesVariants)
  }
  async function loadCtaVariants() {
    return generateVariantsFor('cta', state.feedbackForGeneratedCta, state.generatedCtaVariants)
  }

  async function generateLLMVariants(
    options: Pick<LLMServiceGenerateVariantsOptions, 'type_of_variant' | 'user_feedback' | 'previous_variants'>,
  ) {
    try {
      if (!experimenterFormState?.value?.platform) { return }
      return await LLMService.generateVariants({
        platform: experimenterFormState.value.platform,
        type_of_variant: options.type_of_variant,
        email_body: emailBody.value,
        company_information: clientObject.value?.config?.industry || 'No company information',
        subject_line: subjectLine.value,
        call_to_action: selectedCtaTextContent.value,
        number_of_variants: 5,
        user_feedback: options.user_feedback,
        previous_variants: options.previous_variants,
      })
    }
    catch (e: any) {
      Global.error(SERVICE_ERROR_GLOBAL_MESSAGE)
      console.error(e)
      Sentry.captureException(e)
      return undefined
    }
  }

  const calculatedTotalVariants = computed(() => {
    return (state.generatedCtaVariants?.length || 1) * (state.generatedSubjectLinesVariants?.length || 1) * (state.selectedImagesForVariants?.length || 1)
  })

  async function generateVariants() {
    if (!state.varySubjectLine) {
      state.generatedSubjectLinesVariants = []
    }

    if (!state.selectedCTAHtmlElementIdentifiers.cta) {
      state.generatedCtaVariants = []
    }

    if (!state.selectedCTAHtmlElementIdentifiers.image) {
      state.selectedImagesForVariants = []
    }

    state.generatedVariants = await campaignVariantsCartesianProduct(
      currentTemplate.value!.id,
      clientName.value,
      usecaseName.value,
      state.generatedSubjectLinesVariants,
      state.generatedCtaVariants,
      state.selectedImagesForVariants,
    )
    await updateVariantsDataMap()
  }

  const updateSelectedImagesForVariants = async (selectedImagesForVariants: string[]) => {
    state.selectedImagesForVariants = selectedImagesForVariants
    await updateVariantsDataMap()
  }

  const updateGeneratedSubjectLinesVariants = async (generatedSubjectLinesVariants: GeneratedVariantsOption[]) => {
    state.generatedSubjectLinesVariants = generatedSubjectLinesVariants
    await updateVariantsDataMap()
  }

  const updateChosenElements = async (payload: {
    cta: string | undefined
    image: string | undefined
  }, varySubjectLine: boolean) => {
    state.selectedCTAHtmlElementIdentifiers = payload
    state.varySubjectLine = varySubjectLine
    state.varyCTA = !!payload.cta
    state.varyHeroImage = !!payload.image
    await updateVariantsDataMap()
  }

  const updateCTAUserFeedback = (feedbackForGeneratedCta?: string) => {
    state.feedbackForGeneratedCta = feedbackForGeneratedCta
  }

  const updateCTATextSelection = async ({
    generatedCtaVariantsOptions,
    tooSimilarCtaVariants,
    feedbackForGeneratedCta,
  }: {
    generatedCtaVariantsOptions: GeneratedVariantsOption[]
    tooSimilarCtaVariants: GeneratedVariantsOption[]
    feedbackForGeneratedCta: string | undefined
  }) => {
    state.generatedCtaVariants = generatedCtaVariantsOptions
    state.tooSimilarCtaVariants = tooSimilarCtaVariants
    state.feedbackForGeneratedCta = feedbackForGeneratedCta
    await updateVariantsDataMap()
  }


  return {
    ...toRefs(state),
    isLoaded,
    currentTemplate,
    templateHtml,
    updateSelectedImagesForVariants,
    // Subject Lines
    loadSubjectLineVariants,
    updateGeneratedSubjectLinesVariants,
    updateChosenElements,
    updateCTATextSelection,
    // Html CTA Variants detection
    subjectLine,
    emailBody,
    // CTA Variants
    loadCtaVariants,
    updateCTAUserFeedback,
    calculatedTotalVariants,
    generateVariants,
    $reset: () => Object.assign(state, createDefaultState()),
  }
})
