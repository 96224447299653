<script setup lang="ts">
import { timeZoneOptions } from '@/level4/data/l4_mapAndLists'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

const { experimenterFormState, updateSelectedTimeZone, getRecentTimeZones } = useExperimenterFormState()

const currentSelectedTimeZone = ref<string | null>(null)
const recentlySelectedTimeZones = ref<string[]>([])

onMounted(async () => {
  recentlySelectedTimeZones.value = await getRecentTimeZones()
})

defineExpose<FormExposedProperties>({
  dataIsComplete: computed(() => !!currentSelectedTimeZone.value),
  submitHandler: async () => {
    await updateSelectedTimeZone(currentSelectedTimeZone.value!)
  },
})

const valueToLabelMap = timeZoneOptions.reduce((acc, option) => {
  acc[option.value] = option.label
  return acc
}, {} as Record<string, string>)

watch(() => experimenterFormState.value?.selectedTimeZone, (timeZone) => {
  currentSelectedTimeZone.value = toRaw(timeZone!)
}, { immediate: true })
</script>

<template>
  <WizardFormPageLayout containerSize="small" headerSize="medium" title="Next we’ll choose times of day to send emails." subtitle="What time zone should we use?">
    <BasicSelect id="select-timezone-standard" v-model="currentSelectedTimeZone" :options="timeZoneOptions" />
    <div v-if="recentlySelectedTimeZones.length" class="my-4 max-w-[400px] flex flex-wrap items-start gap-2">
      <p class="text-gray-400 mt-0.5">
        Recently used:
      </p>
      <div class="flex flex-col gap-2">
        <NButton v-for="(recentTimeZone, index) in recentlySelectedTimeZones" :key="index" tertiary type="tertiary" size="small" @click="currentSelectedTimeZone = recentTimeZone">
          {{ valueToLabelMap[recentTimeZone] }}
        </NButton>
      </div>
    </div>
  </WizardFormPageLayout>
</template>

<style scoped>
.radio-item {
  @apply flex flex-col gap-1;
&[active="false"] {
   @apply text-gray-400;
 }
}
.recipient-timezone-ifnull-selector {
  @apply p-2 ml-4 mb-4
}
</style>
