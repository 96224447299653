<script setup lang="tsx">
interface Props {
  tagItems: {
    label: string
    value: string | number
  }[]
  selectedTags: string[] | number[]
  disabledTags: string[] | number[]
}
withDefaults(defineProps<Props>(), { disabledTags: () => ([]), })

const emit = defineEmits<{
  modelValueChange: string[]
}>()

const modelValue = ref<string[] | number[]>(__props.selectedTags || [])

function chipClickHandler(tag: string | number) {
  if (!modelValue.value.includes(tag)) { modelValue.value.push(tag) }
  else { modelValue.value = modelValue.value.filter((item: string | number) => item !== tag) }

  emit('modelValueChange', modelValue.value)
}

function isItemChecked(item: string | number): boolean {
  return !!modelValue.value?.includes(item)
}
</script>

<template>
  <div class="testing-div" flex="~" justify="start">
    <template v-for="(item, idx) in tagItems" :key="idx">
      <button id="chip-select" class="chip-select-group text-nowrap" flex="~" :active="isItemChecked(item.value)" :disabled="disabledTags.includes(item.value)" @click="chipClickHandler(item.value)">
        <div class="label w-fit">
          {{ item.label }}
        </div>
      </button>
    </template>
  </div>
</template>

<style scoped>
.chip-select-group {
  @apply flex gap-1  cursor-pointer p-1 px-3 border-1 rounded-full m-2 bg-white text-offerfit-bright-purple;

  &:hover {
   @apply shadow-lg ring-offerfit-bright-purple/50 ring-offset-2 ring-2;
  }

  &[active="true"] {
     @apply bg-offerfit-bright-purple text-white border-transparent;
  }

  & .label {
    @apply font-semibold;
  }
}
</style>
