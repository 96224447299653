<script setup lang="ts">
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'
import ToneGuidance from './tone-guidance/Form.vue'
import CtaTextSelection from './cta-text-selection/Form.vue'
import SubjectLineSelection from './subject-lines/Form.vue'
import HeroImageSelection from './hero-image-selection/Form.vue'
import Confirm from './confirm/Form.vue'
import { CreateVariantsSteps } from '@/level4/data/forms'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'
const router = useRouter()
const { experimenterFormState, isFormStateLoading } = useExperimenterFormState()

async function stepsMap(){
  await until(isFormStateLoading).toBe(false)
  const variantsDataMap = experimenterFormState.value?.variantsDataMap
  const templateId = router.currentRoute.value.params?.templateId as string
  const hasToneGuidance = !!variantsDataMap?.[templateId]?.toneGuidanceFeedback
  const shouldVarySubjectLine = variantsDataMap?.[templateId]?.varySubjectLine
  const shouldVaryCTA = variantsDataMap?.[templateId]?.varyCTA
  const shouldVaryHeroImage = variantsDataMap?.[templateId]?.varyHeroImage
  const shouldSetToneGuidance = !hasToneGuidance && (shouldVarySubjectLine || shouldVaryCTA)

  const steps = [
    shouldSetToneGuidance ? {
        name: CreateVariantsSteps.TONE_GUIDANCE,
        component: ToneGuidance,
    } : undefined,
    shouldVarySubjectLine ? {
        name: CreateVariantsSteps.SELECT_SUBJECT_LINES,
        component: SubjectLineSelection,
    } : undefined,
    shouldVaryCTA ?   {
        name: CreateVariantsSteps.CTA_TEXT_SELECTION,
        component: CtaTextSelection,
    } : undefined,
    shouldVaryHeroImage ? {
        name: CreateVariantsSteps.HERO_IMAGE_SELECTION,
        component: HeroImageSelection,
    } : undefined,
    {
        name: CreateVariantsSteps.CONFIRM,
        component: Confirm,
    },
  ]
  return steps.filter(Boolean)
}

</script>

<template>
  <div>
    <L4WizardContainer :steps="stepsMap" />
  </div>
</template>
