<script setup lang="ts">
import { handlePortalError } from '@shared/utils/errorHandling.ts'
import BlueInfoMessage from '@shared/components/basic/static/BlueInfoMessage.vue'
import AppSettings from '@app/AppSettings.ts'
import type { AudienceModel } from '@/level4/models/braze/AudienceModel'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { INTEGRATION_PLATFORM, PLATFORM_DISPLAY_NAME } from '@/level4/data/l4_constants'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

const {
  experimenterFormState,
  updateExperimenterFormState,
  validateAudience,
  loadAudiences,
  updateSelectedAudienceId,
} = useExperimenterFormState()
const { clientName, experimenterName, pipelineName } = AppSettings

const isLoadingAudienceDetail = ref<boolean>(false)
const isInfoOpen = ref<boolean>(true)
const currentSelectedAudienceIds = ref<string[]>([])
const audienceSizeMap = ref<Map<string, number>>(new Map())
const audienceErrorMap = ref<Map<string, Pick<AudienceModel, 'error' | 'errorCode'>>>(new Map())

const loadAudiencesQuery = useQuery({
  queryKey: ['get-audiences', clientName.value, experimenterName.value, pipelineName.value],
  queryFn: async () => getAudiences(),
  enabled: !!experimenterFormState.value?.name,
})

syncRefs(() => toRaw(experimenterFormState.value?.selectedAudienceIds || []), currentSelectedAudienceIds, { immediate: true })
async function validateSelectedAudiences() {
  const audiences = loadAudiencesQuery.data?.value?.filter((audience) => {
    return currentSelectedAudienceIds.value.includes(audience.id)
  })

  if (!audiences?.length) {
    return
  }

  isLoadingAudienceDetail.value = true
  for (const audience of audiences) {
    if (audienceSizeMap.value.get(audience.id) === undefined) {
      const validationData = await validateAudience(audience)
      if (validationData?.error_msg) {
        audienceErrorMap.value.set(audience.id, { error: validationData.error_msg, errorCode: validationData.error_code })
      }
      if (validationData && validationData.result.size !== null) {
        audienceSizeMap.value.set(audience.id, validationData.result.size)
      }
    }
  }
  isLoadingAudienceDetail.value = false
}

onMounted(async () => {
  watchThrottled(currentSelectedAudienceIds, validateSelectedAudiences, { throttle: 650, immediate: true })
})

async function getAudiences(useCache: boolean = true): Promise<AudienceModel[] | undefined> {
  try {
    const res = await loadAudiences(useCache)
    return res.data.attributes.importedAudiences
  }
  catch (e: any) {
    handlePortalError(e, { defaultUserErrorText: `Couldn't load audiences. ${experimenterFormState.value?.importedAudiences.length ? 'You can see audiences taken from cache. ' : ''}Please check that the integration data is correct` })
    return experimenterFormState.value?.importedAudiences
  }
}

async function getAudiencesWithoutCache(): Promise<void> {
  await getAudiences(false)
}

async function handleUpdateAudienceId(audienceIds: string[]) {
  if (!audienceIds) {
    return
  }
  try {
    await updateSelectedAudienceId(audienceIds)
  }
  catch (e: any) {
    handlePortalError(e, { defaultUserErrorText: 'Couldn\'t set audience data. Please try again later.' })
  }
}

const invalidAudiences = computed(() => {
  return loadAudiencesQuery.data?.value?.filter((audience) => {
    return currentSelectedAudienceIds.value.includes(audience.id)
  }).filter((audience) => {
    return audience.error
  })
})

const title = computed(() => `Here are the lists of users I found in your ${PLATFORM_DISPLAY_NAME[experimenterFormState.value?.platform || '']} account.`)

// const filteredImportedAudiences = computed(() => {
//   if (loadAudiencesQuery?.isPending.value) { return }
//   console.log('loadAudiencesQuery.data?.value', loadAudiencesQuery.data?.value)
//   return search.value
//     ? loadAudiencesQuery.data?.value?.filter((audience) => {
//       return audience.name.toLowerCase().includes(search.value.toLowerCase())
//     })
//     : loadAudiencesQuery.data?.value
// })

async function refreshDataClickHandler(): Promise<void> {
  audienceSizeMap.value.clear()
  audienceErrorMap.value.clear()
  await getAudiencesWithoutCache()
  await validateSelectedAudiences()
}

const isDataLoading = computed(() => loadAudiencesQuery.isPending.value && isLoadingAudienceDetail.value)
const hasSelectedAudiences = computed(() => !!currentSelectedAudienceIds.value.length)
const hasInvalidAudiences = computed(() => !!invalidAudiences.value?.length)
const dataIsComplete = computed(() => !isDataLoading.value && hasSelectedAudiences.value && !hasInvalidAudiences.value)

defineExpose<FormExposedProperties>({
  dataIsComplete,
  submitHandler: async () => {
    // TODO: refactor this part and make it isolated (the form should not include platform specific code)
    if (
      experimenterFormState.value?.platform === INTEGRATION_PLATFORM.SFMC
      && experimenterFormState.value?.sfmcQuerySubmitClicked
      && (experimenterFormState.value?.selectedAudienceIds.length !== currentSelectedAudienceIds.value.length
      || !experimenterFormState.value?.selectedAudienceIds.every(id => currentSelectedAudienceIds.value.includes(id)))) {
      // means audience selection was changed from previous selection
      // and the query step has been done. This means the user needs
      // to re-do the query step
      await updateExperimenterFormState({ sfmcQueryNeedsUpdateFlag: true })
    }
    await handleUpdateAudienceId(currentSelectedAudienceIds.value!)
  },
})

function setAudienceValue(value: string[]) {
  currentSelectedAudienceIds.value = value
}
</script>

<template>
  <WizardFormPageLayout
    containerSize="full" headerSize="medium" :title="title"
    subtitle="Which of these groups would you like to use as the audience for this experimenter?"
  >
    <div class="flex justify-center">
      <div class="max-w-[42rem] w-full flex flex-col gap-6">
        <span v-if="!loadAudiencesQuery.isPending.value">
          <BlueInfoMessage
            v-if="isInfoOpen && !experimenterFormState?.sfmcQuerySubmitClicked && experimenterFormState?.platform === INTEGRATION_PLATFORM.SFMC"
            closable
            link="https://help.salesforce.com/s/articleView?language=en_US&id=sf.mc_es_list_versus_data_extension.htm&type=5"
            title="Your experiment population must be reflected in your data extensions list."
            subtitle="OfferFit will match the Data Extension Subscriber Key to the relevant subscriber and key."
            extendedSubtitle="If you use Subscriber Lists, you will need to convert them to Data Extensions to use OfferFit. You can read more about Lists versus Data Extensions by clicking on &quot;Learn more&quot;."
            @dismiss="isInfoOpen = false"
          />
          <YellowWarning
            v-if="isInfoOpen && experimenterFormState?.sfmcQuerySubmitClicked && experimenterFormState?.platform === INTEGRATION_PLATFORM.SFMC"
            closable title="Pay attention, any changes in audience will require SFMC updates and pause a live experimenter"
            subtitle="If you make any changes, you will be needed to update SFMC query automation before going live again."
            @dismiss="isInfoOpen = false"
          />
          <YellowWarning
            v-if="invalidAudiences?.length" title="Your selection includes an incompatible audience."
            subtitle="You have selected audiences that are empty or have errors. These cannot be used in the experimenter, please update your selection to include only compatible audiences."
          />
        </span>
        <AudienceMultipleSelectCheckBox
          :arrayValue="currentSelectedAudienceIds"
          :listData="loadAudiencesQuery.data?.value"
          :errorMap="audienceErrorMap"
          :sizeMap="audienceSizeMap"
          :isLoading="loadAudiencesQuery.isPending.value"
          :queryIsSuccess="loadAudiencesQuery.isSuccess.value"
          noDataMessage="No audiences found."
          dataErrorMessage="Make sure you have created an audience in your platform and that it has at least one user.
              If at least one audience has been created in your platform and it does not appear here,
              please return to Step 1 - Platform Connection - and verify that all settings are correct."
          searchPlaceholder="Search for more audiences by group name..."
          @refresh="refreshDataClickHandler"
          @modelValueChange="setAudienceValue"
        />
      </div>
    </div>
  </WizardFormPageLayout>
</template>
