<script setup lang="ts">
defineProps<{
  assetName: string
}>()

const shouldShowModal = defineModel<boolean>('show', {required: true})

const handleCancel = () => {
  shouldShowModal.value = false
}

const handleDelete = () => {
  // Perform delete operation here
  shouldShowModal.value = false
}
</script>

<template>
  <NModal v-model:show="shouldShowModal" preset="dialog" :show-icon="false">
    <template #header>
      <div class="text-base font-medium">
        Are you sure you want to delete the data asset {{ assetName }}?
      </div>
    </template>
    <div class="pt-4">
      <p>
        This will permanently delete all connected features, events, and asset
        columns.
        <br />
        This action cannot be undone.
      </p>
    </div>
    <template #action>
      <NButton @click="handleCancel"> Cancel </NButton>
      <NButton type="error" @click="handleDelete"> Delete </NButton>
    </template>
  </NModal>
</template>
