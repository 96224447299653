<script setup lang="ts">
</script>

<template>
  <p>2.1 The API Event has already been configured for you. Leave that as is.
    <NImage src="/integration-instructions/sfmc/launch-sfmc/finish-journey/03_preconfigured_api.png"></NImage>
  </p>
  <p>2.2 Drag and drop a Decision Split node after the Entry Event.
    <NImage src="/integration-instructions/sfmc/launch-sfmc/finish-journey/04_drag_and_drop.png"></NImage>
  </p>
</template>
