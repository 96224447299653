<script setup lang="ts">
import { colors } from '@theme'

interface Props {
  isSelected: boolean
  value: string
  label: string
  helperText?: string
  warningText?: string
  errorText?: string
  classNames?: string
}

withDefaults(defineProps<Props>(), { classNames: '', })
const emit = defineEmits<{
  (e: 'updateIsSelected', isSelected: boolean): void
}>()

function onToggleSelected() {
  emit('updateIsSelected', !__props.isSelected)
}
</script>

<template>
  <div
      :class="`flex items-center gap-2 w-full rounded-md h-10 px-4 py-2 border-gray-300 border-1 ${classNames}`"
  >
    <NCheckbox
        :checked="isSelected"
        :value="value"
        @update:checked="onToggleSelected"
    />
    <div class="truncate text-sm">{{ label }}</div>
    <div v-show="helperText" class="text-sm text-gray-500">{{ helperText }}</div>
    <NTooltip v-if="warningText" placement="top" trigger="hover">
      <template #trigger>
        <NTag type="warning" :color="{ textColor: colors.brand.orange }" round :bordered="false" class="ml-a">
          Warning
          <template #icon>
            <div class="i-solar-danger-triangle-bold" m="l-1" text="sm"></div>
          </template>
        </NTag>
      </template>
      {{ warningText }}
    </NTooltip>
    <NTooltip v-if="errorText" placement="top" trigger="hover">
      <template #trigger>
        <NTag type="error" :color="{ textColor: colors.brand.red }" round :bordered="false" class="ml-a">
          Error
          <template #icon>
            <div class="i-solar-danger-triangle-bold" m="l-1" text="sm"></div>
          </template>
        </NTag>
      </template>
      {{ errorText }}
    </NTooltip>
  </div>
</template>
