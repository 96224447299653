//  this function extract any inner text from html tags.
// for example: <div>hello <span>world</span></div> -> hello world
// It should ignore not relevant tags like <script> and <style>
export function extractTextFromHtml(html: string): string {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  // Remove script and style elements
  Array.from(doc.querySelectorAll('script, style')).forEach(el => el.remove())

  // Replace multiple newline characters with a single space
  return (doc.body.textContent || '').replace(/\s\s+/g, ' ').trim()
}
