import type { NullString, TimeDetails } from '@shared/utils/Types'
import slugify from 'slugify'

// Date transformations

export function transDateStringShort(st: string): string {
  if (!st) { return '' }
  const options: object = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }
  const dt = new Date(st.replace(/ /g, '/'))
  dt.setDate(dt.getDate())
  return dt.toLocaleDateString('en-US', options)
}

export function transDateLong(date: Date) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

/**
 * Converts a date string in the format 'YYYY-MM-DD' to a Date object with the time set to zero hours.
 *
 * @param {NullString} dateString - The date string to convert. If null or undefined, the function returns undefined.
 * @returns {Date | undefined} A Date object with the time set to zero hours, or undefined if the input is null or undefined.
 */
export function newDateZeroHourObjectFromString(dateString: NullString): Date | undefined {
  if (!dateString) { return }

  if (!/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateString)) {
    throw new Error('Invalid date string format')
  }

  const [year, month, day] = dateString.split('-').map(Number)
  return new Date(year, month - 1, day)
}

export function getMilitaryTime(time: string, dayPart: string): string {
  const hours = Number(time.split(':')[0])
  const minutes = time.split(':')[1]

  if (dayPart === 'PM') {
    const pmHours = hours + 12
    return `${pmHours}:${minutes}`
  }
  else {
    return time
  }
}

export function getUSDayTime(militaryTime: string): TimeDetails {
  const parts = militaryTime.split(':')
  let hours = parts[0]
  const minutes = parts[1]
  const dayPart = Number(hours) < 12 ? 'AM' : 'PM'
  if (dayPart === 'PM') {
    hours = (Number(hours) - 12).toString()
  }
  if (hours.length === 1) {
    hours = `0${hours}`
  }

  return {
    time: `${hours}:${minutes}`,
    dayPart,
  }
}

export function dateFormat(date: Date, timeZone?: string) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone,
  }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

export function dateEuropeFormat(date: Date, timeZone?: string) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone,
  }

  return new Intl.DateTimeFormat('fr-CA', options).format(date)
}

export function dateTimeFormat(date: Date, timeZone?: string) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
  }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

export function timeFormat(date: Date, timeZone?: string) {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone,
  }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

export function dateFullTimeFormat(date: Date, timeZone?: string) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone,
  }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

export function monthShortDateTime(date: Date, timeZone?: string) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
  }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

export function stringDateToMonthShortDate(date: string) {
  const newDate = new Date(date)
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  }
  return new Intl.DateTimeFormat('en-US', options).format(newDate)
}

export function dateTimeFormatShort(date: Date) {
  return date.toISOString().replace('T', ' ').substring(0, 19)
}

/**
 * Generates an array of date strings in ISO format between the given start and end dates,
 * optionally excluding the last N days.
 *
 * @param {Date} start - The start date.
 * @param {Date} end - The end date.
 * @param {number} [excludeLastNDays] - The number of days to exclude from the end date.
 * @returns {string[]} An array of date strings in ISO format (YYYY-MM-DD).
 */
export function getDateRangeArray(start: Date, end: Date, excludeLastNDays: number = 0): string[] {
  const arr: string[] = []
  const lastDayToInclude = new Date(end)
  lastDayToInclude.setDate(lastDayToInclude.getDate() - excludeLastNDays)
  // eslint-disable-next-line no-unmodified-loop-condition
  for (const dt: Date = new Date(start); dt <= lastDayToInclude; dt.setDate(dt.getDate() + 1)) {
    const date = new Date((dt.getTime() - dt.getTimezoneOffset() * 60 * 1000))
    arr.push(date.toISOString().split('T')[0])
  }
  return arr
}

export function getDateSubtractedByDays(date: Date, days: number = 0) {
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() - days)
  return newDate
}

// string transformations

export function slugString<T = string>(value: string, replacement: string = '_', toLowerCase: boolean = true, trim: boolean = true): T {
  // Count trailing underscores in original value
  const trailingUnderscores = value.match(/_+$/)?.[0]?.length || 0

  // Apply normal slugify transformation
  let slugified = slugify(value, {
    replacement,
    lower: toLowerCase,
    strict: true,
    trim,
  })

  // Add back any trailing underscores that were present
  if (trailingUnderscores > 0) {
    slugified += '_'.repeat(trailingUnderscores)
  }

  return slugified as T
}

export function getStandardizedString(column: string, toLowerCase: boolean = true, trim: boolean = true): string {
  // Column name standardization function modeled on pipeline preprocessing step logic.
  // Note: only dealing with 2 special characters: - and * (others are unlikely to be in column names)
  column = column?.toString() || ''
  column = column.replaceAll('(', ' ')
  column = column.replaceAll(')', ' ')
  column = column.replace(/[*-]/g, ' ')
  column = column.replace(/([a-z])([A-Z])/g, '$1 $2')
  column = column.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
  column = column.replace(/\s{2,}/g, ' ')
  column = slugString(column, '_', toLowerCase, trim)
  return column
}

export function slugToSentenceCase(slug: string): string {
  return slug.toLowerCase().replaceAll('_', ' ')
}

export function escapeSymbols(symbols: string, str: NullString) {
  if (str) {
    return str?.includes(`\\${symbols}`) ? str : str.replaceAll(symbols, `\\${symbols}`)
  }
  else {
    return null
  }
}

export function escapeTabs(str: NullString) {
  return str && str.replaceAll(/\\t/g, '\t')
}
export function unescapeTabs(str: NullString) {
  return str && str.replaceAll(/\t/g, '\\t')
}

export function capitalizeFirstLetter(string: string) {
  if (string === undefined) { throw new Error('Argument has to be string') }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getSentences: (text: string, count: number) => string = (text: string, count: number) => {
  const allSentences: string[] = text.split('. ')
  allSentences.length = count
  return allSentences.join('. ')
}

export function returnAnOrA(string: string): string {
  const vowels = ['a', 'e', 'i', 'o', 'u']
  if (vowels.includes(string.split('')[0]?.toLowerCase())) {
    return 'an'
  }
  return 'a'
}

export function getLastWord(string: string) {
  const array = (string || '').split(' ')
  return array.pop() // return last element
}

// numerical transformations

export function returnAbbreviatedNumber(num: number, digits?: number): any {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.\d*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find((item) => {
      return num >= item.value
    })
  return item ? (num / item.value).toFixed(digits || 2).replace(rx, '$1') + item.symbol : '0'
}

export const averageOfArray = (array: number[]) => array.reduce((a, b) => a + b, 0) / array.length

export function getZeroPrefixedNumber(value: number | string, minNumbers: number = 2) {
  const valueString: string = value.toString()
  return '0'.repeat(minNumbers - Math.min(minNumbers, valueString.length)) + valueString
}

export function currencyFormat(num: number) {
  return new Intl.NumberFormat('EN', {
    style: 'currency',
    currency: 'USD',
  }).format(num)
}

export function returnBasicFormattedReportingMetric(metricValue: number | 'pending' | null) {
  if (metricValue === 'pending') {
    return 'Pending'
  }

  if (metricValue === null) {
    return 'N/A'
  }

  return metricValue <= 1 ? metricValue === 0 ? '0' : metricValue.toFixed(5) : metricValue >= 1000 ? metricValue.toLocaleString(undefined, { maximumFractionDigits: 0 }) : metricValue?.toLocaleString()
}
