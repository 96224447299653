<script setup lang="ts">
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'
import { timeOfDaySliderMarks, timeOfDayTooltipMap } from '@/level4/data/l4_mapAndLists'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'

const DEFAULT_TIME_OF_DAY: [number, number] = [5, 22]

const { experimenterFormState, updateTimeOfDayActionBank, getRecentTimeOfDays } = useExperimenterFormState()

const currentSelectedTimeOfDay = ref<[number, number]>(DEFAULT_TIME_OF_DAY)
const recentlySelectedTimeOfDay = ref<[number, number][]>([])

onMounted(async () => {
  recentlySelectedTimeOfDay.value = await getRecentTimeOfDays()
})

watch(() => experimenterFormState.value?.selectedTimeOfDay, (timeOfDay) => {
  if (timeOfDay?.length) {
    currentSelectedTimeOfDay.value = toRaw(timeOfDay!)
  }
}, { immediate: true })

function convertTupleToTimeString(tuple: [number, number]): string {
  const startHour = tuple[0]
  const endHour = tuple[1]

  const startPeriod = startHour >= 12 ? 'pm' : 'am'
  const endPeriod = endHour >= 12 ? 'pm' : 'am'

  const adjustedStartHour = startHour > 12 ? startHour - 12 : startHour
  const adjustedEndHour = endHour > 12 ? endHour - 12 : endHour

  return `${adjustedStartHour}${startPeriod} - ${adjustedEndHour}${endPeriod}`
}

defineExpose<FormExposedProperties>({
  dataIsComplete: computed(() => currentSelectedTimeOfDay?.value?.length === 2),
  submitHandler: async () => {
    // In case the user invert the slider, we swap the values
    if (currentSelectedTimeOfDay.value[0] > currentSelectedTimeOfDay.value[1]) {
      currentSelectedTimeOfDay.value = [currentSelectedTimeOfDay.value[1], currentSelectedTimeOfDay.value[0]]
    }
    await updateTimeOfDayActionBank(currentSelectedTimeOfDay.value!)
  },
})
</script>

<template>
  <WizardFormPageLayout containerSize="small" headerSize="medium" title="Next choose the times of day at which OfferFit Email can send emails." subtitle="We recommend allowing the experimenter to try as many different times as possible to see what works best for each customer.">
    <div class="icons-spacer pb-4">
      <div class="icon">
        <div class="i-solar-sun-fog-bold" />
      </div>
      <div class="icon">
        <div class="i-solar-moon-bold" />
      </div>
    </div>
    <NSlider v-model:value="currentSelectedTimeOfDay" range :min="0" :max="23" :step="1" :marks="timeOfDaySliderMarks" :formatTooltip="(value) => timeOfDayTooltipMap[value]">
      <NInputNumber id="start-time-of-day" v-model:value="currentSelectedTimeOfDay[0]" size="small" />
      <NInputNumber v-model:value="currentSelectedTimeOfDay[1]" size="small" />
    </NSlider>
    <div v-if="recentlySelectedTimeOfDay.length" class="max-w-[400px] flex items-start gap-2 py-4">
      <p class="text-gray-400 mt-0.5">
        Recently used:
      </p>
      <div class="flex flex-col gap-2">
        <NButton v-for="(recentTimeOfDay, index) in recentlySelectedTimeOfDay" :key="index" tertiary type="tertiary" size="small" @click="currentSelectedTimeOfDay = recentTimeOfDay">
          {{ convertTupleToTimeString(recentTimeOfDay) }}
        </NButton>
      </div>
    </div>
  </WizardFormPageLayout>
</template>

<style scoped>
.icons-spacer {
  @apply flex justify-between text-gray-200 w-full h-0;
}
</style>
