<script setup lang="ts">
import ChooseElements from './Form.vue'
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'
import { ChooseElementsSteps } from '@/level4/data/forms'

const stepsMap = [
  {
    name: ChooseElementsSteps.CHOOSE_ELEMENTS,
    component: ChooseElements,
  },
]
</script>

<template>
  <div>
    <L4WizardContainer :steps="stepsMap" />
  </div>
</template>
