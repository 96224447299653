<script setup lang="tsx">
import { maskUuid } from 'maskdata'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

const { experimenterFormState, setBrazeAppId } = useExperimenterFormState()

const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
const currentBrazeAppId = ref('')
const existedBrazeAppId = ref('')
syncRefs(() => toRaw(experimenterFormState.value?.brazeAppId), existedBrazeAppId, { immediate: true })

const isValidAppId = computed(() => regex.test(currentBrazeAppId.value))
const hasDataChanged = computed(() => currentBrazeAppId.value && experimenterFormState.value?.brazeAppId !== currentBrazeAppId.value)
const isFormDataFilled = computed(() => !!currentBrazeAppId.value)
const isDataComplete = computed(() => isValidAppId.value && isFormDataFilled.value)
const isStateDataNotEmpty = computed(() => !!existedBrazeAppId.value)
const hasInputBlurred = ref(false)

const computedMaskedAppId = computed(() => maskUuid(existedBrazeAppId.value, { maskWith: '*', unmaskedStartCharacters: 4, unmaskedEndCharacters: 4 }))

defineExpose<FormExposedProperties>({
  dataIsComplete: isDataComplete,
  skipIsAvailable: computed(() => isStateDataNotEmpty.value && !hasDataChanged.value),
  submitHandler: () => {
    setBrazeAppId(currentBrazeAppId.value)
  },
})
</script>

<template>
  <WizardFormPageLayout container-size="small" header-size="small" footer-size="large" supertitle="Let's connect OfferFit with Braze" title="Please provide your <span class='font-bold'>App API key</span>, also known as your app_id">
    <template #thirdSection>
      <BasicLink
        href="https://app.tango.us/app/workflow/Finding-your-Braze-App-API-key-d34a269114f448d18ee3547c139b9d3a"
        target="_blank"
        class="cursor-pointer text-link"
        withExternalIcon
      >
        How to find your Braze App API key
      </BasicLink>
    </template>
    <form flex="~ col" gap="4" items="center" w="full">
      <BasicInput v-model="currentBrazeAppId" label="App API key" :placeholder="computedMaskedAppId || 'Paste your App API key here'" data-sentry-mask @blur="hasInputBlurred = true" />
      <p v-if="currentBrazeAppId && hasInputBlurred && !isValidAppId" class="text-xs text-red-600 text-center">
        Invalid App Id.
      </p>
    </form>
  </WizardFormPageLayout>
</template>

<style scoped>
</style>
