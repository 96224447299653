import http from '@app/utils/http-client'
import qs from 'qs'
import type { UseCaseMetaDataStrapiModel, UseCaseModel } from '@/usecases/models/server/UseCaseModel.ts'

const METADATA_QUERY_PARAMS = {
  fields: ['id', 'name', 'metadata'] as (keyof UseCaseMetaDataStrapiModel)[],
}

export default class UsecaseService {
  static async updateSelectedEmails(client: string, pipeline: string, usecase: string) {
    return http.put(`v2/clients/${client}/pipelines/${pipeline}/usecases/${usecase}/update-selected-emails`)
  }

  static async getUsecase(client: string, pipeline: string, usecase: string) {
    return (await http.get<UseCaseModel>(`clients/${client}/pipelines/${pipeline}/usecases/${usecase}`)).data
  }

  static async getUsecaseMetadata(client: string, pipeline: string, usecase: string) {
    return (await http.get<UseCaseMetaDataStrapiModel>(`clients/${client}/pipelines/${pipeline}/usecases/${usecase}?${qs.stringify(METADATA_QUERY_PARAMS, { encodeValuesOnly: true })}`)).data
  }
}
