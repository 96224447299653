<script setup lang="tsx">
import type { DataTableColumns } from "naive-ui";
import { NButton, NTag } from "naive-ui";
interface SubjectLineListRow {
  id: string;
  label: string;
  tags: string[];
}

interface Props {
  data: SubjectLineListRow[];
  loading: boolean;
}

defineProps<Props>()

const emit = defineEmits<{
  add: [index: number]
  delete: [index:number]
}>()

function createColumns(): DataTableColumns<SubjectLineListRow> {
  return [
    {
      title: "Subject line",
      key: "label",
      render(row) {
        return <div>{row.label}</div>
      },
    },
    {
      title: "Tags",
      key: "tags",
      render(row) {
        const variantTags = () =>
          row.tags.map((element) => (
            <NTag round bordered={false} size="small">
              {element}
            </NTag>
          ));

        return (
          <div flex="~ wrap" gap="1">
            {variantTags()}
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      align: "right",
      render(row, index) {
        const AddButton = () => (
          <NButton
            type="primary"
            quaternary
            size="small"
            circle
            onClick={() => emit('add', index)}
          >
            {{ icon: () => <div class="i-solar-add-square-outline"></div> }}
          </NButton>
        );

        const DeleteButton = () => (
          <NButton type="primary" quaternary size="small" circle onClick={() => emit('delete', index)}>
            {{
              icon: () => <div class="i-solar-trash-bin-trash-outline"></div>,
            }}
          </NButton>
        );
      
        return (
          <div flex="~ justify-end">
            <AddButton />
            <DeleteButton />
          </div>
        );
      },
    },
  ];
}
</script>

<template>  
  <div class="subject-lines-list-wrapper">
    <NDataTable :data="data.toSorted((s1, s2) => s1.minDistance < s2.minDistance)" :columns="createColumns()" :bordered="false" :loading="loading"></NDataTable>
  </div>
</template>

<style scoped>
:deep(.n-data-table-th) {
  @apply hidden;
}
</style>
