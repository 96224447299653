<script setup lang="ts">
import { useIntegrationsSync } from '@/level4/composables/useIntegrationsSync'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

const { experimenterFormState, updateExperimenterFormState } = useExperimenterFormState()
const { createDataInVault } = useIntegrationsSync()

const regex = /pk_.*?/i

const currentKlaviyoApiKey = ref('')
const existedKlaviyoApiKey = ref('')
syncRefs(() => experimenterFormState.value?.klaviyoApiKey, existedKlaviyoApiKey, { immediate: true })

const computedMaskedApiKey = computed(() => existedKlaviyoApiKey.value ? `${existedKlaviyoApiKey.value.substring(0, 8)}****************` : '')

const isValidAPIKey = computed(() => regex.test(currentKlaviyoApiKey.value))
const isFormDataFilled = computed(() => !!currentKlaviyoApiKey.value)
const isStateDataNotEmpty = computed(() => !!experimenterFormState.value?.klaviyoApiKey)
const isDataComplete = computed(() => (isValidAPIKey.value && isFormDataFilled.value) || isStateDataNotEmpty.value)

defineExpose<FormExposedProperties>({
  dataIsComplete: isDataComplete,
  submitHandler: async () => {
    if (!isFormDataFilled.value && !isStateDataNotEmpty.value) {
      throw new Error('API key is required')
    }

    if (isFormDataFilled.value) {
      await updateExperimenterFormState({ klaviyoApiKey: currentKlaviyoApiKey.value })
    }
    await createDataInVault()
  },
})
</script>

<template>
  <WizardFormPageLayout containerSize="small" footerSize="full" supertitle="Let’s connect OfferFit with Klaviyo"
    title="Please create a Klaviyo <span class='font-bold'>API key.</span>">
    <template #thirdSection>
      <BasicLink
        href="https://app.tango.us/app/workflow/Setting-Up-Klaviyo-API-Keys-for-OfferFit-Email-8afd7f9e13dd40e498b944c44bf5243e"
        target="_blank" class="cursor-pointer text-link" withExternalIcon>
        How to create an API key and permissions required?
      </BasicLink>
    </template>
    <form flex="~ col" gap="4" items="center" w="full">
      <BasicInput
        v-model="currentKlaviyoApiKey" label="Private API key" :placeholder="computedMaskedApiKey || 'Paste client secret'" data-sentry-mask
      />
      <p v-if="!isValidAPIKey && currentKlaviyoApiKey" class="text-center text-xs text-red-600">
        Invalid API key.
      </p>
    </form>
  </WizardFormPageLayout>
</template>

<style scoped></style>
