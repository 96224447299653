function getEnvironment() {
  if (import.meta.env.VITE_VERCEL_ENV) { 
    return 'staging'
  }
  return import.meta.env.MODE === 'development'
    ? 'development'
    : import.meta.env.VITE_APP_ENVIRONMENT
}

const faviconMap = {
  development: '/icons/favicons/favicon.dev.svg',
  staging: '/icons/favicons/favicon.staging.svg',
  production: '/icons/favicons/favicon.production.svg',
}

export default function useOfferfitEnvironment() {
  const favicon = useFavicon()
  const environment = getEnvironment()
  favicon.value = faviconMap[environment] || faviconMap.development
  const showTopbar = environment !== 'production'
  const portalVersion = PORTAL_VERSION
  return {
    environment,
    favicon,
    showTopbar,
    portalVersion,
  }
}
