<script setup lang="ts">
import { storeToRefs } from 'pinia'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { useExperimenterVariantsFormsStore } from '@/level4/stores/useExperimenterVariantsFormsStore'

const experimenterVariantsFormStore = useExperimenterVariantsFormsStore()
const {
  varySubjectLine,
  selectedCTAHtmlElementIdentifiers,
  subjectLine,
  currentTemplate,
  templateHtml,
} = storeToRefs(experimenterVariantsFormStore)

const isLoadingLLMData = ref(false)
const currentVarySubjectLine = ref(false)
const currentSelectedCTAHtmlElementIdentifiers = reactive<{ cta: string | undefined, image: string | undefined }>({ cta: undefined, image: undefined })

const abortController = new AbortController()

syncRefs(varySubjectLine, currentVarySubjectLine, { immediate: true })
watch(selectedCTAHtmlElementIdentifiers, (value) => {
  currentSelectedCTAHtmlElementIdentifiers.cta = value?.cta
  currentSelectedCTAHtmlElementIdentifiers.image = value?.image
}, { immediate: true, deep: true })

const chooseElementsMiniMap = computed(() => {
  return [{
    label: 'Subject line',
    key: 'subject-line',
    vary: currentVarySubjectLine.value,
  }, {
    label: 'Call to action',
    key: 'cta',
    vary: !!currentSelectedCTAHtmlElementIdentifiers.cta,
  }, {
    label: 'Hero image',
    key: 'hero-image',
    vary: !!currentSelectedCTAHtmlElementIdentifiers.image,
  }]
})

defineExpose<FormExposedProperties>({
  dataIsComplete: computed(() => !isLoadingLLMData.value && (!!currentSelectedCTAHtmlElementIdentifiers.cta || !!currentSelectedCTAHtmlElementIdentifiers.image || currentVarySubjectLine.value)),
  submitHandler: async () => {
    abortController.abort()
    await experimenterVariantsFormStore.updateChosenElements(currentSelectedCTAHtmlElementIdentifiers, currentVarySubjectLine.value)
  },
  backHandler: () => {
    abortController.abort()
  },
})
</script>

<template>
  <WizardFormPageLayout
    containerSize="full"
    title="Click on the elements of this email that you’d like to vary."
    subtitle="You can choose to vary subject line, hero image, and/or CTA."
  >
    <div class="w-full flex items-start justify-center gap-10">
      <EmailTemplatePreviewPlayground
        v-model:vary-subject-line="currentVarySubjectLine"
        v-model:selected-cta-html-identifiers="currentSelectedCTAHtmlElementIdentifiers"
        :html="templateHtml"
        :subjectLine="subjectLine"
        :loading="!currentTemplate"
        w="full"
        max="w-4xl"
      />

      <div
        class="choose-elements-mini-map sticky top-0 min-w-[10rem] flex flex-col cursor-default gap-2 pt-20"
      >
        <h1 class="font-semibold">
          Generate variants for
        </h1>
        <div
          v-for="miniMapItem in chooseElementsMiniMap"
          :key="miniMapItem.key"
          class="map-item flex cursor-default items-center gap-1 rounded p-1"
          :class="miniMapItem.vary ? 'text-brand-purple-bright font-semibold bg-brand-purple-bright/10' : 'text-gray-400'"
        >
          <div
            :class="miniMapItem.vary ? 'i-solar-check-circle-outline' : 'i-solar-forbidden-circle-outline'"
          />
          {{ miniMapItem.label }}
        </div>
      </div>
    </div>
  </WizardFormPageLayout>
</template>
