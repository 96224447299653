<script setup lang="ts">
import ChoosePlatform from './connection/Form.vue'
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'
import { IntegrateSteps } from '@/level4/data/forms'

const steps = [
    {
        name: IntegrateSteps.PLATFORM_CONNECTION,
        component: ChoosePlatform,
    },
]

</script>
<template>
<div>
    <L4WizardContainer :steps="steps" />
  </div>
</template>
