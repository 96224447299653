<script setup lang="ts">
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'

const { experimenterFormState, setBrazeDashboardUrl } = useExperimenterFormState()

const currentBrazeDashboardUrl = ref('')
syncRefs(() => toRaw(experimenterFormState.value?.brazeDashboardUrl), currentBrazeDashboardUrl, { immediate: true })

const urlPattern = /^(https?:\/\/)?[a-zA-Z0-9-]+\.braze\.com(\/.*)?$/
const isValidUrl = computed(() => urlPattern.test(currentBrazeDashboardUrl.value))
function prependHttpsAndRemoveExtra(url: string) {
  if (!url.startsWith('https://')) {
    url = `https://${url}`
  }

  const index = url.indexOf('.com')
  if (index !== -1) {
    url = url.slice(0, index + 4)
  }

  return url
}

const hasDataChanged = computed(() => experimenterFormState.value?.brazeDashboardUrl !== currentBrazeDashboardUrl.value)
const isStateDataNotEmpty = computed(() => !!experimenterFormState.value?.brazeDashboardUrl)
const isFormDataFilled = computed(() => !!currentBrazeDashboardUrl.value)

defineExpose<FormExposedProperties>({
  dataIsComplete: computed(() => isFormDataFilled.value && isValidUrl.value),
  skipIsAvailable: computed(() => isStateDataNotEmpty.value && !hasDataChanged.value),
  submitHandler: () => {
    setBrazeDashboardUrl(prependHttpsAndRemoveExtra(currentBrazeDashboardUrl.value))
  },
})
</script>

<template>
  <WizardFormPageLayout
    containerSize="small"
    headerSize="small"
    footerSize="large"
    supertitle="Let's connect OfferFit with Braze"
    title="Please copy and paste your Braze URL so I can find your account."
  >
    <template #thirdSection>
      <BasicLink
        href="https://app.tango.us/app/workflow/Finding-your-Braze-URL-c7af59ca978543708ebde016f25d6695"
        target="_blank"
        class="cursor-pointer text-link"
        withExternalIcon
      >
        How to find your Braze URL
      </BasicLink>
    </template>
    <form
      flex="~ col"
      gap="4"
      items="center"
      w="full"
    >
      <BasicInput
        v-model="currentBrazeDashboardUrl"
        label="Braze URL"
        placeholder="https://dashboard-01.braze.com"
      />
      <p v-if="!isValidUrl && currentBrazeDashboardUrl" class="text-center text-xs text-red-600">
        The URL must contain "braze.com", and will likely be similar to "https://dashboard-01.braze.com". Please check the above instructions for help.
      </p>
    </form>
  </WizardFormPageLayout>
</template>

<style scoped>
</style>
