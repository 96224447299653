<script setup lang="ts">
import SfmcDataQueries from './sfmc-data-queries/Form.vue'
import SfmcFinishJourney from './sfmc-finish-journey/Form.vue'
import SfmcValidateResults from './sfmc-validate-results/Form.vue'
import { LaunchSFMCSteps } from '@/level4/data/forms'
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'

const formSteps = [
  {
    name: LaunchSFMCSteps.SFMC_DATA_QUERIES,
    component: SfmcDataQueries,
  },
  {
    name: LaunchSFMCSteps.SFMC_FINISH_JOURNEY,
    component: SfmcFinishJourney,
  },
  {
    name: LaunchSFMCSteps.SFMC_VALIDATE_RESULTS,
    component: SfmcValidateResults,
  },
]
</script>

<template>
  <div>
    <L4WizardContainer :steps="formSteps" />
  </div>
</template>
