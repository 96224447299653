import { md5 } from 'hash-wasm'
import type {
  GeneratedVariantsOption,

} from '@/level4/stores/useExperimenterVariantsFormsStore'

export interface VariantsCartesianProduct {
  id?: string
  subjectLine?: GeneratedVariantsOption
  cta?: GeneratedVariantsOption
  image?: string
}

export type VariantsCartesianProductOutput = VariantsCartesianProduct[]
export async function campaignVariantsCartesianProduct(
  clientName: string,
  usecaseName: string,
  templateId: string,
  subjectLines: GeneratedVariantsOption[],
  ctas: GeneratedVariantsOption[],
  images: string[],
): Promise<VariantsCartesianProductOutput> {
  const products = createCartesianProduct(subjectLines, ctas, images).map((variants) => { return { clientName, usecaseName, templateId, variants } })
  return await Promise.all(products.map(makeCombinationWithId))
}

function createCartesianProduct(subjectLines: GeneratedVariantsOption[], ctas: GeneratedVariantsOption[], images: string[]) {
  const result = []

  for (const sl of subjectLines.length ? subjectLines : [undefined]) {
    for (const cta of ctas.length ? ctas : [undefined]) {
      for (const img of images.length ? images : [undefined]) {
        result.push({ subjectLine: sl, cta, image: img })
      }
    }
  }
  return result
}

async function makeCombinationWithId(
  { clientName, usecaseName, templateId, variants }: {
    clientName: string,
    usecaseName: string,
    templateId: string,
    variants: VariantsCartesianProduct
  }
) {
  const { subjectLine, cta, image } = variants

  const combination: VariantsCartesianProduct = { }
  if (subjectLine) { combination.subjectLine = subjectLine }
  if (cta) { combination.cta = cta }
  if (image) { combination.image = image }

  const variantIdCombination = [
    clientName,
    usecaseName,
    templateId,
    subjectLine?.label ?? subjectLine ?? '',
    cta?.label ?? cta ?? '',
    image ?? ''
  ].join('_')
  const variantId = await md5(variantIdCombination)

  combination.id = variantId

  return combination
}
