<script setup lang="ts">
import debounce from 'lodash/debounce'

import SimpleTextarea from '@shared/components/basic/SimpleTextarea.vue'

import { REQUEST_DEBOUNCE_DELAY } from '@shared/data/constants'
import { useStore } from '@app/store'
import type { NullString } from '@shared/utils/Types'
import { trackEvent } from '@shared/utils/analytics'
import { xssPlugin } from '@shared/utils/xss'

interface Props {
  id: string
  type?: string
  placeholder?: NullString
  areaWidth?: number
  emptyPlaceholder?: NullString
  modelValue: NullString | number
  error?: NullString | Ref
  suffix?: NullString
  autocomplete?: boolean
  showInput?: boolean
  numeric?: boolean
  underlined?: boolean
  smallText?: boolean
  errorIsAbsolutPositioned?: boolean
  ghost?: boolean
  tokenizerRegex?: RegExp
  tokenizerReplacer?: string
}

const props = defineProps<Props>()

const emits = defineEmits<{
  'update:modelValue': [value: NullString]
  'blur': [value: NullString]
}>()

const store = useStore()
const isEdit = ref(false)
const inputRef = ref<any>(null)
const textRef = ref<any>(null)

const localValue: Ref = ref<NullString>(props.modelValue?.toString())

const errorText: ComputedRef<NullString> = computed<NullString>(() => {
  return isRef(props.error) ? (props.error as Ref)?.value : props.error
})

const valueTitle: ComputedRef<NullString> = computed<NullString>(() => {
  let titleString: NullString = `${localValue.value}${props.suffix ? props.suffix : ''}`

  if (errorText.value) {
    titleString = (localValue.value ? `${titleString} - ${errorText.value}` : errorText.value) as string
  }

  return titleString
})

const isEditable: ComputedRef<boolean> = computed<boolean>(() => {
  return !!isEdit.value || !!props.showInput
})

const textDivHeight: Ref = ref<number>(0)

const areaLines: ComputedRef<number> = computed<number>(() => {
  return Math.floor(textDivHeight.value / 22) + 1
})

let ignoreLocalValueChange: boolean = false

let unWatchModelValue: WatchStopHandle
let unWatchLocalValue: WatchStopHandle

const debouncedFieldChange = debounce(() => {
  if (props.id) {
    trackEvent(props.id, { type: 'table-textarea', action: localValue.value !== '' ? 'change' : 'clear' })
  }
}, REQUEST_DEBOUNCE_DELAY)

onMounted(() => {
  unWatchModelValue = watch(
    () => [props.modelValue],
    () => {
      ignoreLocalValueChange = true

      localValue.value = props.modelValue?.toString()

      nextTick(() => {
        ignoreLocalValueChange = false
      })
    },
  )

  unWatchLocalValue = watch(localValue, () => {
    if (ignoreLocalValueChange) { return }

    emits('update:modelValue', localValue.value?.toString())
    debouncedFieldChange()
  })

  textDivHeight.value = textRef.value.getBoundingClientRect().height
})

let highlightedValue: ComputedRef<string>

if (props.tokenizerRegex) {
  highlightedValue = computed(() => {
    let html = localValue.value
    if (!html) { return '' }
    html = html.replace(props.tokenizerRegex, (match, captureGroup) => `<span class="highlight">${props.tokenizerReplacer ?? captureGroup}</span>`)
    return html
  })
}

onBeforeUnmount(() => {
  unWatchModelValue()
  unWatchLocalValue()
})

function clickHandler() {
  isEdit.value = true
  nextTick(() => {
    inputRef.value?.focus?.()
  })
}

watch(() => props.showInput, () => {
  nextTick(() => {
    inputRef.value?.focus?.()
  })
})

function blurAndEmitData() {
  emits('blur', localValue.value)
}

function inputBlurCapturedHandler() {
  blurAndEmitData()
}
function enterKeyDownCaptureHandler() {
  blurAndEmitData()
}

function blurInput() {
  if (!errorText.value) {
    isEdit.value = false
  }
}

function inputBlurHandler() {
  blurInput()
  textDivHeight.value = textRef.value?.getBoundingClientRect()?.height
}
function inputTextareaHandler() {
  textDivHeight.value = textRef.value?.getBoundingClientRect()?.height
}
function enterKeyDownHandler() {
  blurInput()
}

const globalReadOnlyMode = computed(() => {
  return store.getters.isReadonlyMode
})
</script>

<template>
  <div>
    <div @keydown.enter="enterKeyDownHandler" @keydown.enter.capture="enterKeyDownCaptureHandler">
      <div
        v-if="!isEditable || globalReadOnlyMode"
        :title="valueTitle"
        class="h-full w-full border"
        :style="{ width: `${areaWidth}px` }"
        :class="{
          'cursor-pointer hover:ring-2 hover:ring-gray-300 hover:shadow-inner hover:bg-white rounded-md editable-area': !globalReadOnlyMode,
          'underline': underlined,
          'border-dashed': !ghost,
          'border-transparent': ghost,
          'text-offerfit-red border-offerfit-red': !!errorText,
          'text-gray-400 border-gray-300': localValue === '' && !errorText,
        }"
        @click="clickHandler"
      >
        <span v-if="tokenizerRegex" class="highlighted-prose" v-html="xssPlugin.process(highlightedValue)" />
        <template v-else>
          {{ localValue || emptyPlaceholder }}{{ suffix ? suffix : '' }}
        </template>
      </div>
      <SimpleTextarea
        v-else
        :id="id"
        ref="inputRef"
        v-model="localValue"
        :rows="areaLines"
        class="simple-input"
        :style="{ width: `${areaWidth}px` }"
        :placeholder="placeholder"
        :error="errorText"
        :errorIsAbsolutPositioned="errorIsAbsolutPositioned"
        autocomplete
        autoselect
        compact
        :smallText="smallText"
        @update:modelValue="inputTextareaHandler"
        @blur.capture="inputBlurCapturedHandler"
        @blur="inputBlurHandler"
      />
    </div>
    <div
      ref="textRef"
      class="duplicate-text absolute"
      style="left: -10000px; padding-left: 28px; padding-right: 23px"
      :style="{ width: `${areaWidth}px` }"
    >
      {{ localValue || emptyPlaceholder }}{{ suffix ? suffix : '' }}
    </div>
  </div>
</template>

<style scoped>
:deep(.simple-input) textarea {
  @apply py-1;
}
.editable-area {
  padding-top: 3px;
  padding-left: 5px;
  min-height: 1.5rem;
  word-break: normal;
}

.duplicate-text {
  word-break: break-all;
}

.highlighted-prose {
  :deep(.highlight) {
    @apply bg-brand-purple-light/20 text-brand-purple-bright font-bold px-2 rounded-md;
  }
}
</style>
