<script setup lang="ts">
import { h } from 'vue'
import { TestEmailsSteps } from '@/level4/data/forms'

const props = defineProps<{
  stepName: string
}>()

interface TokensMiniMapStep {
  label?: string
  icon: () => VNode
  completed: boolean
  tooltip?: string
  step: TestEmailsSteps
}

const testEmailsMinimap: TokensMiniMapStep[] = [
  {
    label: 'Select Variants',
    icon: () => h('div', { class: 'i-solar-calendar-outline' }),
    completed: false,
    tooltip: `Select variants`,
    step: TestEmailsSteps.VARIANTS,
  },
  {
    label: 'Select Emails',
    icon: () => h('div', { class: 'i-solar-letter-outline' }),
    completed: false,
    tooltip: `Select emails`,
    step: TestEmailsSteps.EMAILS,
  },
  {
    label: 'Summary',
    icon: () => h('div', { class: 'i-solar-checklist-outline' }),
    completed: false,
    tooltip: `Summary`,
    step: TestEmailsSteps.SUMMARY,
  },
]
</script>

<template>
  <div v-for="(formStep, formStepIndex) in testEmailsMinimap" :key="formStep.step" class="step-group flex items-center">
    <MinimapChip
      :label="formStep.label"
      :index="formStepIndex"
      :icon="formStep.icon()"
      :isCompleted="formStep.completed"
      :isActive="formStep.step === props.stepName"
    />
    <div v-if="formStepIndex < testEmailsMinimap.length - 1" class="i-solar-alt-arrow-right-outline text-sm text-gray-400" />
  </div>
</template>
