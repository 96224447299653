<script setup lang="ts">
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'
import TimeOfDay from './time-of-day/Form.vue'
import Timezone from './timezone/Form.vue'
import { TimeSteps } from '@/level4/data/forms'
const formSteps = [
    {
        name: TimeSteps.TIMEZONE,
        component: Timezone,
    },
    {
        name: TimeSteps.TIME_OF_DAY,
        component: TimeOfDay,
    },
]
</script>

<template>
  <div>
    <L4WizardContainer :steps="formSteps" />
  </div>
</template>
