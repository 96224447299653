<script setup lang="ts">
import { ShadowRoot } from 'vue-shadow-dom'
import type { ShadowRootExpose } from 'vue-shadow-dom'

interface Props {
  html: string
  loading?: boolean
}

withDefaults(defineProps<Props>(), {  })

const emit = defineEmits<{
  'element-hover': [element: Element | null]
  'element-click': [element: Element]
  'document-loaded': [document: Document]
}>()

const shadowRoot = ref<ShadowRootExpose>()
const shadowRootWrapper = ref<HTMLDivElement>()
const loadedElementsSet = ref<Set<Element>>(new Set())

function updateContent() {
  if (!shadowRoot.value) { return }
  addEventListenerToAllElements(shadowRoot.value.shadow_root)

  // Emit the document-loaded event after updating the content
  emit('document-loaded', shadowRoot.value.shadow_root)
}

const debouncedUpdateContent = useDebounceFn(updateContent, 3000, { maxWait: 1000})

onMounted(() => {
  nextTick(debouncedUpdateContent)
})

function addEventListenerToAllElements(element: Node | ShadowRootExpose['shadow_root']) {
  if (!element) { return }

  // Add event listeners to all elements
  const isAnElement = element instanceof Element
  if (isAnElement) {
    element.addEventListener('mouseover', handleOverElement)
    element.addEventListener('click', handleClickElement)
    loadedElementsSet.value.add(element)
  }

  // Recursively add event listeners to all children
  element.childNodes.forEach((child: Node) => {
    addEventListenerToAllElements(child)
  })
}

function handleOverElement(event: Event) {
  const targetElement = event.target as Element
  if (targetElement) {
    emit('element-hover', targetElement)
  }
}

function handleClickElement(event: Event) {
  const targetElement = event.target as Element
  if (targetElement) {
    emit('element-click', targetElement)
  }
}

onDeactivated(() => {
  loadedElementsSet.value.forEach((element) => {
    element.removeEventListener('mouseover', handleOverElement)
    element.removeEventListener('click', handleClickElement)
  })
  loadedElementsSet.value.clear()
})

watch(
  () => shadowRoot.value?.shadow_root,
  debouncedUpdateContent,
  { deep: true }
)
const wrapperPosition = useElementBounding(shadowRootWrapper)
watch(() => wrapperPosition, debouncedUpdateContent)

</script>

<template>
  <div ref="shadowRootWrapper" class="shadow-dom-email-template-preview" position="relative" @mouseleave="() => emit('element-hover', null)">
    <ShadowRoot
      ref="shadowRoot"
      z="10"
      cursor="pointer"
      select="none"
      @click.stop.prevent
    >
      <div v-html="html"></div>
    </ShadowRoot>
  </div>
</template>
