<script setup lang="ts">
import type { InputNumberProps } from 'naive-ui'
import BlueInfoMessage from '@shared/components/basic/static/BlueInfoMessage.vue'
import { controlPercentSliderMarks } from '@/level4/data/l4_mapAndLists'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

type InputNumberDisabledThemeOverride = NonNullable<InputNumberProps['themeOverrides']>

const isInfoOpen = ref<boolean>(true)
const currentSelectedControlPercent = ref<number>(10)
const currentSelectedOfferfitPercent = ref<number>(90)

const { experimenterFormState, updateSelectedAudienceRegimePercent } = useExperimenterFormState()

const inputNumberDisabledThemeOverride: InputNumberDisabledThemeOverride = {
  peers: {
    Input: {
      textColorDisabled: '#A8A7A7',
    },
  },
}

onMounted(() => {
  if (experimenterFormState.value?.selectedControlGroupPercent > 0) {
    currentSelectedControlPercent.value = experimenterFormState.value.selectedControlGroupPercent
  }
  if (experimenterFormState.value.selectedOfferfitGroupPercent > 0) {
    currentSelectedOfferfitPercent.value = experimenterFormState.value.selectedOfferfitGroupPercent
  }
})
function selectedControlPercentUpdateHandler(value: number) {
  if (value <= 50 && value >= 5) {
    currentSelectedControlPercent.value = value
    currentSelectedOfferfitPercent.value = 100 - currentSelectedControlPercent.value
  }
}

defineExpose<FormExposedProperties>({
  submitHandler: async () => {
    await updateSelectedAudienceRegimePercent(currentSelectedOfferfitPercent.value, currentSelectedControlPercent.value)
  },
})
</script>

<template>
  <WizardFormPageLayout
    containerSize="full" headerSize="medium"
    title="I will divide the audience into Control and OfferFit groups."
    subtitle="What percent of the audience do you want to use as a Control group? Drag the slider or add manually."
  >
    <div class="flex justify-center">
      <div class="w-50%">
        <BlueInfoMessage
          v-if="isInfoOpen" class="mb-10" closable title="Audience groups"
          subtitle="The OfferFit Experimenter will choose options for the OfferFit group. The Control group will let you measure OfferFit's effectiveness — it will receive a comparable volume of outreach per customer, but outreach won't be personalized. I recommend 10% Control group."
          @dismiss="isInfoOpen = false"
        />
        <NSlider
          v-model:value="currentSelectedControlPercent" :min="0" :max="100" :step="5"
          :marks="controlPercentSliderMarks" :formatTooltip="(value: number) => `${value}%`"
          :on-update:value="selectedControlPercentUpdateHandler"
        />

        <div class="pb-6">
          <YellowWarning v-if="currentSelectedControlPercent === 50" subtitle="The Control group does not receive personalized recommendations, so there is a maximum of 50% allocation to the Control group." />
          <YellowWarning v-else-if="currentSelectedControlPercent >= 20" subtitle="Larger control groups like what you selected slow down learning and reduce the leverage for optimization." />
        </div>

        <div class="flex justify-between">
          <div>
            <span class="mb-1 flex text-sm font-medium"><span
              class="i-solar-tuning-bold mr-2 text-offerfit-bright-purple"
            /> Control group</span>
            <NInputNumber
              v-model:value="currentSelectedControlPercent" :step="10"
              :on-update:value="selectedControlPercentUpdateHandler"
            >
              <template #suffix>
                %
              </template>
            </NInputNumber>
          </div>
          <div>
            <span class="mb-1 flex text-sm font-medium"><span
              class="i-custom:of-icon mr-2 text-offerfit-bright-purple"
            />
              OfferFit</span>
            <NInputNumber
              v-model:value="currentSelectedOfferfitPercent"
              :themeOverrides="inputNumberDisabledThemeOverride" disabled
            >
              <template #suffix>
                %
              </template>
            </NInputNumber>
          </div>
        </div>
      </div>
    </div>
  </WizardFormPageLayout>
</template>
