import type { NullString } from '@shared/utils/Types'

export type MatchingOperator = 'and' | 'or'

export type CompareOperator =
  | 'eq'
  | 'gt'
  | 'lt'
  | 'geq'
  | 'leq'
  | 'neq'
  | 'isnull'
  | 'notnull'
  | 'isin'
  | 'notin'
  | 'endswith'
  | 'startswith'
  | 'contains'
  | 'notcontains'

export enum FilterInputType {
  VALUE = 'value',
  VALUE_LIST = 'value_list',
  DATETIME = 'datetime',
  DATE = 'date',
}

export type ComparatorName = string

export interface CommonFilter {
  id?: string
  colname: NullString
  compare_op: undefined | CompareOperator
  compare_val: string | number | undefined | (string | number | undefined)[]
}

export type FilterCondition = Partial<{
  and?: (CommonFilter | FilterCondition)[]
  or?: (CommonFilter | FilterCondition)[]
}>

export type RootFilterExpression = [FilterCondition]

export interface LogicConfigModel {
  id: string
  expression: RootFilterExpression
  negate_expression: boolean
  display_name: string
  description: string
  callback?: string
}

export interface ComparatorLogic {
  comparators?: CommonFilter[]
  logic?: LogicConfigModel[]
}

export interface CommonFeatureFields {
  days_back: Array<number>
  description: NullString
  filter?: RootFilterExpression | ComparatorLogic | []
  override_date_col?: string
}

export interface UpdateExpressionPayload {
  target: (CommonFilter | FilterCondition)[]
  payload: CommonFilter | FilterCondition
  oldValue?: CommonFilter | FilterCondition
}

export type FilterConfigMatchingExpression = Partial<{
  and?: (ComparatorName | FilterConfigMatchingExpression)[]
  or?: (ComparatorName | FilterConfigMatchingExpression)[]
}>

export type RootFilterConfigExpression = [FilterConfigMatchingExpression | ComparatorName]

export interface FilterConfigModel<T = string, R = RootFilterConfigExpression> {
  id: T
  expression: R
  negate_expression: boolean
  display_name?: string
  description?: NullString
  callback?: string
}
