<script setup lang="ts">
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState.ts'
import NameExternalKeyPairTable
  from '@/level4/views/modules/launch-sfmc/sfmc-data-queries/NameExternalKeyPairTable.vue'
import { useSFMC } from '@/level4/composables/useSFMC'

const { getOFEBaseDataExtensionQuery, getOFEEngagementDataQuery } = useSFMC()
const { baseAudienceExtensionNameToOverrwrite, engagementExtensionNameToOverrwrite } = useExperimenterFormState()
</script>

<template>
  <BlueInfoMessage
    subtitle="Some of the setup steps will require the information below, which is specific to your Experimenter. I'll show you the relevant information at each step, or you can reference it in this tab at any time."
  />

  <!--  Subscribers Query -->
  <div class="text-lg font-semibold">
    Subscribers Query resources:
  </div>
  <div class="mb-4 ml-4 mt-1 font-medium">
    Name and External key:<br>
  </div>
  <NameExternalKeyPairTable :data="[{ name: 'OFE_Subscribers_query', externalKey: 'OFE_Subscribers_query' }]" />
  <div class="mb-4 ml-4 mt-1 font-medium">
    System data SQL query:
  </div>
  <CodeBlock :content="getOFEBaseDataExtensionQuery()" language="sql" />
  <div class="mb-4 ml-4 mt-1 font-medium">
    Target Data Extension:
  </div>
  <NameExternalKeyPairTable :data="[{ name: baseAudienceExtensionNameToOverrwrite }]" />

  <!--  Engagement Query -->
  <div class="text-lg font-semibold">
    Engagement Query resources:
  </div>
  <div class="mb-4 ml-4 mt-1 font-medium">
    Name and External key:<br>
  </div>
  <NameExternalKeyPairTable :data="[{ name: 'OFE_Engagement_query', externalKey: 'OFE_Engagement_query' }]" />
  <div class="mb-4 ml-4 mt-1 font-medium">
    System data SQL query:
  </div>
  <CodeBlock :content="getOFEEngagementDataQuery()" language="sql" />
  <div class="mb-4 ml-4 mt-1 font-medium">
    Target Data Extension:
  </div>
  <NameExternalKeyPairTable :data="[{ name: engagementExtensionNameToOverrwrite }]" />
</template>

<style scoped>

</style>
