<script setup lang="ts">
import Emails from './emails/Form.vue'
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'
import { TemplatesSteps } from '@/level4/data/forms'

const formSteps = [
  {
    name: TemplatesSteps.EMAILS,
    component: Emails,
  },
]
</script>

<template>
  <div>
    <L4WizardContainer :steps="formSteps" />
  </div>
</template>
