<script setup lang="tsx">
import { maskUuid } from 'maskdata'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

const { experimenterFormState, setBrazeApiKey } = useExperimenterFormState()

const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

const currentBrazeApiKey = ref('')
const existedBrazeApiKey = ref('')
syncRefs(() => experimenterFormState.value?.brazeApiKey, existedBrazeApiKey, { immediate: true })

const computedMaskedApiKey = computed(() => maskUuid(existedBrazeApiKey.value, { maskWith: '*', unmaskedStartCharacters: 4, unmaskedEndCharacters: 4 }))

const isValidAPIKey = computed(() => regex.test(currentBrazeApiKey.value))
const hasDataChanged = computed(() => currentBrazeApiKey.value && existedBrazeApiKey.value !== currentBrazeApiKey.value)
const isFormDataFilled = computed(() => !!currentBrazeApiKey.value)
const isDataComplete = computed(() => isValidAPIKey.value && isFormDataFilled.value)
const isStateDataNotEmpty = computed(() => !!existedBrazeApiKey.value)
const hasInputBlurred = ref(false)

defineExpose<FormExposedProperties>({
  dataIsComplete: isDataComplete,
  skipIsAvailable: computed(() => isStateDataNotEmpty.value && !hasDataChanged.value),
  submitHandler: async () => {
    await setBrazeApiKey(currentBrazeApiKey.value)
  },
})
</script>

<template>
  <WizardFormPageLayout containerSize="small" headerSize="small" footerSize="large" supertitle="Let's connect OfferFit with Braze" title="Please create a Braze <span class='font-bold'>API key.</span>">
    <template #thirdSection>
      <BasicLink
        href="https://app.tango.us/app/workflow/Creating-a-Braze-API-key-6f63701f53cf4688b263f7aea2b0ce7a"
        target="_blank"
        class="cursor-pointer text-link"
        withExternalIcon
      >
        How to create an API key and what permissions are required
      </BasicLink>
    </template>
    <form flex="~ col" gap="4" items="center" w="full">
      <BasicInput v-model="currentBrazeApiKey" label="API key" :placeholder="computedMaskedApiKey || 'paste your API key here'" data-sentry-mask @blur="hasInputBlurred = true" />
      <p v-if="currentBrazeApiKey && hasInputBlurred && !isValidAPIKey" class="text-center text-xs text-red-600">
        Invalid API key.
      </p>
    </form>
  </WizardFormPageLayout>
</template>

<style scoped>
</style>
