import type { SelectItem } from '@shared/utils/Types'
import { ActionFieldType, ActionStatus, DataType } from '@shared/data/constants'
import { RECIPIENT_TIMEZONE, STANDARD_TIMEZONE } from '@/level4/data/l4_constants'
import type { ActionFieldModelType } from '@/usecases/models/server/ActionModel'

export const timeZoneTypeRadioOptions: SelectItem[] = [
  {
    value: RECIPIENT_TIMEZONE,
    label: 'Use each recipient’s time zone.',
  },
  {
    value: STANDARD_TIMEZONE,
    label: 'Always use:',
  },
]

export const timeZoneOptions: SelectItem[] = [
  {
    value: 'UTC-8',
    label: 'USA Pacific Standard Time (UTC-08:00)',
  },
  {
    value: 'UTC-7',
    label: 'USA Mountain Standard Time (UTC-07:00)',
  },
  {
    value: 'UTC-6',
    label: 'USA Central Standard Time (UTC-06:00)',
  },
  {
    value: 'UTC-5',
    label: 'USA Eastern Standard Time (UTC-05:00)',
  },
]

export const timeOfDaySliderMarks = { 0: '12am', 1: '', 2: '', 3: '', 4: '4am', 5: '', 6: '', 7: '7am', 8: '', 9: '', 10: '', 11: '', 12: '12pm', 13: '', 14: '', 15: '', 16: '4pm', 17: '', 18: '', 19: '7pm', 20: '', 21: '', 22: '', 23: '11pm' }

export const timeOfDayTooltipMap: Record<number, string> = { 0: '12am', 1: '1am', 2: '2am', 3: '3am', 4: '4am', 5: '5am', 6: '6am', 7: '7am', 8: '8am', 9: '9am', 10: '10am', 11: '11am', 12: '12pm', 13: '1pm', 14: '2pm', 15: '3pm', 16: '4pm', 17: '5pm', 18: '6pm', 19: '7pm', 20: '8pm', 21: '9pm', 22: '10pm', 23: '11pm' }

export const controlPercentSliderMarks = { 20: '', 40: '', 60: '', 80: '' }

export const daysOfWeekShortMap = {
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
  Sunday: 'Sun',
}

export enum TAGS {
  ACCOMPLISHMENT = 'accomplishment',
  AUTHORITY = 'authority',
  CHALLENGE = 'challenge',
  FORMALITY = 'formality',
  CURIOSITY = 'curiosity',
  EXCITEMENT = 'excitement',
  FOMO = 'fomo',
  FORTUNE = 'fortune',
  INSPIRATION = 'inspiration',
  LOYALTY = 'loyalty',
  LUXURY = 'luxury',
  SCARCITY = 'scarcity',
  SECURITY = 'security',
  SOCIAL_PROOF = 'social_proof',
  URGENCY = 'urgency',
  HUMOR = 'humor',
  WARMTH = 'warmth',
  EMOJI = 'emoji',
  FULL_NAME = 'full_name',
  FIRST_NAME = 'first_name',
}

export enum dimensionNames {
  'email' = 'Email variants',
  'frequency' = 'Sending frequencies',
  'daysofweek' = 'Days of week',
  'time' = 'Times of day',
}

export const TIME_OF_DAY_ACTION_BANK_FIELDS: ActionFieldModelType[] = [
  {
    column: 'action_id',
    category: ActionFieldType.PARAMETER,
    description: 'Unique action id',
    default_value: null,
    data_type: DataType.INTEGER,
    encoding: null,
    encoding_order: null,
    sort: 2,
  },
  {
    column: 'hour_of_day',
    category: ActionFieldType.FEATURE,
    description: 'Time that the offers will be sent in local timezone as integer',
    default_value: null,
    data_type: DataType.INTEGER,
    encoding: null,
    encoding_order: null,
    sort: 3,
  },
  {
    column: 'time_of_day',
    category: ActionFieldType.PARAMETER,
    description: 'Time that the offers will be sent in local timezone as string',
    default_value: null,
    data_type: DataType.STRING,
    encoding: null,
    encoding_order: null,
    sort: 4,
  },
  {
    column: 'description',
    category: ActionFieldType.PARAMETER,
    description: 'Verbal description of the time action',
    default_value: null,
    data_type: DataType.STRING,
    encoding: null,
    encoding_order: null,
    sort: 5,
  },
  {
    column: 'action_status',
    category: ActionFieldType.PARAMETER,
    description: 'Whether action is active',
    default_value: ActionStatus.ACTIVE,
    data_type: DataType.STRING,
    encoding: null,
    encoding_order: null,
    sort: 6,
  },
]

export const EMAIL_ACTION_BANK_FIELDS: ActionFieldModelType[] = [
  {
    column: 'action_id',
    category: ActionFieldType.PARAMETER,
    description: 'Unique action id',
    default_value: null,
    data_type: DataType.INTEGER,
    encoding: null,
    encoding_order: null,
    sort: 2,
  },
  {
    column: 'base_email',
    category: ActionFieldType.FEATURE,
    description: 'Ordinal encoded template id',
    default_value: null,
    data_type: DataType.INTEGER,
    encoding: null,
    encoding_order: null,
    sort: 3,
  },
  {
    column: 'template_id',
    category: ActionFieldType.PARAMETER,
    description: 'Id of the email template stored in the external marketing platform',
    default_value: null,
    data_type: DataType.STRING,
    encoding: null,
    encoding_order: null,
    sort: 4,
  },
  {
    column: 'subject_emotion_is_accomplishment',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as accomplishment in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 5,
  },
  {
    column: 'subject_emotion_is_curiosity',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as curiosity in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 6,
  },
  {
    column: 'subject_emotion_is_fomo',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as fomo in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 7,
  },
  {
    column: 'subject_emotion_is_fortune',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as fortune in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 8,
  },
  {
    column: 'subject_emotion_is_inspiration',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as inspiration in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 9,
  },
  {
    column: 'subject_emotion_is_loyalty',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as loyalty in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 10,
  },
  {
    column: 'subject_emotion_is_luxury',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as luxury in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 11,
  },
  {
    column: 'subject_emotion_is_scarcity',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as scarcity in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 12,
  },
  {
    column: 'subject_emotion_is_security',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as security in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 13,
  },
  {
    column: 'subject_emotion_is_social_proof',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as social_proof in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 14,
  },
  {
    column: 'subject_emotion_is_urgency',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as urgency in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 15,
  },
  {
    column: 'subject_tone_is_authority',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as authority in tone',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 16,
  },
  {
    column: 'subject_tone_is_challenge',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as challenge in tone',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 17,
  },
  {
    column: 'subject_tone_is_excitement',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as excitement in tone',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 18,
  },
  {
    column: 'subject_tone_is_formality',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as formality in tone',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 19,
  },
  {
    column: 'subject_tone_is_humor',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as humor in tone',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 20,
  },
  {
    column: 'subject_tone_is_warmth',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is labeled as warmth in tone',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 21,
  },
  {
    column: 'subject_personalization_is_first_name',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is personalized with first name',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 22,
  },
  {
    column: 'subject_personalization_is_full_name',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is personalized with full name',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 23,
  },
  {
    column: 'subject_emoji_is_emoji',
    category: ActionFieldType.FEATURE,
    description: 'Whether an emoji is used',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 24,
  },
  {
    column: 'subject_count_of_all_characters',
    category: ActionFieldType.FEATURE,
    description: 'Total number of characters in the subject line',
    default_value: '0',
    data_type: DataType.INTEGER,
    encoding: null,
    encoding_order: null,
    sort: 25,
  },
  {
    column: 'subject_count_of_all_words',
    category: ActionFieldType.FEATURE,
    description: 'Total number of words in the subject line',
    default_value: '0',
    data_type: DataType.INTEGER,
    encoding: null,
    encoding_order: null,
    sort: 26,
  },
  {
    column: 'subject_count_of_all_special_characters',
    category: ActionFieldType.FEATURE,
    description: 'Total number of special characters in the subject line',
    // any character except A-Z, a-z, 0-9, space
    default_value: '0',
    data_type: DataType.INTEGER,
    encoding: null,
    encoding_order: null,
    sort: 27,
  },
  {
    column: 'subject_is_all_lower',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is all lowercase',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 28,
  },
  {
    column: 'subject_is_all_title_case',
    category: ActionFieldType.FEATURE,
    description: 'Whether the subject line is all lowercase',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 29,
  },
  {
    column: 'subject_text',
    category: ActionFieldType.PARAMETER,
    description: 'Subject line text',
    default_value: null,
    data_type: DataType.STRING,
    encoding: null,
    encoding_order: null,
    sort: 30,
  },
  {
    column: 'cta_emotion_is_accomplishment',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as accomplishment in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 31,
  },
  {
    column: 'cta_emotion_is_curiosity',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as curiosity in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 32,
  },
  {
    column: 'cta_emotion_is_fomo',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as fomo in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 33,
  },
  {
    column: 'cta_emotion_is_fortune',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as fortune in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 34,
  },
  {
    column: 'cta_emotion_is_inspiration',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as inspiration in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 35,
  },
  {
    column: 'cta_emotion_is_loyalty',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as loyalty in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 36,
  },
  {
    column: 'cta_emotion_is_luxury',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as luxury in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 37,
  },
  {
    column: 'cta_emotion_is_scarcity',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as scarcity in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 38,
  },
  {
    column: 'cta_emotion_is_security',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as security in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 39,
  },
  {
    column: 'cta_emotion_is_social_proof',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as social_proof in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 40,
  },
  {
    column: 'cta_emotion_is_urgency',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as urgency in emotion',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 41,
  },
  {
    column: 'cta_tone_is_authority',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as authority in tone',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 42,
  },
  {
    column: 'cta_tone_is_challenge',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as challenge in tone',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 43,
  },
  {
    column: 'cta_tone_is_excitement',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as excitement in tone',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 44,
  },
  {
    column: 'cta_tone_is_formality',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as formality in tone',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 45,
  },
  {
    column: 'cta_tone_is_humor',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as humor in tone',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 46,
  },
  {
    column: 'cta_tone_is_warmth',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is labeled as warmth in tone',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 47,
  },
  {
    column: 'cta_personalization_is_first_name',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is personalized with first name',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 48,
  },
  {
    column: 'cta_personalization_is_full_name',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is personalized with full name',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 49,
  },
  {
    column: 'cta_emoji_is_emoji',
    category: ActionFieldType.FEATURE,
    description: 'Whether an emoji is used in the cta',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 50,
  },
  {
    column: 'cta_count_of_all_characters',
    category: ActionFieldType.FEATURE,
    description: 'Total number of characters in the cta',
    default_value: '0',
    data_type: DataType.INTEGER,
    encoding: null,
    encoding_order: null,
    sort: 51,
  },
  {
    column: 'cta_count_of_all_words',
    category: ActionFieldType.FEATURE,
    description: 'Total number of words in the cta',
    default_value: '0',
    data_type: DataType.INTEGER,
    encoding: null,
    encoding_order: null,
    sort: 52,
  },
  {
    column: 'cta_count_of_all_special_characters',
    category: ActionFieldType.FEATURE,
    description: 'Total number of special characters in the cta',
    // any character except A-Z, a-z, 0-9, space
    default_value: '0',
    data_type: DataType.INTEGER,
    encoding: null,
    encoding_order: null,
    sort: 53,
  },
  {
    column: 'cta_is_all_lower',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is all lowercase',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 54,
  },
  {
    column: 'cta_is_all_title_case',
    category: ActionFieldType.FEATURE,
    description: 'Whether the cta is all lowercase',
    default_value: 'False',
    data_type: DataType.BOOLEAN,
    encoding: null,
    encoding_order: null,
    sort: 55,
  },
  {
    column: 'cta_text',
    category: ActionFieldType.PARAMETER,
    description: 'CTA text parameter',
    default_value: null,
    data_type: DataType.STRING,
    encoding: null,
    encoding_order: null,
    sort: 56,
  },
  {
    column: 'image',
    category: ActionFieldType.FEATURE,
    description: 'Ordinal encoded image url',
    default_value: null,
    data_type: DataType.INTEGER,
    encoding: null,
    encoding_order: null,
    sort: 57,
  },
  {
    column: 'image_url',
    category: ActionFieldType.PARAMETER,
    description: 'The image url',
    default_value: null,
    data_type: DataType.STRING,
    encoding: null,
    encoding_order: null,
    sort: 58,
  },
  {
    column: 'email_count_of_all_words',
    category: ActionFieldType.FEATURE,
    description: 'Total number of words in the email',
    default_value: '0',
    data_type: DataType.INTEGER,
    encoding: null,
    encoding_order: null,
    sort: 59,
  },
  {
    column: 'email_count_of_images',
    category: ActionFieldType.FEATURE,
    description: 'Total number of images in the email',
    default_value: '0',
    data_type: DataType.INTEGER,
    encoding: null,
    encoding_order: null,
    sort: 60,
  },
  {
    column: 'email_count_of_links',
    category: ActionFieldType.FEATURE,
    description: 'Total number of links in the email',
    default_value: 0,
    data_type: DataType.INTEGER,
    encoding: null,
    encoding_order: null,
    sort: 61,
  },
  {
    column: 'description',
    category: ActionFieldType.PARAMETER,
    description: 'Verbal description of the email action',
    default_value: null,
    data_type: DataType.STRING,
    encoding: null,
    encoding_order: null,
    sort: 62,
  },
  {
    column: 'action_status',
    category: ActionFieldType.PARAMETER,
    description: 'Whether action is active',
    default_value: ActionStatus.ACTIVE,
    data_type: DataType.STRING,
    encoding: null,
    encoding_order: null,
    sort: 999,
  },
]
