<script setup lang="ts">
import ChooseAudience from '@/level4/views/modules/bau-configure/choose-audience/Form.vue'
import ChooseCampaign from '@/level4/views/modules/bau-configure/choose-campaign/Form.vue'
import { BauAudienceSteps } from '@/level4/data/forms'
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'

const formSteps = [
  {
    name: BauAudienceSteps.CHOOSE_CAMPAIGN,
    component: ChooseCampaign,
  },
  {
    name: BauAudienceSteps.CHOOSE_AUDIENCE,
    component: ChooseAudience,
  },
]
</script>

<template>
  <div>
    <L4WizardContainer :steps="formSteps" />
  </div>
</template>
