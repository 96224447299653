<script setup lang="ts">
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'
import ConfigureApiKey from './configure-api/Form.vue'
import { KlaviyoSteps } from '@/level4/data/forms'

const formSteps = [
    {
        name: KlaviyoSteps.PLATFORM_CONFIGURATION_KLAVIYO_API,
        component: ConfigureApiKey,
    },
]

</script>

<template>
  <div>
    <L4WizardContainer :steps="formSteps" />
  </div>
</template>
