const dimensions = [100, 200, 320]
export function getBunnySrcSet(url: string) {
  const srcSet = dimensions.map((width) => {
    return `${url}&width=${width} ${width}w`
  }).join(', ')
  return srcSet
}

// Sizes for email thumbnails based on the dimensions above. Less screen width means less columns, so more image width.
export function getSrcSetSizes() {
  return '(max-width: 400px) 100vw, (max-width: 800px) 50vw, 25vw'
}

const PLACEHOLDER_WIDTH = 10
export function getBunnyPlaceholderImage(url: string) {
  return `${url}&width=${PLACEHOLDER_WIDTH}&quality=5`
}
