<script setup lang="ts">
import AppSettings from '@app/AppSettings.ts'
import { useLvl4Usecase } from '@/level4/composables/useLvl4Usecase.ts'
import BaseEmailEmailTemplatePairInstructionsTable
  from '@/level4/views/modules/launch-klaviyo/klaviyo-finish-flow/BaseEmailEmailTemplatePairInstructionsTable.vue'

const { clientName, pipelineName, experimenterName } = AppSettings
const { usecase } = useLvl4Usecase(clientName.value, pipelineName.value, experimenterName.value)

const klaviyoFlowName = ref()
const triggerEventName = ref()
syncRefs(() => usecase.value?.config?.marketing_platform_connection?.platform_params?.flow_name, klaviyoFlowName)
syncRefs(() => usecase.value?.config?.schedule_activation_events?.config?.klaviyo?.flow.flow_api_event_static, triggerEventName)
</script>

<template>
  <BlueInfoMessage
    class="mb-4"
    subtitle="Some of the setup steps will require the information below, which is specific to your Experimenter. The instructions will reference the  relevant information at each step."
  />
  <div class="text-md mb-4 font-semibold">
    Resources to use for Flow:
  </div>
  <div class="mb-4 ml-4">
    <strong>Flow Name:</strong> {{ klaviyoFlowName }}<br>
    <strong>Trigger Event Name:</strong> {{ triggerEventName }} <br>
    For the Trigger Splits - Email template ID;<br>
    For selecting your template - Search by Base email name, corresponding to the email template ID.
  </div>
  <BaseEmailEmailTemplatePairInstructionsTable />
</template>

<style scoped>

</style>
