<script setup lang="ts">
import { eachDayOfInterval, format, parse } from 'date-fns'
import type { Frequency } from '@/usecases/models/FrequencyModel'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'
import ContainerCard from '@/level4/components/common/ContainerCard.vue'
import { Level4Module } from '@/level4/data/forms'

const router = useRouter()
const { experimenterFormState, updateDaysFrequencyActionBank } = useExperimenterFormState()

const weekdays: {
  label: string
  value: string
}[] = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
]

const currentSelectedFrequenciesArray = ref<Frequency[]>([])
const currentSelectedDaysArray = ref<string[]>([])

const frequencies: ComputedRef<{
  label: string
  value: Frequency
}[]> = computed(() => {
  if (experimenterFormState?.value?.type
    && experimenterFormState.value.type === 'calendared'
    && experimenterFormState?.value?.startDate
    && experimenterFormState?.value?.endDate
    && (eachDayOfInterval({ start: new Date(experimenterFormState?.value?.startDate), end: new Date(experimenterFormState?.value?.endDate) }).length < 7)
  ) {
    return [
      { label: '1 time', value: 1 },
      { label: '2 times', value: 2 },
      { label: '3 times', value: 3 },
      { label: '4 times', value: 4 },
      { label: '5 times', value: 5 },
      { label: '6 times', value: 6 },
      { label: '7 times', value: 7 },
    ]
  }
  else {
    return [
      { label: '1x per week', value: 1 },
      { label: '2x per week', value: 2 },
      { label: '3x per week', value: 3 },
      { label: '4x per week', value: 4 },
      { label: '5x per week', value: 5 },
      { label: '6x per week', value: 6 },
      { label: '7x per week', value: 7 },
    ]
  }
})

watch(() => experimenterFormState.value?.selectedDaysArray, (days) => {
  currentSelectedDaysArray.value = toRaw(days || [])
}, { immediate: true })

watch(() => experimenterFormState.value?.selectedFrequenciesArray, (loadedFrequencies) => {
  const allowedFrequencies = loadedFrequencies?.filter(frequency => frequencies.value.some(f => frequency === f.value))

  currentSelectedFrequenciesArray.value = toRaw(allowedFrequencies || [])
}, { immediate: true })

function handleUpdateFrequencies(frequencies: Frequency[]) {
  currentSelectedFrequenciesArray.value = frequencies
}

function handleUpdateDays(days: string[]) {
  currentSelectedDaysArray.value = days
}

async function handleGoToTypeModule() {
  await router.push({ name: Level4Module.TYPE })
}

const minimumDaysToSelect = computed(() => {
  return Math.max(...(currentSelectedFrequenciesArray.value.map((item: Frequency) => item || 0) || [0]))
})

const areDaysSelectedValid = computed(() => {
  return currentSelectedDaysArray.value?.length >= minimumDaysToSelect.value
})

const invalidDates = computed(() => {
  if (!experimenterFormState?.value?.startDate || !experimenterFormState?.value?.endDate || experimenterFormState?.value?.type !== 'calendared') {
    return []
  }

  // Need to concatenate the UTC timezone to the date string to avoid timezone issues
  const startDate = new Date(`${experimenterFormState?.value?.startDate}T00:00:00`)
  const endDate = new Date(`${experimenterFormState?.value?.endDate}T23:59:59`)

  const interval = eachDayOfInterval({ start: startDate, end: endDate })

  const uniqueDaysOfWeek = new Set(interval.map(date => format(date, 'EEEE')))

  return weekdays.filter(day => !uniqueDaysOfWeek.has(day.value)).map(day => day.value)
})

const invalidFrequencies = computed(() => {
  if (!invalidDates.value.length) {
    return []
  }

  const maxFrequency = weekdays.length - invalidDates.value.length

  return frequencies.value.filter(frequency => frequency.value > maxFrequency).map(frequency => frequency.value)
})

const dateRange = computed(() => {
  if (!experimenterFormState?.value?.startDate || !experimenterFormState?.value?.endDate || experimenterFormState?.value?.type !== 'calendared') {
    return ''
  }

  const start = parse(experimenterFormState?.value?.startDate, 'yyyy-MM-dd', new Date())
  const end = parse(experimenterFormState?.value?.endDate, 'yyyy-MM-dd', new Date())

  const formatedStart = format(start, 'MMMM dd')
  const formatedEnd = (format(start, 'MMMM') !== format(end, 'MMMM')) ? format(end, 'MMMM dd') : format(end, 'dd')

  return `${formatedStart} - ${formatedEnd}`
})

const daysOfWeekSubtitle = computed(() => {
  if (!areDaysSelectedValid.value) {
    return `Select at least ${minimumDaysToSelect.value} days of week`
  }
  else {
    return 'Select frequency first'
  }
})

defineExpose<FormExposedProperties>({
  dataIsComplete: computed(() => !!currentSelectedFrequenciesArray.value?.length && !!currentSelectedDaysArray.value?.length && !!areDaysSelectedValid.value),
  submitHandler: async () => {
    await updateDaysFrequencyActionBank({ selectedDaysArray: currentSelectedDaysArray.value, selectedFrequenciesArray: currentSelectedFrequenciesArray.value })
  },
})
</script>

<template>
  <WizardFormPageLayout containerSize="large" headerSize="medium" title="Choose the sending frequencies and days of week that will be available to OfferFit.">
    <NCard v-if="experimenterFormState?.type === 'calendared'" contentClass="border-blue-400 border-1">
      <div class="flex gap-2">
        <div class="i-solar-info-circle-bold text-blue-400" />
        <div class="flex flex-col gap-2">
          <p class="font-semibold">
            Your previously selected experimenter dates: {{ dateRange }}.
          </p>
          <p>The frequency and days of week options are automatically filtered based on the experimenter dates.</p>
          <NButton class="!w-fit" @click="handleGoToTypeModule">
            Change Dates
          </NButton>
        </div>
      </div>
    </NCard>
    <ContainerCard title="Frequency" description="Select at least 1 frequency" icon="i-solar-calendar-bold" className="border-b-2">
      <ChipSelect id="select-frequencies-chips" w="full" :selectedTags="currentSelectedFrequenciesArray" :tagItems="frequencies" :disabledTags="invalidFrequencies" @modelValueChange="handleUpdateFrequencies" />
    </ContainerCard>
    <ContainerCard
      title="Days of week"
      :description="daysOfWeekSubtitle"
      icon="i-solar-calendar-bold" className="border-b-2"
    >
      <ChipSelect id="select-days-chips" w="full" :selectedTags="currentSelectedDaysArray" :tagItems="weekdays" :disabledTags="invalidDates" @modelValueChange="handleUpdateDays" />
    </ContainerCard>
  </WizardFormPageLayout>
</template>

<style scoped>
</style>
