<script setup lang="ts">
import { emailPreviewXssPlugin } from '@shared/utils/xss'
import { replaceImageSrcFromIdentifier, replaceInnerContentFromIdentifier } from '@/level4/utils/LLM/assignIdentifiersToHtmlString'

interface Props {
  html: string
  replaceMap: {
    cta: Record<string, string>
    image: Record<string, string>
    subjectLine?: string
  }
}
withDefaults(defineProps<Props>(), {  })
const emit = defineEmits<{
  close: [void]
}>()
const show = defineModel('show', { required: true })

const replacedHtml = computed(() => {
  let replacementHtmlBus = toRaw(__props.html)

  if (__props.replaceMap?.cta) {
    Object.entries(__props.replaceMap.cta).forEach(([identifier, replacement]) => {
      replacementHtmlBus = replaceInnerContentFromIdentifier(replacementHtmlBus, identifier, replacement)
    })
  }

  if (__props.replaceMap?.image) {
    Object.entries(__props.replaceMap.image).forEach(([identifier, replacement]) => {
      replacementHtmlBus = replaceImageSrcFromIdentifier(replacementHtmlBus, identifier, replacement)
    })
  }

  return emailPreviewXssPlugin.process(replacementHtmlBus)
})
</script>

<template>
  <NDrawer v-model:show="show" preset="card" width="800px" @esc="emit('close')">
    <NDrawerContent closable>
      <template #header>
        Preview | Subject line: {{ replaceMap.subjectLine }}
      </template>
      <ShadowDomEmailTemplatePreview v-if="replacedHtml" :html="replacedHtml" w="full" pointer-events="none" />
    </NDrawerContent>
  </NDrawer>
</template>
