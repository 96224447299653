<script lang="tsx">
const STATUS_VALIDATING = 'validating'
const STATUS_PASSED = 'passed'
const STATUS_FAILED = 'failed'
</script>
<script setup lang="tsx">
import AppSettings from '@app/AppSettings.ts'
import { NButton } from 'naive-ui'
import SFMCValidationStatusTag from './KlaviyoValidationStatusTag.vue'
import type { KlaviyoIntegrationValidationResponse } from '@/level4/services/PlatformIntegrationService.ts'
import { useLvl4Usecase } from '@/level4/composables/useLvl4Usecase.ts'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

interface ValidationTableDataModel {
  type: string
  status: 'passed' | 'failed' | 'validating'
  description: string
  how_to_fix: string
  how_to_fix_action_component?: string
}

const { clientName, pipelineName, experimenterName } = AppSettings
const { experimenterFormState, validateIntegration, experimenterFinishSetUpHook } = useExperimenterFormState()
const { usecase } = useLvl4Usecase(clientName.value, pipelineName.value, experimenterName.value)
const validationResponseData = ref<KlaviyoIntegrationValidationResponse>()
const loading = ref<boolean>(false)

onMounted(async () => {
  await setValidationData()
})

async function setValidationData() {
  loading.value = true
  validationResponseData.value = await validateIntegration() as KlaviyoIntegrationValidationResponse
  loading.value = false
}





const triggerEventName = usecase.value?.config?.schedule_activation_events?.config?.klaviyo?.flow.flow_api_event_static

const howToFixActionsComponentMap = {
  checkInstructions: () => (
    <NButton quaternary type="primary" strong onClick={() => window.open('https://app.tango.us/app/workflow/Creating-a-Klaviyo-Flow-for-OfferFit-Email-9e156d69fca4467bba2500c49dda0f85')}>
      {{
        default: 'Check Instructions',
        icon: <div class="i-solar-question-circle-outline" />,
      }}
    </NButton>
  ),
}

const validationData = computed<ValidationTableDataModel[]>(() => {
  return [
    {
      type: 'Flow',
      description: 'Flow exists and is live',
      status: loading.value ? STATUS_VALIDATING : validationResponseData.value?.result.flow_status_live ? STATUS_PASSED : STATUS_FAILED,
      how_to_fix: `Check and make sure your flow matches the name ${experimenterFormState.value?.klaviyoFlowName} and is live.`,
    },
    {
      type: 'Flow',
      description: 'Flow exists and is unarchived',
      status: loading.value ? STATUS_VALIDATING : validationResponseData.value?.result.flow_not_archived ? STATUS_PASSED : STATUS_FAILED,
      how_to_fix: `Check and make sure your flow matches the name ${experimenterFormState.value?.klaviyoFlowName} and is unarchived.`,
    },
    {
      type: 'Flow',
      description: 'Flow is a \'metric triggered\' flow',
      status: loading.value ? STATUS_VALIDATING : validationResponseData.value?.result.flow_metric_triggered ? STATUS_PASSED : STATUS_FAILED,
      how_to_fix: `Ensure your flow is a metric-triggered flow with the trigger set to ${triggerEventName}.`,
    },
    {
      type: 'Triggers',
      description: 'Number of trigger splits in the flow equals the number of base templates in the Experimenter',
      status: loading.value ? STATUS_VALIDATING : validationResponseData.value?.result.num_trigger_splits ? STATUS_PASSED : STATUS_FAILED,
      how_to_fix: `Ensure that you have a trigger split in your Klaviyo Flow for each base template. In your case, you should have ${validationResponseData.value?.result.num_expected_trigger_splits} trigger splits. Currently you have ${validationResponseData.value?.result.num_actual_trigger_splits}.`,
    },
    {
      type: 'email nodes',
      description: 'All Email nodes have "Skip recently emailed profiles" box unchecked',
      status: loading.value ? STATUS_VALIDATING : validationResponseData.value?.result.no_smart_sending ? STATUS_PASSED : STATUS_FAILED,
      how_to_fix: 'Ensure that the "Smart sending" box is unchecked for each email node. See instructions in step 20',
      how_to_fix_action_component: howToFixActionsComponentMap.checkInstructions,
    },
    {
      type: 'email nodes',
      description: 'All Email nodes are set to live',
      status: loading.value ? STATUS_VALIDATING : validationResponseData.value?.result.email_nodes_live ? STATUS_PASSED : STATUS_FAILED,
      how_to_fix: `Ensure each Email node in your Klaviyo Flow is set to Live. See instructions in step 22`,
      how_to_fix_action_component: howToFixActionsComponentMap.checkInstructions,
    },
    {
      type: 'email nodes',
      description: 'Number of email nodes in the flow equals the number of base templates in the Experimenter',
      status: loading.value ? STATUS_VALIDATING : validationResponseData.value?.result.num_email_nodes ? STATUS_PASSED : STATUS_FAILED,
      how_to_fix: `Ensure that you have an Email node in your Klaviyo Flow for each base template. In your case, you should have ${validationResponseData.value?.result.num_expected_email_nodes} Email nodes. Currently you have ${validationResponseData.value?.result.num_actual_email_nodes}`,
      how_to_fix_action_component: howToFixActionsComponentMap.checkInstructions,
    },
  ]
})

defineExpose<FormExposedProperties>({
  nextFormButtonText: 'Finish setup',
  dataIsComplete: computed(() => !!validationResponseData.value?.result.flow_id),
  submitHandler: async () => {
    if (!validationResponseData.value?.result.flow_id) { throw new Error('no flow_id') }
    await experimenterFinishSetUpHook(validationResponseData.value?.result.flow_id)
    // TODO: we should call this function after every experimenter completed, not only for Klaviyo!
    // !!!
    // that means no arguments should be passed to this function, but BE should know where to get the data from
  },
})
</script>

<template>
  <WizardFormPageLayout
    containerSize="full"
    title="The experimenter will be ready to go live after completing the last few Klaviyo steps."
  >
    <div class="re-run" flex="~" justify="end" p="2">
      <NButton
        secondary
        :loading="loading"
        @click="setValidationData"
      >
        <template #icon>
          <div class="i-solar-refresh-bold" />
        </template>
        Refresh
      </NButton>
    </div>
    <div class="validation-list" grid="~" gap="2">
      <div
        v-for="validationItem in validationData"
        :key="validationItem.description"
        class="validation-item"
      >
        <NCard
          rounded="!lg"
          :hoverable="validationItem.status === STATUS_FAILED"
          :embedded="validationItem.status === STATUS_PASSED"
          :segmented="{ content: true }"
        >
          <template #header>
            <div class="item-validation-wrapper" flex="~" gap="4" items="start" :opacity="validationItem.status === STATUS_PASSED ? '70' : '100'">
              <div
                flex="~ 1 col"
              >
                <h2 font="bold" text="sm" flex="~" gap="2" items="center">
                  <NBadge
                    dot
                    :type="validationItem.status === STATUS_PASSED ? 'success' : 'warning'"
                  />
                  {{ validationItem.type }}
                </h2>
                <p
                  font="normal"
                  text="sm capitalize"
                >
                  {{ validationItem.description }}
                </p>
              </div>
              <SFMCValidationStatusTag
                :status="validationItem.status"
              />
            </div>
          </template>
          <NCollapseTransition :show="validationItem.status === STATUS_FAILED">
            <div
              flex="~ wrap"
              gap="2"
              items="center"
              text="brand-purple-bright base"
            >
              <div class="left flex flex-1 gap-2">
                <div class="i-solar-sledgehammer-bold-duotone text-sm" />
                <span font="bold">How to fix?</span>
                <span font="regular">{{ validationItem.how_to_fix }}</span>
              </div>
              <component :is="validationItem.how_to_fix_action_component" v-if="validationItem.how_to_fix_action_component" />
            </div>
          </NCollapseTransition>
        </NCard>
      </div>
    </div>
  </WizardFormPageLayout>
</template>
