<script setup lang="ts">
import GuardrailCard from '@/level4/components/guardrails/GuardrailCard.vue'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import type { Frequency } from '@/usecases/models/FrequencyModel'
import type { EmailGuardrail, OFSSGuardrailFeature } from '@/level4/utils/guardrails/utils'
import { PeriodGuardrailMap } from '@/level4/utils/guardrails/utils'

const { experimenterFormState, variantsCount, subjectLinesCount, updateGuardrails } = useExperimenterFormState()

function getEmptyEmailGuardrails(feature: OFSSGuardrailFeature): EmailGuardrail {
  return {
    feature,
    selected: undefined,
    period: undefined,
    times: undefined,
  }
}

const currentSelectedBaseEmailGuardrail = ref<EmailGuardrail>(getEmptyEmailGuardrails('template_id'))
const currentSelectedVariantsGuardrail = ref<EmailGuardrail>(getEmptyEmailGuardrails('variant_id'))
const currentSelectedSubjectLineGuardrail = ref<EmailGuardrail>(getEmptyEmailGuardrails('subject_text'))

syncRefs(() => toRaw(experimenterFormState.value?.selectedBaseEmailGuardrails ?? getEmptyEmailGuardrails('template_id')), currentSelectedBaseEmailGuardrail, { immediate: true })
syncRefs(() => toRaw(experimenterFormState.value?.selectedVariantsGuardrails ?? getEmptyEmailGuardrails('variant_id')), currentSelectedVariantsGuardrail, { immediate: true })
syncRefs(() => toRaw(experimenterFormState.value?.selectedSubjectLineGuardrails ?? getEmptyEmailGuardrails('subject_text')), currentSelectedSubjectLineGuardrail, { immediate: true })

const guardrailDataComplete = computed(() => {
  const emailGuardrails = currentSelectedBaseEmailGuardrail.value?.selected ? currentSelectedBaseEmailGuardrail.value?.period && currentSelectedBaseEmailGuardrail.value?.times : true
  const variantsGuardrails = currentSelectedVariantsGuardrail.value?.selected ? currentSelectedVariantsGuardrail.value?.period && currentSelectedVariantsGuardrail.value?.times : true
  const subjectLineGuardrails = currentSelectedSubjectLineGuardrail.value?.selected ? currentSelectedSubjectLineGuardrail.value?.period && currentSelectedSubjectLineGuardrail.value?.times : true
  return !!(emailGuardrails && variantsGuardrails && subjectLineGuardrails)
})

const maxFrequency = computed(() => {
  return Math.max(...(experimenterFormState.value?.selectedFrequenciesArray?.map((item: Frequency) => item) || [0]))
})

const showBaseEmailWarning = computed(() => {
  if (!currentSelectedBaseEmailGuardrail.value?.selected || !currentSelectedBaseEmailGuardrail.value?.period || !currentSelectedBaseEmailGuardrail.value?.times) { return false }
  return ((maxFrequency.value / 7) * (currentSelectedBaseEmailGuardrail.value.period ?? 0)) > ((experimenterFormState.value?.currentImportedTemplates?.length ?? 0) * (currentSelectedBaseEmailGuardrail.value.times ?? 0))
})

const showSubjectLineWarning = computed(() => {
  if (!currentSelectedSubjectLineGuardrail.value?.selected || !currentSelectedSubjectLineGuardrail.value?.period || !currentSelectedSubjectLineGuardrail.value?.times) { return false }
  return ((maxFrequency.value / 7) * (currentSelectedSubjectLineGuardrail.value.period ?? 0)) > ((subjectLinesCount.value ?? 0) * (currentSelectedSubjectLineGuardrail.value.times ?? 0))
})

const showVariantsWarining = computed(() => {
  if (!currentSelectedVariantsGuardrail.value?.selected || !currentSelectedVariantsGuardrail.value?.period || !currentSelectedVariantsGuardrail.value?.times) { return false }
  return ((maxFrequency.value / 7) * (currentSelectedVariantsGuardrail.value.period ?? 0)) > ((variantsCount.value ?? 0) * (currentSelectedVariantsGuardrail.value.times ?? 0))
})

const warningVariantVsBaseTemplateTimes = computed(() => {
  return (currentSelectedVariantsGuardrail.value?.selected
    && currentSelectedBaseEmailGuardrail.value?.selected)
    && ((currentSelectedVariantsGuardrail.value?.times ?? 0) > (currentSelectedBaseEmailGuardrail.value?.times ?? 0))
})

const warningVariantVsSubjectLineTimes = computed(() => {
  return (currentSelectedVariantsGuardrail.value?.selected
    && currentSelectedSubjectLineGuardrail.value?.selected)
    && ((currentSelectedVariantsGuardrail.value?.times ?? 0) > (currentSelectedSubjectLineGuardrail.value?.times ?? 0))
})

const warningSubjectLineTimes = computed(() => {
  return (currentSelectedSubjectLineGuardrail.value?.selected
    && currentSelectedBaseEmailGuardrail.value?.selected)
    && ((currentSelectedSubjectLineGuardrail.value?.times ?? 0) > (currentSelectedBaseEmailGuardrail.value?.times ?? 0))
})

const recommendedNVariants = computed(() => {
  if (!currentSelectedVariantsGuardrail.value || !currentSelectedVariantsGuardrail.value?.period || !currentSelectedVariantsGuardrail.value?.times) { return }
  return Math.ceil(currentSelectedVariantsGuardrail.value.period / currentSelectedVariantsGuardrail.value.times)
})

const recommendedNSubjectLines = computed(() => {
  if (!currentSelectedSubjectLineGuardrail.value || !currentSelectedSubjectLineGuardrail.value?.period || !currentSelectedSubjectLineGuardrail.value?.times) { return }
  return Math.ceil(currentSelectedSubjectLineGuardrail.value.period / currentSelectedSubjectLineGuardrail.value.times)
})

const periodGuardRailOptions = Object.entries(PeriodGuardrailMap).map(([key, keyVal]) => {
  return {
    label: key.toString(),
    key: keyVal,
  }
})

defineExpose<FormExposedProperties>({
  dataIsComplete: computed(() => guardrailDataComplete.value),
  submitHandler: async () => {
    const guardrailsFilteredObject = {
      baseEmailGuardrail: currentSelectedBaseEmailGuardrail.value,
      variantsGuardrail: currentSelectedVariantsGuardrail.value,
      subjectLineGuardrail: currentSelectedSubjectLineGuardrail.value,
    }

    guardrailsFilteredObject.baseEmailGuardrail.feature = currentSelectedBaseEmailGuardrail.value.feature ?? 'template_id'
    guardrailsFilteredObject.variantsGuardrail.feature = currentSelectedVariantsGuardrail.value.feature ?? 'variant_id'
    guardrailsFilteredObject.subjectLineGuardrail.feature = currentSelectedSubjectLineGuardrail.value.feature ?? 'subject_text'

    return updateGuardrails(guardrailsFilteredObject)
  },
})

function timesOptions(n: number) {
  return Array.from({ length: n }, (_, i) => i + 1).map((val) => {
    return {
      key: val,
      label: val === 1 ? `${val} time` : `${val} times`,
    }
  })
}
</script>

<template>
  <WizardFormPageLayout containerSize="large" headerSize="medium" title="Set a duplicate content guardrail" subtitle="Manage the maximum frequency at which the same recipient receives the same base email or variant.">
    <div class="flex flex-col gap-y-4">
      <GuardrailCard
        class="mt-3 mb-3"
        targetLabel="Base email"
        :periodOptions="periodGuardRailOptions"
        :timesOptions="timesOptions(currentSelectedBaseEmailGuardrail.period ?? 0)"
        :selected="currentSelectedBaseEmailGuardrail.selected"
        :period="currentSelectedBaseEmailGuardrail.period"
        :times="currentSelectedBaseEmailGuardrail.times"
        :invalidTimes="warningVariantVsBaseTemplateTimes || warningSubjectLineTimes"
        @update:selected="currentSelectedBaseEmailGuardrail.selected = $event"
        @update:period="currentSelectedBaseEmailGuardrail.period = $event"
        @update:times="currentSelectedBaseEmailGuardrail.times = $event"
        @unselect="currentSelectedBaseEmailGuardrail.selected = false"
      />

      <NCollapseTransition :show="!!showBaseEmailWarning">
        <YellowWarning class="mb-3" title="OfferFit will need to suppress sends for at least some users">
          Based on your current selections, OfferFit will need to suppress sends for at least some users in order to satisfy these guardrails.
          To avoid this, you can <b text="brand-purple-bright">Add more base templates, or increase the number of times the same recipient can receive the same base template.</b>
          Or you can <b text="brand-purple-bright">decrease the maximum allowed Send frequency.</b>
        </YellowWarning>
      </NCollapseTransition>

      <GuardrailCard
        class="mt-3 mb-3"
        targetLabel="Subject line"
        :periodOptions="periodGuardRailOptions"
        :timesOptions="timesOptions(currentSelectedSubjectLineGuardrail.period ?? 0)"
        :selected="currentSelectedSubjectLineGuardrail.selected"
        :period="currentSelectedSubjectLineGuardrail.period"
        :times="currentSelectedSubjectLineGuardrail.times"
        :invalidTimes="warningSubjectLineTimes"
        @update:selected="currentSelectedSubjectLineGuardrail.selected = $event"
        @update:period="currentSelectedSubjectLineGuardrail.period = $event"
        @update:times="currentSelectedSubjectLineGuardrail.times = $event"
        @unselect="currentSelectedSubjectLineGuardrail.selected = false"
      />

      <NCollapseTransition :show="!!warningSubjectLineTimes">
        <YellowWarning class="mb-3" title="Oops! Your base email guardrail is currently stricter than your subject line guardrail. If you continue with these settings, your base email guardrail will override your subject line guardrail." />
      </NCollapseTransition>

      <NCollapseTransition :show="!!showSubjectLineWarning">
        <YellowWarning class="mb-3" title="OfferFit will need to suppress sends for at least some users">
          Based on your current selections, OfferFit will need to suppress sends for at least some users in order to satisfy these guardrails.
          To avoid this, you can <b text="brand-purple-bright">Add more subject lines, or increase the number of times the same recipient can receive the same base template.</b>
          Or you can <b text="brand-purple-bright">decrease the maximum allowed Send frequency.</b>
          Recommended number of subject lines: minimum {{ recommendedNSubjectLines }}.
        </YellowWarning>
      </NCollapseTransition>

      <GuardrailCard
        class="mt-3 mb-3"
        targetLabel="Variant"
        :periodOptions="periodGuardRailOptions"
        :timesOptions="timesOptions(currentSelectedVariantsGuardrail.period ?? 0)"
        :selected="currentSelectedVariantsGuardrail.selected"
        :period="currentSelectedVariantsGuardrail.period"
        :times="currentSelectedVariantsGuardrail.times"
        :invalidTimes="warningVariantVsBaseTemplateTimes || warningVariantVsSubjectLineTimes"
        @update:selected="currentSelectedVariantsGuardrail.selected = $event"
        @update:period="currentSelectedVariantsGuardrail.period = $event"
        @update:times="currentSelectedVariantsGuardrail.times = $event"
        @unselect="currentSelectedVariantsGuardrail.selected = false"
      />

      <NCollapseTransition :show="!!warningVariantVsBaseTemplateTimes">
        <YellowWarning class="mb-3" title="Oops! Your base email guardrail is currently stricter than your variant guardrail. If you continue with these settings, your base email guardrail will override your variant guardrail." />
      </NCollapseTransition>

      <NCollapseTransition :show="!!warningVariantVsSubjectLineTimes">
        <YellowWarning class="mb-3" title="Oops! Your subject line guardrail is currently stricter than your variant guardrail. If you continue with these settings, your subject line guardrail will override your variant guardrail." />
      </NCollapseTransition>

      <NCollapseTransition :show="!!showVariantsWarining">
        <YellowWarning class="mb-3" title="OfferFit will need to suppress sends for at least some users">
          Based on your current selections, OfferFit will need to suppress sends for at least some users in order to satisfy these guardrails.
          To avoid this, you can <b text="brand-purple-bright">Add more variants, or increase the number of times the same recipient can receive the same variant.</b>
          Or you can <b text="brand-purple-bright">decrease the maximum allowed Send frequency.</b>
          Recommended number of variants: minimum {{ recommendedNVariants }}.
        </YellowWarning>
      </NCollapseTransition>
    </div>
  </WizardFormPageLayout>
</template>, EmailGuardrail
