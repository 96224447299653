import { useLvl4PipelineConfig } from '@/pipelines/composables/useLvl4PipelineConfig'
import { useUseCaseConfig } from '@/usecases/composables/useUseCaseConfig'
import type { AudienceModel } from '@/level4/models/braze/AudienceModel'
import { INTEGRATION_PLATFORM } from '@/level4/data/l4_constants'
import { useExperimenterFormState } from './useExperimenterFormState'

export function useSFMC() {
    const { experimenterFormState } = useExperimenterFormState()

    const OFE_ENGAGEMENT_DATA_QUERY = `select
        d.SubscriberKey,
        d.EventDate,
        d.EventType,
        d.URL,
        d.TriggererSendDefinitionObjectID,
        d.TriggeredSendCustomerKey,
        ja.ActivityName,
        ja.ActivityExternalKey,
        ja.ActivityType,
        ja.VersionID as JourneyVersionID,
        j.JourneyID,
        j.JourneyName
    from (
        select
            s.SubscriberKey,
            s.EventDate as EventDate,
            'Sent' as EventType,
            null as URL,
            s.TriggererSendDefinitionObjectID,
            s.TriggeredSendCustomerKey
        from
            _Sent s
        where
            s.TriggererSendDefinitionObjectID is not null

        union

        select
            o.SubscriberKey,
            o.EventDate as EventDate,
            'Open' as EventType,
            null as URL,
            o.TriggererSendDefinitionObjectID,
            o.TriggeredSendCustomerKey
        from
            _Open o
        where
            o.TriggererSendDefinitionObjectID is not null

        union

        select
            c.SubscriberKey,
            c.EventDate as EventDate,
            'Click' as EventType,
            c.URL as URL,
            c.TriggererSendDefinitionObjectID,
            c.TriggeredSendCustomerKey
        from
            _Click c
        where
            c.TriggererSendDefinitionObjectID is not null
        union
        select
            u.SubscriberKey,
            u.EventDate as EventDate,
            'Unsubscribe' as EventType,
            null as URL,
            null as TriggererSendDefinitionObjectID,
            null as TriggeredSendCustomerKey
        from
            _Unsubscribe u
    ) as d
    left join
        _JourneyActivity ja
    on
        d.TriggererSendDefinitionObjectID = ja.JourneyActivityObjectID
    left join
        _Journey j
    on
        ja.VersionID = j.VersionID
    where d.SubscriberKey is not null
    and j.JourneyID in (null, 'JOURNEY_ID_HERE')
    and d.EventDate >= cast(dateadd(day, -1, cast(getdate() as date)) as datetime)
    and d.EventDate < cast(cast(getdate() as date) as datetime)
  `

    const getOFEEngagementDataQuery = () => {
        const { getUseCaseConfig } = useUseCaseConfig()
        return OFE_ENGAGEMENT_DATA_QUERY.replace('JOURNEY_ID_HERE', getUseCaseConfig.value?.marketing_platform_connection?.platform_params?.journey_id || 'JOURNEY_ID_HERE')
    }

    const getOFEBaseDataExtensionQuery = () => {
        const { getAudienceDataExtensions } = useLvl4PipelineConfig()

        const audienceExtensionInnerQuery = getAudienceDataExtensions(INTEGRATION_PLATFORM.SFMC).reduce((query, extension) => {
            const audienceName = experimenterFormState.value?.importedAudiences?.find((audience: AudienceModel) => audience.id === extension)?.name

            if (query.length > 0) {
                query += ' union'
            }
            query += `select distinct SubscriberKey from [${audienceName}]`

            return query
        }, '')

        return `select
       data.SubscriberKey,
       s.SubscriberType,
       s.DateJoined,
       s.BounceCount,
       s.Status
    from (
       ${audienceExtensionInnerQuery}
    ) as data
    inner join _Subscribers s on data.SubscriberKey = s.SubscriberKey
    where
       s.Status = 'active'
   `
    }

    return {
        getOFEBaseDataExtensionQuery,
        getOFEEngagementDataQuery,
    }
}
