<script setup lang="ts">
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState.ts'
import { useSFMC } from '@/level4/composables/useSFMC'
import NameExternalKeyPairTable
  from '@/level4/views/modules/launch-sfmc/sfmc-data-queries/NameExternalKeyPairTable.vue'

const { getOFEEngagementDataQuery } = useSFMC()
const { engagementExtensionNameToOverrwrite } = useExperimenterFormState()
</script>

<template>
  <p>
    4.1 Drag and drop "SQL Query" to the Step 1 column, then press “Choose” button to set up the second data query.
    <NImage src="/integration-instructions/sfmc/launch-sfmc/data-queries/11_summary_sql_query.png" />
  </p>
  <p>
    4.2 Press “Create New Query Activity” button.
    <NImage src="/integration-instructions/sfmc/launch-sfmc/data-queries/12_create_new_query.png" />
  </p>
  <p>
    4.3 At the “Create New Query Activity” screen enter "OFE_Engagement_query" for the Name and External Key fields, or any other name as long as both fields match. Press “Next” to continue.
    <NameExternalKeyPairTable :data="[{ name: 'OFE_Engagement_query', externalKey: 'OFE_Engagement_query' }]" />
    <NImage src="/integration-instructions/sfmc/launch-sfmc/data-queries/13_engagement_query_name.png" />
  </p>
  <p>
    4.4. At the “Query” step, copy and paste the below SQL Query into the "SQL Query" textbox. Press “Next” to continue.
    <NImage src="/integration-instructions/sfmc/launch-sfmc/data-queries/14_engagement_query_paste.png" />
    <CodeBlock :content="getOFEEngagementDataQuery()" language="sql" />
  </p>
  <p>
    4.5 At the “Target Data Extension” step select the data extension with following name. Set the Data Action to "Overwrite". Press “Next” to continue.
    <NameExternalKeyPairTable :data="[{ name: engagementExtensionNameToOverrwrite }]" />
    <NImage src="/integration-instructions/sfmc/launch-sfmc/data-queries/15_data_extension_overwrite.png" />
  </p>
  <p>
    4.6 Press “Finish” to save the second SQL Query Activity.
    <NImage src="/integration-instructions/sfmc/launch-sfmc/data-queries/16_save.png" />
  </p>
</template>
