import type { GetKpiQueryParams } from '@/reporting/models/server/ReportingModel'

interface MockReportingData {
  dates: string[]
  regime: string[]
  unsubscribe: number[]
  total_email_click: number[]
  clicked_customer: number[]
  attributed_email_click: number[]
  unique_daily_click: number[]
  contacted_customer: number[]
  total_email_send: number[]
  email_send: number[]
  customer: number[]
  [key: string]: string[] | number[]
}

// Enums and interfaces needed
enum Aggregation {
  SUM = 'sum',
  MEAN = 'mean',
  COUNT_DISTINCT = 'count distinct',
  NONE = 'none',
}

export function deleteMockReportingData() {
  localStorage.removeItem('mockReportingData')
  location.reload()
}

export function handleMockReportingFileUpload(event: any) {
  const file = event.target.files[0]

  const reader = new FileReader()
  let jsonData: Record<string, string[]>

  reader.onload = async () => {
    jsonData = parseCSVIntoJSON(reader.result?.toString())
    localStorage.setItem('mockReportingData', JSON.stringify(jsonData))
  }

  if (file) {
    reader.readAsText(file)
    location.reload()
  }
}

export function mockGetDataPerKPI(queryParams: Partial<GetKpiQueryParams>) {
  const jsonData = JSON.parse(localStorage.getItem('mockReportingData') as string) as MockReportingData
  const horizons = [1, 7, 30]
  const metrics = processMetricArguments(queryParams.numerator_metric, queryParams.denominator_metric)
  const metricName = `${metrics[0]}_per_${metrics[1]}`
  const comparisonRegime = queryParams.comparison_regime
  const filteredData = filterMockReportingDataByDateRange(jsonData, queryParams.start_date, queryParams.end_date)
  const resultData = calculateRollingSums(filteredData, horizons, metrics)
  const ratioData = calculateRatios(resultData, horizons, [[metrics[0], metrics[1], metricName]])
  const joinedData = joinRegimeData(ratioData, ['offerfit', 'control'], comparisonRegime)
  const upliftData = calculateUplift(joinedData, horizons, [metricName])

  const numerator_offerfit = aggregateData(upliftData[`${metrics[0]}_offerfit`] as number[], queryParams.numerator_aggregation)
  const numerator_comparison = aggregateData(upliftData[`${metrics[0]}_comparison`] as number[], queryParams.numerator_aggregation)
  const denominator_offerfit = aggregateData(upliftData[`${metrics[1]}_offerfit`] as number[], queryParams.denominator_aggregation)
  const denominator_comparison = aggregateData(upliftData[`${metrics[1]}_comparison`] as number[], queryParams.denominator_aggregation)
  const value_offerfit = numerator_offerfit / denominator_offerfit
  const value_comparison = numerator_comparison / denominator_comparison

  const result = {
    numerator_offerfit,
    numerator_comparison,
    denominator_offerfit,
    denominator_comparison,
    value_offerfit,
    value_comparison,
    uplift: (value_offerfit - value_comparison) / value_comparison,
    dates: upliftData.rep_dt,
    values_offerfit_rolling_1: upliftData[`${metricName}_rolling_1_offerfit`],
    values_comp_rolling_1: upliftData[`${metricName}_rolling_1_comparison`],
    values_offerfit_rolling_7: upliftData[`${metricName}_rolling_7_offerfit`],
    values_comp_rolling_7: upliftData[`${metricName}_rolling_7_comparison`],
    values_offerfit_rolling_30: upliftData[`${metricName}_rolling_30_offerfit`],
    values_comp_rolling_30: upliftData[`${metricName}_rolling_30_comparison`],
    uplift_rolling_1: upliftData[`uplift_${metricName}_rolling_1`],
    uplift_rolling_7: upliftData[`uplift_${metricName}_rolling_7`],
    uplift_rolling_30: upliftData[`uplift_${metricName}_rolling_30`],
    p_value: calculateStatisticalSignificance(numerator_offerfit, numerator_comparison, denominator_offerfit, denominator_comparison),
  }

  return result
}

function parseCSVIntoJSON(csvText: string) {
  const rows = csvText.replaceAll('\r', '').split('\n').map((row) => {
    return row.split(',')
  })
  const headers = rows?.[0]

  const data = rows?.slice(1)

  const jsonData: MockReportingData = {}
  headers?.forEach((value, index) => {
    jsonData[value] = data?.map((row) => {
      if (['dates', 'regime'].includes(value)) {
        return row[index]
      }
      else {
        return Number(row[index])
      }
    })
  })
  return jsonData
}

function filterMockReportingDataByDateRange(
  data: MockReportingData,
  startDate: string,
  endDate: string,
): MockReportingData {
  // Convert dates to timestamps for comparison
  const startTimestamp = new Date(startDate).getTime()
  const endTimestamp = new Date(endDate).getTime()

  // Find indices of dates within range
  const validIndices = data.dates.reduce<number[]>((indices, date, index) => {
    const timestamp = new Date(date).getTime()
    if (timestamp >= startTimestamp && timestamp <= endTimestamp) {
      indices.push(index)
    }
    return indices
  }, [])

  // Create filtered data object
  const filteredData: MockReportingData = {
    dates: [],
    regime: [],
    unsubscribe: [],
    total_email_click: [],
    clicked_customer: [],
    attributed_email_click: [],
    unique_daily_click: [],
    contacted_customer: [],
    total_email_send: [],
    email_send: [],
    customer: [],
  }

  // Filter each array using the valid indices
  Object.keys(data).forEach((key) => {
    filteredData[key] = validIndices.map(i => data[key][i])
  })
  return filteredData
}

function processMetricArguments(
  numeratorMetric: string,
  denominatorMetric: string | null,
): [string, string] {
  const processedNumerator = numeratorMetric.replace(/\s+/g, '_').toLowerCase()
  const processedDenominator = denominatorMetric
    ? denominatorMetric.replace(/\s+/g, '_').toLowerCase()
    : ''

  return [processedNumerator, processedDenominator]
}

function calculateRollingSums(data: MockReportingData, horizons: number[], metrics: string[]) {
  const result = { ...data }

  horizons.forEach((horizon) => {
    const suffix = `_rolsum_${horizon}`

    metrics.forEach((metric) => {
      const newMetricName = `${metric}${suffix}`
      result[newMetricName] = []

      // Calculate rolling sum for each regime separately
      const regimeSet = [...new Set(data.regime)]

      regimeSet.forEach((regimeValue) => {
        // Get indices for current regime
        const regimeIndices = data.regime.map((r, i) => r === regimeValue ? i : -1).filter(i => i !== -1)

        regimeIndices.forEach((currentIndex) => {
          // Calculate sum for window
          const windowStart = Math.max(
            regimeIndices[0],
            currentIndex - horizon + 1,
          )

          const sum = data[metric as keyof MockReportingData]
            .slice(windowStart, currentIndex + 1)
            .reduce((acc, val) => Number(acc) + Number(val), 0)

          result[newMetricName][currentIndex] = sum
        })
      })
    })
  })

  return result
}

function calculateRatios(data: MockReportingData, horizons: number[], metricPairs: [string, string, string][]) {
  const result = { ...data }

  horizons.forEach((horizon) => {
    // Set up suffixes based on horizon
    let numeratorSuf = ''
    let denominatorSuf = ''
    let resultSuf = ''
    if (horizon !== 0) {
      numeratorSuf = `_rolsum_${horizon}`
      denominatorSuf = `_rolsum_${horizon}`
      resultSuf = `_rolling_${horizon}`
    }

    // Calculate ratios for each metric pair
    metricPairs.forEach(([numerator, denominator, metricName]) => {
      const nomName = `${numerator}${numeratorSuf}`
      const denName = `${denominator}${denominatorSuf}`
      const resultName = `${metricName}${resultSuf}`

      // Calculate ratio and handle division by zero
      result[resultName] = ratioNanToZero(
        result[nomName] as number[],
        result[denName] as number[],
      )
    })
  })

  return result
}

function ratioNanToZero(numerator: number[], denominator: number[]): number[] {
  return numerator.map((num, index) => {
    const den = denominator[index]
    return den === 0 ? 0 : num / den
  })
}

interface JoinedData {
  rep_dt: string[]
  [key: string]: (string | number)[]
}

function joinRegimeData(
  data: MockReportingData,
  regimes: string[] | null = null,
  comparisonGroup: string,
): JoinedData {
  // Debug logs

  // Set default regimes if none provided
  if (!regimes) {
    regimes = [...new Set(data.regime)]
  }

  const comparisonRegime = comparisonGroup

  // Filter data for offerfit and comparison regimes
  const offerfitData = {
    indices: [] as number[],
    dates: [] as string[],
  }
  const comparisonData = {
    indices: [] as number[],
    dates: [] as string[],
  }

  // Collect all indices and dates for each regime
  data.regime.forEach((r, i) => {
    const date = data.dates[i]
    if (r === 'offerfit') {
      offerfitData.indices.push(i)
      offerfitData.dates.push(date)
    }
    if (r === comparisonRegime) {
      comparisonData.indices.push(i)
      comparisonData.dates.push(date)
    }
  })

  // Create joined data structure
  const joined: JoinedData = {
    rep_dt: [],
  }

  // Get all unique dates
  const uniqueDates = new Set([...data.dates])
  joined.rep_dt = [...uniqueDates].sort((a, b) => {
    const dateA = new Date(a)
    const dateB = new Date(b)
    return dateA.getTime() - dateB.getTime()
  })

  // Add data for each metric
  Object.keys(data).forEach((key) => {
    if (key !== 'dates' && key !== 'regime') {
      const metricData = data[key] as number[]

      // Create offerfit version
      joined[`${key}_offerfit`] = joined.rep_dt.map((date) => {
        const dateIndex = data.dates.findIndex((d, i) =>
          d === date && data.regime[i] === 'offerfit',
        )
        return dateIndex === -1 ? 0 : metricData[dateIndex]
      })

      // Create comparison version
      joined[`${key}_comparison`] = joined.rep_dt.map((date) => {
        const dateIndex = data.dates.findIndex((d, i) =>
          d === date && data.regime[i] === comparisonRegime,
        )
        return dateIndex === -1 ? 0 : metricData[dateIndex]
      })
    }
  })

  return joined
}

function calculateUplift(
  data: JoinedData,
  horizons: number[],
  metrics: string[],
): JoinedData {
  const result = { ...data }

  horizons.forEach((horizon) => {
    // Set up suffix based on horizon
    const horizonSuf = horizon !== 0 ? `_rolling_${horizon}` : ''

    metrics.forEach((metric) => {
      const offerfitMetric = `${metric}${horizonSuf}_offerfit`
      const comparisonMetric = `${metric}${horizonSuf}_comparison`
      const upliftMetric = `uplift_${metric}${horizonSuf}`

      // Calculate uplift as (offerfit - comparison) / comparison
      result[upliftMetric] = ratioNanToZero(
        result[offerfitMetric].map((val: number, i: number) =>
          val - (result[comparisonMetric][i] as number),
        ),
        result[comparisonMetric] as number[],
      )
    })
  })

  return result
}

function aggregateData(data: number[], aggregation: Aggregation): number {
  switch (aggregation) {
    case Aggregation.SUM:
      return data.reduce((acc, val) => acc + val, 0)
    case Aggregation.MEAN:
    { const sum = data.reduce((acc, val) => acc + val, 0)
      return data.length > 0 ? sum / data.length : 0 }
    case Aggregation.COUNT_DISTINCT:
      // Get unique values and count them
      return [...new Set(data)].length
    case Aggregation.NONE:
      // Return the last value in the array, or 0 if empty
      return data.length > 0 ? data[data.length - 1] : 0
  }
}

/**
 * Safely divides two numbers, returning 0 if denominator is 0
 */
function safeDivide(numerator: number, denominator: number): number {
  return denominator === 0 ? 0 : numerator / denominator
}

/**
 * Calculate statistical significance for the given metric using a z-test.
 *
 * This function computes the p-value for the difference between
 * two proportions (OfferFit vs. comparison). It uses a pooled proportion
 * estimate for the calculation.
 * If one of the denominators is 0 it returns a p-value of 0.5, as it means
 * maximum uncertainty.
 *
 * @param numOfferfit - The numerator of the KPI for OfferFit
 * @param numComp - The numerator of the KPI for the comparison
 * @param denOfferfit - The denominator of the KPI for OfferFit
 * @param denComp - The denominator of the KPI for the comparison
 * @returns The statistical significance value, between 0 and 1 or null if encountered an error
 */
function calculateStatisticalSignificance(
  numOfferfit: number,
  numComp: number,
  denOfferfit: number,
  denComp: number,
): number | null {
  if (denOfferfit === 0 || denComp === 0) {
    return null
  }

  const valueOfferfit = safeDivide(numOfferfit, denOfferfit)
  const valueComp = safeDivide(numComp, denComp)
  const pPooled = safeDivide(numOfferfit + numComp, denOfferfit + denComp)
  const squaredStandardError
    = pPooled * (1 - pPooled) * (safeDivide(1, denOfferfit) + safeDivide(1, denComp))

  // This means that the proportions or ratios are above 1, which is not possible
  if (squaredStandardError <= 0) {
    return null
  }

  const standardError = Math.sqrt(squaredStandardError)
  const zScore = safeDivide(valueOfferfit - valueComp, standardError)

  // Calculate p-value using the complementary error function
  // This is equivalent to 1 - CDF of the normal distribution
  const pValue = 1 - 0.5 * (1 + erf(Math.abs(zScore) / Math.sqrt(2)))

  return pValue
}

/**
 * Error function implementation for standard normal distribution
 * This is used to calculate the cumulative distribution function (CDF)
 */
function erf(x: number): number {
  // Constants
  const a1 = 0.254829592
  const a2 = -0.284496736
  const a3 = 1.421413741
  const a4 = -1.453152027
  const a5 = 1.061405429
  const p = 0.3275911

  // Save the sign of x
  const sign = x < 0 ? -1 : 1
  x = Math.abs(x)

  // A&S formula 7.1.26
  const t = 1.0 / (1.0 + p * x)
  const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x)

  return sign * y
}
