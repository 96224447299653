<script setup lang="ts">
interface Props {
  isSelected: boolean
  value: string
  label: string
  helperText?: string
  classNames?: string
}

withDefaults(defineProps<Props>(), { classNames: '', })
const emit = defineEmits<{
  (e: 'updateIsSelected', isSelected: boolean): void
}>()

function onToggleSelected() {
  emit('updateIsSelected', !__props.isSelected)
}
</script>

<template>
  <div
    :class="`flex gap-2 w-full rounded-md h-10 px-4 py-2 border-gray-300 border-1 ${classNames}`"
  >
    <NRadio
      :checked="isSelected"
      :value="value"
      @update:checked="onToggleSelected"
    />
    <p class="truncate text-sm">{{ label }}</p>
    <p class="text-sm text-gray-500">{{ helperText }}</p>
  </div>
</template>
