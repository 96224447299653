<script setup lang="ts">
import { useExperimenterVariantsFormsStore } from '@/level4/stores/useExperimenterVariantsFormsStore'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'

const store = useExperimenterVariantsFormsStore()
const {
  toneGuidanceFeedback,
  generatedCtaVariants,
  generatedSubjectLinesVariants,
} = storeToRefs(store)

const localToneGuidance = ref<string | undefined>('')

syncRefs(toneGuidanceFeedback, localToneGuidance, { immediate: true })

defineExpose<FormExposedProperties>({
  submitHandler: () => {
    if (localToneGuidance.value?.trim() !== toneGuidanceFeedback.value?.trim()) {
      generatedSubjectLinesVariants.value = []
      generatedCtaVariants.value = []
    }
    toneGuidanceFeedback.value = localToneGuidance.value?.trim()
  },
})
</script>

<template>
  <WizardFormPageLayout containerSize="medium" headerSize="large" title="Before I suggest subject lines and CTAs, do you have any guidance?" subtitle="For example, you can share your preferences on tone or topics to avoid.">
    <div class="container" flex="~">
      <SimpleTextarea v-model="localToneGuidance" w="full" maxW="xs" label="Tone guidance" placeholder="No preferences -- just show me your ideas!" :rows="4" :maxLength="200" />
    </div>
  </WizardFormPageLayout>
</template>

<style scoped>
.container {
  @apply justify-center;
}
</style>
