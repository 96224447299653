<script setup lang="ts">
import Choose from './choose/Form.vue'
import { BauAudienceSteps } from '@/level4/data/forms'
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'

const formSteps = [
  {
    name: BauAudienceSteps.CHOOSE,
    component: Choose,
  },
]
</script>

<template>
  <div>
    <L4WizardContainer :steps="formSteps" />
  </div>
</template>
