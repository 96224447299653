export enum Level4Module {
  SIGN_UP = 'sign-up',
  TYPE = 'type',
  INTEGRATIONS = 'integrations',
  USE_CREDENTIALS = 'use-credentials',
  BRAZE = 'braze',
  SFMC = 'sfmc',
  KLAVIYO = 'klaviyo',
  TEST_EMAILS = 'test-emails',
  TEMPLATES_SELECTION = 'templates-selection',
  TEMPLATES = 'templates',
  CREATE_VARIANTS = 'create-variants',
  BAU_CHOOSE = 'bau-choose',
  BAU_CONFIGURE = 'bau-configure',
  FREQUENCY = 'frequency',
  DAYS_OF_WEEK = 'days-of-week',
  TIME = 'time',
  AUDIENCE = 'audience',
  LAUNCH_SFMC = 'launch-sfmc',
  LAUNCH_KLAVIYO = 'launch-klaviyo',
  GUARDRAILS = 'guardrails',
  CHOOSE_ELEMENTS = 'choose-elements',
}

export enum SignUpSteps {
  COMPANY_NAME = 'name',
  INDUSTRY = 'industry',
}

export enum TypeSteps {
  CHOOSE = 'choose',
}

export enum IntegrateSteps {
  PLATFORM_CONNECTION = 'connection',
}

export enum ReuseCredentialsSteps {
  DEFAULT = 'default',
}

export enum BrazeSteps {
  PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_URL = 'configure-url',
  PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_API = 'configure-api',
  PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_APP_ID = 'configure-appid',
  PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_CAMPAIGN = 'configure-campaign',
  PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_MESSAGE_VAR_ID = 'configure-messageid',
  PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_EMAIL_FROM = 'configure-email',
}

export enum SFMCSteps {
  PLATFORM_CONFIGURATION_SMFC_CONFIGURE_KEYS = 'configure-keys',
  PLATFORM_CONFIGURATION_SMFC_CLIENT_ID = 'client-id',
  PLATFORM_CONFIGURATION_SMFC_CONFIGURE_CLIENT_SECRET = 'configure-secret',
  PLATFORM_CONFIGURATION_SMFC_CONFIGURE_AUTH_BASE_URI = 'configure-auth',
  PLATFORM_CONFIGURATION_SMFC_CONFIGURE_REST_BASE_URI = 'configure-rest',
  PLATFORM_CONFIGURATION_SMFC_CONFIGURE_SOAP_BASE_URI = 'configure-soap',
}

export enum KlaviyoSteps {
  PLATFORM_CONFIGURATION_KLAVIYO_API = 'configure-api',
}

export enum TemplatesSteps {
  EMAILS = 'emails',
}

export enum TemplatesSelectionSteps {
  CHOOSE = 'choose',
}

export enum ChooseElementsSteps {
  CHOOSE_ELEMENTS = 'choose-elements',
}

export enum CreateVariantsSteps {
  TONE_GUIDANCE = 'tone-guidance',
  SELECT_SUBJECT_LINES = 'subject-lines',
  CTA_TEXT_SELECTION = 'cta-text-selection',
  HERO_IMAGE_SELECTION = 'hero-image-selection',
  CONFIRM = 'confirm',
}
export enum FrequencySteps {
  CHOOSE = 'choose',
}

export enum DaysSteps {
  CHOOSE = 'choose',
}

export enum AudienceSteps {
  CHOOSE = 'choose',
  PERCENT = 'percent',
}

export enum BauAudienceSteps {
  CHOOSE = 'choose',
  CHOOSE_CAMPAIGN = 'choose-campaign',
  CHOOSE_AUDIENCE = 'choose-audience',
}

export enum TimeSteps {
  TIMEZONE = 'timezone',
  TIME_OF_DAY = 'time-of-day',
}

export enum GuardRailSteps {
  CHOOSE = 'choose',
}

export enum TestEmailsSteps {
  VARIANTS = 'select-variants',
  EMAILS = 'select-emails',
  SUMMARY = 'summary',
}

export enum LaunchSFMCSteps {
  SFMC_DATA_QUERIES = 'sfmc-data-queries',
  SFMC_FINISH_JOURNEY = 'sfmc-finish-journey',
  SFMC_VALIDATE_RESULTS = 'sfmc-validate-results',
}

export enum LaunchKlaviyoSteps {
  KLAVIYO_FINISH_FLOW = 'klaviyo-finish-flow',
  KLAVIYO_VALIDATE_RESULTS = 'klaviyo-validate-results',
}
