<script lang="ts">
const LOADING_STRING: string = 'Loading...'
</script>
<script setup lang="ts">
import { CheckCircleIcon, ChevronRightIcon, ExclamationCircleIcon } from '@heroicons/vue/24/solid'
import { handlePortalError } from '@shared/utils/errorHandling.ts'
import type { DrawerProps, DropdownOption } from 'naive-ui'
import SimpleButton from '@shared/components/basic/SimpleButton.vue'
import DiffViewModal from '@shared/components/basic/modal/DiffViewModal.vue'
import { TOOLTIP, getTooltipContent } from '@assets/help/tooltips'
import {
  DAG_LIST_ROUTE,
  Environment,
  POLLING_DELAY,
  TASK_NAME,
  TASK_STATUS,
  UseCaseStatus,
} from '@shared/data/constants'
import { TASK_DISPLAY_NAME_MAP } from '@shared/data/mapAndLists'
import { useStore } from '@app/store'
import type { LogsMap } from '@shared/utils/Types'
import Global from '@shared/utils/global'
import UseCaseService from '@/usecases/services/UseCaseService.ts'
import ValidationResultsModal from '@/configuration/validation/ValidationResultsModal.vue'
import ValidationService from '@/deployments/services/ValidationService.ts'
import { usePermissions } from '@/auth/composables/usePermissions'

import { useTaskLogs } from '@/dags/composables/useTaskLogs'
import { useUseCases } from '@/usecases/composables/useUseCases'
import type { PipelineModel } from '@/pipelines/models/server/PipelineModel'
import type { UseCaseModelWithoutConfig } from '@/usecases/models/server/UseCaseModel.ts'
import DeploymentsService from '@/deployments/services/DeploymentsService'
import DeliverRecsTaskService from '@/dags/services/tasks/DeliverRecsTaskService'
import FilterAndAugmentRecsTaskService from '@/dags/services/tasks/FilterAndAugmentRecsService'
import K8TaskService from '@/dags/services/tasks/K8TaskService'
import PredictTaskService from '@/dags/services/tasks/PredictTaskService'


type ThemeOverrides = NonNullable<DrawerProps['themeOverrides']>

const themeOverrides: ThemeOverrides = {
  resizableTriggerColorHover: '#22BD98',
  bodyPadding: '0px',
} as ThemeOverrides

const store = useStore()
const { loadLastTaskLogs, jobHistoryItems, isActivityDrawerOpened } = useTaskLogs()
const { isAIEorAdminOrEp } = usePermissions()
const { usecases } = useUseCases()

const isValidationRequestPending = ref<boolean>(false)
const isMigrationRequestPending = ref<boolean>(false)
const statusNotificationProperties = ref<{ type: string, text: string } | undefined>()
const migrationItemName = ref<string>()
const diffUseCase = ref<string>()
const isDiffModalOpened = ref<boolean>(false)

const isValidationResultsModalOpened = ref<boolean>(false)
const validationResultsModalTitle = ref<string>('')
const validationResultsModalErrors = ref<string[]>([])

const failedOnlyEnabled: Ref<boolean> = ref<boolean>(false)

const useCases = computed<DropdownOption[]>(() =>
  (usecases.value || []).map((usecase: UseCaseModelWithoutConfig) => {
    return {
      label: `Use case: ${usecase.displayName || usecase.name}`,
      key: usecase.name,
    } as DropdownOption
  }),
)

const useCasesAndPipelines = computed<DropdownOption[]>(() => {
  return (usecases.value || []).map((usecase: UseCaseModelWithoutConfig) => {
    return {
      label: `Use case: ${usecase.displayName || usecase.name}`,
      key: `usecase_${usecase.name}`,
    } as DropdownOption
  }).concat(store.getters['pipeline/pipelines'].map((pipeline: PipelineModel) => {
    return {
      label: `Pipeline: ${pipeline.name}`,
      key: `pipeline_${pipeline.name}`,
    } as DropdownOption
  }))
})

const isTaskLogsLoading: Ref<boolean> = ref<boolean>(true)
const loadedLogs: Ref<LogsMap> = ref<LogsMap>({})
let intervalTimer: ReturnType<typeof setInterval>

async function loadData() {
  isTaskLogsLoading.value = true
  if (failedOnlyEnabled.value) {
    await loadLastTaskLogs(TASK_STATUS.FAILED)
  }
  else {
    await loadLastTaskLogs()
  }
  isTaskLogsLoading.value = false
}

watch(
  () => isActivityDrawerOpened.value,
  (value) => {
    if (value) {
      loadData()
      intervalTimer = setInterval(loadData, POLLING_DELAY)
    }
    else {
      clearInterval(intervalTimer)
    }
  },
)

const itemHeaderClickHandler: (data: { name: string, expanded: boolean }) => void = async ({ name, expanded }) => {
  if (loadedLogs.value[name] || !expanded) { return }

  try {
    loadedLogs.value[name] = LOADING_STRING
    const logLines: string[] = await K8TaskService.getTaskLogs(name)
    loadedLogs.value[name] = logLines.join('\n') || 'Empty'
  }
  catch (error: any) {
    Global.error(error.message)
  }
}

function failedToggleClickHandler() {
  failedOnlyEnabled.value = !failedOnlyEnabled.value
  loadData()
}

async function useCasePredictSelectHandler(key: string) {
  await PredictTaskService.startTask(key)
}

async function useCaseAugmentRecsSelectHandler(key: string) {
  await FilterAndAugmentRecsTaskService.startTask(key)
}

async function useCaseDeliverRecsSelectHandler(key: string) {
  await DeliverRecsTaskService.startTask(key)
}

async function handleValidation(prefix: string, clientName: string, pipelineName: string, itemName: string) {
  const response = prefix === 'pipeline'
    ? await ValidationService.validateCfg(clientName, itemName)
    : await ValidationService.validateCfg(clientName, pipelineName, itemName)

  if (!response?.data?.length) {
    statusNotificationProperties.value = { type: 'success', text: `'${itemName}' ${prefix} is valid!` }
    return true
  }
  else {
    validationResultsModalErrors.value = response.data
    validationResultsModalTitle.value = `Validation errors for '${itemName}' ${prefix}`
    isValidationResultsModalOpened.value = true
    return false
  }
}

async function handleMigration(prefix: string, itemName: string, pipelineName: string) {
  const migrationPromise = prefix === 'pipeline'
    ? DeploymentsService.migratePipeline(itemName, Environment.DRAFT, Environment.LIVE)
    : DeploymentsService.migrateUsecase(pipelineName, itemName, Environment.DRAFT, Environment.LIVE)

  if (prefix === 'usecase') {
    // set use case to live before the migration
    const usecase = (usecases.value || []).find((usecase: UseCaseModelWithoutConfig) => usecase.name === itemName)

    if (usecase && usecase.id && usecase.status !== UseCaseStatus.LIVE) {
      const payload = {
        status: UseCaseStatus.LIVE,
      }

      try {
        await UseCaseService.updateUseCase(usecase.id, payload)
      }
      catch (error: any) {
        handlePortalError(error, { defaultUserErrorText: 'Failed to update usecase status' })
        throw error
      }

      usecase.status = UseCaseStatus.LIVE
    }
  }

  await migrationPromise
  statusNotificationProperties.value = { type: 'success', text: `Successfully migrated the '${itemName}' ${prefix}` }
}

async function migrateUsecaseAndPipelineClickHandler(migrationItem: string) {
  const [prefix, ...nameParts] = migrationItem.split('_')
  migrationItemName.value = nameParts.join('_')

  if (!migrationItemName.value) {
    statusNotificationProperties.value = { type: 'error', text: 'Please select a use case or pipeline to migrate' }
    return
  }

  const clientName = store.getters['client/client'].name
  const pipelineName = store.getters['pipeline/pipeline']?.name

  try {
    isValidationRequestPending.value = true
    const isCfgValid = await handleValidation(prefix, clientName, pipelineName, migrationItemName.value)
    isValidationRequestPending.value = false

    if (isCfgValid) {
      isMigrationRequestPending.value = true
      await handleMigration(prefix, migrationItemName.value, pipelineName)
      isMigrationRequestPending.value = false
    }
  }
  catch (e: any) {
    console.error(e.message)
    statusNotificationProperties.value = { type: 'error', text: `There was an error processing the '${migrationItemName.value}' ${prefix}` }
  }
  finally {
    isValidationRequestPending.value = false
    isMigrationRequestPending.value = false
  }
}

async function validateUsecaseAndPipelineClickHandler(validationItem: string) {
  const clientName = store.getters['client/client'].name
  const pipelineName = store.getters['pipeline/pipeline']?.name
  const [prefix, ...nameParts] = validationItem.split('_')
  migrationItemName.value = nameParts.join('_')

  try {
    isValidationRequestPending.value = true
    await handleValidation(prefix, clientName, pipelineName, migrationItemName.value)
  }
  catch (e: any) {
    console.error(e.message)
    statusNotificationProperties.value = { type: 'error', text: `There was an error validating the '${migrationItemName.value}' ${prefix}` }
  }
  finally {
    isValidationRequestPending.value = false
  }
}

function openDiffModal(selectedUseCaseName: string) {
  diffUseCase.value = selectedUseCaseName
  isActivityDrawerOpened.value = false
  isDiffModalOpened.value = true
}

const hoveredJobHistoryItem = ref<string>()
</script>

<template>
  <NDrawer
    v-model:show="isActivityDrawerOpened"
    class="z-10"
    :defaultWidth="600"
    placement="right"
    resizable
    :themeOverrides="themeOverrides"
    maskClosable
    closeOnEsc
  >
    <NDrawerContent class="m-0" title="Logs" closable>
      <template v-if="isAIEorAdminOrEp">
        <BasicLink class="block border-b p-2 text-sm hover:underline" role="button" :to="{ name: DAG_LIST_ROUTE }" @click="isActivityDrawerOpened = false">
          Open DAG page
        </BasicLink>
        <NCollapse class="border-b" arrowPlacement="right">
          <NCollapseItem class="p-2" title="Run tasks">
            <div class="flex justify-evenly">
              <NDropdown trigger="click" :options="useCases" @select="useCasePredictSelectHandler">
                <SimpleButton primary text="Predict" />
              </NDropdown>
              <NDropdown trigger="click" :options="useCases" @select="useCaseAugmentRecsSelectHandler">
                <SimpleButton primary text="Augment recs" />
              </NDropdown>
              <NDropdown trigger="click" :options="useCases" @select="useCaseDeliverRecsSelectHandler">
                <SimpleButton primary text="Deliver recs" />
              </NDropdown>
            </div>
          </NCollapseItem>
          <NCollapseItem class="p-2 !mt-0" title="Run migration">
            <div class="justify-left flex">
              <div class="w-3/4 flex gap-2">
                <NDropdown trigger="click" :options="useCases" @select="openDiffModal">
                  <NTooltip :disabled="!!useCases.length">
                    <span>{{ getTooltipContent(TOOLTIP.RUN_MIGRATION_VIEW_DIFF_NO_USECASES).text }}</span>
                    <template #trigger>
                      <NButton
                        type="primary"
                        :disabled="!useCases.length"
                      >
                        View diff
                      </NButton>
                    </template>
                  </NTooltip>
                </NDropdown>
                <NDropdown
                  trigger="click"
                  :options="useCasesAndPipelines"
                  @select="migrateUsecaseAndPipelineClickHandler"
                >
                  <NTooltip :disabled="!!useCasesAndPipelines.length">
                    <span>{{ getTooltipContent(TOOLTIP.NO_USECASES_OR_PIPELINES).text }}</span>
                    <template #trigger>
                      <NButton
                        type="primary"
                        :disabled="!useCasesAndPipelines.length || isMigrationRequestPending || isValidationRequestPending"
                        :loading="isMigrationRequestPending"
                      >
                        Migrate
                      </NButton>
                    </template>
                  </NTooltip>
                </NDropdown>
                <NDropdown
                  trigger="click"
                  :options="useCasesAndPipelines"
                  @select="validateUsecaseAndPipelineClickHandler"
                >
                  <NTooltip :disabled="!!useCasesAndPipelines.length">
                    <span>{{ getTooltipContent(TOOLTIP.NO_USECASES_OR_PIPELINES).text }}</span>
                    <template #trigger>
                      <NButton
                        type="primary"
                        :disabled="!useCasesAndPipelines.length || isValidationRequestPending || isMigrationRequestPending"
                        :loading="isValidationRequestPending"
                      >
                        Validate
                      </NButton>
                    </template>
                  </NTooltip>
                </NDropdown>
              </div>
              <NAlert v-if="statusNotificationProperties" :type="statusNotificationProperties.type" class="ml-4 text-sm">
                {{ statusNotificationProperties.text }}
              </NAlert>
            </div>
          </NCollapseItem>
        </NCollapse>
      </template>

      <div class="p-4">
        <NFormItem class="mb-2" label="Failed Only" labelPlacement="left" :showFeedback="false">
          <NSwitch
            :value="failedOnlyEnabled"
            size="small"
            status="error"
            @update:value="failedToggleClickHandler"
          />
        </NFormItem>

        <NSpin :show="isTaskLogsLoading" style="min-height: 300px">
          <div v-if="jobHistoryItems !== undefined && !jobHistoryItems.length">
            <span>No job history exists for this client </span>
          </div>
          <NCard
            v-for="item in jobHistoryItems"
            :key="item.job_id"
            size="small"
            class="w-full cursor-pointer pt-3"
            hoverable
            @mouseenter="hoveredJobHistoryItem = item.job_id"
            @mouseleave="hoveredJobHistoryItem = undefined"
          >
            <template v-if="item.job_status !== TASK_STATUS.DELETED">
              <div class="flex justify-between">
                <div class="relative flex">
                  <NSpin
                    v-if="item.job_status === TASK_STATUS.RUNNING || item.job_status === TASK_STATUS.PENDING"
                    :strokeWidth="30"
                    :size="16"
                    class="mr-1 h-5 w-5 !relative !left-0 !top-0 !transform-none"
                  />
                  <ExclamationCircleIcon v-if="item.job_status === TASK_STATUS.FAILED" class="mr-1 h-5 w-5 text-yellow-500" />
                  <CheckCircleIcon v-else-if="item.job_status === TASK_STATUS.SUCCEEDED" class="mr-1 h-5 w-5 text-green-500" />
                  <span class="font-semibold">
                    {{ TASK_DISPLAY_NAME_MAP.get(item.pipeline_task) }}&nbsp;<em>{{ item.job_status.toLowerCase() }}</em>&nbsp;
                    <span v-if="item.asset"> for "{{ item.asset }}"</span>
                  </span>
                </div>
                <TimezonedDateLabel
                  :date="item.createdAt"
                />
              </div>

              <div class="ml-2">
                <div class="mb-1 text-sm text-gray-400">
                  {{ item.job_id }}
                </div>
                <div v-if="item.pipeline_task === TASK_NAME.VALIDATION">
                  <BasicLink
                    to="/reports/validation-results"
                    class="flex cursor-pointer text-link"
                    @click="isActivityDrawerOpened = false"
                  >
                    validation results
                  </BasicLink>
                </div>
                <div
                  v-if="
                    (item.pipeline_task === TASK_NAME.PREPROCESS || item.pipeline_task === TASK_NAME.PREPROCESS_MPARTICLE)
                      && item.job_status === TASK_STATUS.SUCCEEDED
                  "
                >
                  <BasicLink
                    :to="`/preprocessed-data/${item.asset}`"
                    class="flex cursor-pointer text-link"
                    @click="isActivityDrawerOpened = false"
                  >
                    preprocessed data
                  </BasicLink>
                </div>
                <div v-if="item.pipeline_task === TASK_NAME.ENGINEER_FEATURES">
                  <BasicLink
                    to="/feature-calculation"
                    class="flex cursor-pointer text-link"
                    @click="isActivityDrawerOpened = false"
                  >
                    view features
                  </BasicLink>
                </div>
                <div v-if="item.pipeline_task === TASK_NAME.FEATURE_STATISTICS">
                  <BasicLink
                    to="/feature-statistics"
                    class="flex cursor-pointer text-link"
                    @click="isActivityDrawerOpened = false"
                  >
                    view statistics
                  </BasicLink>
                </div>

                <NCollapse arrowPlacement="right" @item-header-click="itemHeaderClickHandler">
                  <NCollapseItem :key="item.job_id" title="" :name="item.job_id">
                    <template #header>
                      <span class="cursor-pointer text-link">view task log</span>
                    </template>
                    <template #arrow>
                      <ChevronRightIcon class="h-4 w-4 text-link" />
                    </template>
                    <NLog
                      trim
                      :rows="10"
                      :loading="loadedLogs[item.job_id] === LOADING_STRING"
                      :log="loadedLogs[item.job_id]?.toString() || 'Empty'"
                    />
                  </NCollapseItem>
                </NCollapse>
              </div>
            </template>
            <template #footer>
              <div
                class="w-full flex gap-2 justify-end min-h-6"
                :opacity="hoveredJobHistoryItem === item.job_id ? '100' : '0'"
              >
                <NTooltip>
                  GCP Logs
                  <template #trigger>
                    <a :href="item.gcp_log_deep_link" target="_blank">
                      <NButton size="tiny" quaternary>
                        <template #icon>
                          <div class="i-logos-google-cloud-platform" />
                        </template>
                      </NButton>
                    </a>
                  </template>
                </NTooltip>
              </div>
            </template>
          </NCard>
        </NSpin>
      </div>
    </NDrawerContent>
  </NDrawer>

  <DiffViewModal
    v-if="isDiffModalOpened"
    :diffUseCase="diffUseCase"
    :isOpened="isDiffModalOpened"
    @close="isDiffModalOpened = false"
  />

  <ValidationResultsModal
    v-if="isValidationResultsModalOpened"
    :title="validationResultsModalTitle"
    :isOpened="isValidationResultsModalOpened"
    :errors="validationResultsModalErrors"
    @close="isValidationResultsModalOpened = false"
  />
</template>

<style scoped>
:deep(.n-collapse-item__header) {
  padding: unset !important;
}
</style>
