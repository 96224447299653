<script setup lang="tsx">
import { maskUuid } from 'maskdata'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

const { experimenterFormState, setMarketingCampaignId } = useExperimenterFormState()

const regex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i

const currentBrazeCampaignId = ref('')
const existedBrazeCampaignId = ref('')
syncRefs(() => experimenterFormState.value?.brazeCampaignId, existedBrazeCampaignId, { immediate: true })

const isValidCampaignId = computed(() => regex.test(currentBrazeCampaignId.value))
const hasDataChanged = computed(() => currentBrazeCampaignId.value && experimenterFormState.value?.brazeCampaignId !== currentBrazeCampaignId.value)
const isFormDataFilled = computed(() => !!currentBrazeCampaignId.value)
const isDataComplete = computed(() => isValidCampaignId.value && isFormDataFilled.value)
const isStateDataNotEmpty = computed(() => !!existedBrazeCampaignId.value)

const hasInputBlurred = ref(false)

const computedMaskedCampaignId = computed(() => maskUuid(existedBrazeCampaignId.value, { maskWith: '*', unmaskedStartCharacters: 4, unmaskedEndCharacters: 4 }))

defineExpose<FormExposedProperties>({
  dataIsComplete: isDataComplete,
  skipIsAvailable: computed(() => isStateDataNotEmpty.value && !hasDataChanged.value),
  submitHandler: async () => {
    await setMarketingCampaignId(currentBrazeCampaignId.value)
  },
})
</script>

<template>
  <WizardFormPageLayout containerSize="small" headerSize="small" footerSize="large" supertitle="Let's connect OfferFit with Braze" title="Please create a new <span class='font-bold'>API campaign</span> in Braze, and share its campaign ID with me.">
    <template #thirdSection>
      <BasicLink
        href="https://app.tango.us/app/workflow/Creating-a-Braze-API-Email-Campaign-for-OfferFit-241fcc8a8fa24172b15dac6ec9f2154b"
        target="_blank"
        class="cursor-pointer text-link"
        withExternalIcon
      >
        How to create an API campaign for OfferFit and find its ID
      </BasicLink>
    </template>
    <form flex="~ col" gap="4" items="center" w="full">
      <BasicInput v-model="currentBrazeCampaignId" label="API campaign ID" :placeholder="computedMaskedCampaignId || 'paste your API Campaign ID here'" data-sentry-mask @blur="hasInputBlurred = true" />
      <p v-if="currentBrazeCampaignId && hasInputBlurred && !isValidCampaignId" class="text-center text-xs text-red-600">
        Invalid Campaign Id.
      </p>
    </form>
  </WizardFormPageLayout>
</template>

<style scoped>
</style>
