<script setup lang="ts">
import Credentials from './default/Form.vue'
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'
import { ReuseCredentialsSteps } from '@/level4/data/forms'

const steps = [
    {
        name: ReuseCredentialsSteps.DEFAULT,
        component: Credentials,
    },
]

</script>
<template>
  <div>
    <L4WizardContainer :steps="steps" />
  </div>
</template>
