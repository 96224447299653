<script setup lang="ts">
import type { PaginationProps } from 'naive-ui'
import { storeToRefs } from 'pinia'
import router from '@router/index'
import type { VariantsCartesianProduct } from '@/level4/utils/variants/variantsCartesianProduct'
import type { SingleVariantRow } from '@/level4/components/email-templates/EmailTemplatesConfirmVariantsTable.vue'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { useExperimenterVariantsFormsStore } from '@/level4/stores/useExperimenterVariantsFormsStore'
import { Level4Module } from '@/level4/data/forms'

const emit = defineEmits<{
  back: [void]
  submit: [void]
}>()

const checkedRowsIndexes = ref<number[]>([])

const experimenterVariantsFormStore = useExperimenterVariantsFormsStore()
const {
  isLoaded,
  templateHtml,
  selectedCTAHtmlElementIdentifiers,
  generatedVariants,
  calculatedTotalVariants,
} = storeToRefs(experimenterVariantsFormStore)

const currentGeneratedVariants = ref<VariantsCartesianProduct[]>([])

syncRefs(generatedVariants, currentGeneratedVariants, { immediate: true })

onMounted(async () => {
  await until(isLoaded).toBe(true)
  await experimenterVariantsFormStore.generateVariants()
})

const mappedLocalGeneratedVariantsCombinatoryList = computed(() => {
  return currentGeneratedVariants.value.map((variant, idx) => {
    return {
      id: idx,
      variantId: variant.id,
      subjectLineText: variant.subjectLine?.label,
      ctaText: variant.cta?.label,
      heroImageData: {
        imageUrl: variant.image,
      },
    } as SingleVariantRow
  })
})

const showEmailVariantPreviewModal = ref(false)
const selectedEmailVariant = ref<VariantsCartesianProduct>()

function previewVariantClickHandler(rowId: string) {
  selectedEmailVariant.value = currentGeneratedVariants.value.find(variant => variant.id === rowId)
  showEmailVariantPreviewModal.value = true
}

function deleteVariantClickHandler(rowId: string) {
  Object.assign(currentGeneratedVariants, currentGeneratedVariants.value.filter(variant => variant.id !== rowId))
}

const pagination = reactive<PaginationProps>({
  page: 1,
  pageSize: 10,
  showSizePicker: true,
  pageSizes: [10, 20, 50, 100],
  onChange: (page: number) => {
    pagination.page = page
  },
  onUpdatePageSize: (pageSize: number) => {
    pagination.pageSize = pageSize
    pagination.page = 1
  },
})

const replaceMap = computed(() => {
  const variantTypeIdentifierValueMap = {
    cta: {},
    image: {},
    subjectLine: selectedEmailVariant.value?.subjectLine?.label,
  }

  if (selectedCTAHtmlElementIdentifiers.value.cta) {
    variantTypeIdentifierValueMap.cta = {
      [selectedCTAHtmlElementIdentifiers.value.cta]: selectedEmailVariant.value?.cta?.label,
    }
  }

  if (selectedCTAHtmlElementIdentifiers.value.image) {
    variantTypeIdentifierValueMap.image = {
      [selectedCTAHtmlElementIdentifiers.value.image]: selectedEmailVariant.value?.image,
    }
  }

  return variantTypeIdentifierValueMap
})

const templateIdQuery = router.currentRoute.value?.params?.templateId

function editVariantsClickHandler() {
  if (calculatedTotalVariants.value === currentGeneratedVariants.value.length
    || (calculatedTotalVariants.value !== currentGeneratedVariants.value.length
    && window.confirm('If you navigate away from this page, you will lose your unsaved generated variants. Are you sure you want to continue?'))) {
    currentGeneratedVariants.value.splice(0)
    router.push({ name: Level4Module.CHOOSE_ELEMENTS, params: { templateId: templateIdQuery } })
  }
}

function useVariantsClickHandler() {
  emit('submit')
}

defineExpose<FormExposedProperties>({
  areControlsDisabled: true,
})
</script>

<template>
  <WizardFormPageLayout containerSize="full" headerSize="medium" :title="`Here are the ${currentGeneratedVariants?.length || ''} email variants you designed.`" subtitle="You can preview each variant you created.">
    <div class="top-actions-toolbar" flex="~" justify="between" gap="2" w="full">
      <div class="block-start" flex="~" gap="4" items="center">
        <div class="edit-variants-button">
          <NButton type="primary" quaternary strong @click="editVariantsClickHandler">
            Edit variants
            <template #icon>
              <div class="i-solar-pen-2-outline" />
            </template>
          </NButton>
        </div>
      </div>
      <div class="block-end">
        <NButton type="primary" strong @click="useVariantsClickHandler">
          Use {{ currentGeneratedVariants?.length || '★' }} variants
        </NButton>
      </div>
    </div>
    <div class="confirm-variants-table" w="full" mt="2">
      <EmailTemplatesConfirmVariantsTable v-model:checked-rows-indexes="checkedRowsIndexes" :data="mappedLocalGeneratedVariantsCombinatoryList" :pagination="pagination" @click:preview-variant="previewVariantClickHandler" @click:delete-variant="deleteVariantClickHandler" />
    </div>
    <EmailVariantPreviewModal v-model:show="showEmailVariantPreviewModal" :html="templateHtml" :replaceMap="replaceMap" />
  </WizardFormPageLayout>
</template>
