<script lang="ts" setup>
import { formatISO, parseISO } from 'date-fns'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'
import type { ExperimenterType } from '@/level4/models/server/FormStateModel'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'

const { experimenterFormState, updateSelectedTypeAndDuration } = useExperimenterFormState()
const currentType = ref<ExperimenterType>('evergreen')
const currentDuration = ref<[number, number] | undefined>(undefined)

const isStepCompleted = computed(() => {
  if (!currentType.value) {
    return false
  }

  if (currentType.value === 'evergreen') {
    return true
  }

  return !!currentDuration.value?.[0] && !!currentDuration.value?.[1]
})

watch(experimenterFormState, () => {
  if (!experimenterFormState.value || !experimenterFormState.value.type) {
    return
  }

  if (experimenterFormState.value.type) {
    currentType.value = experimenterFormState.value.type
  }

  if (experimenterFormState.value.type === 'calendared') {
    if (!experimenterFormState.value.startDate || !experimenterFormState.value.endDate) {
      currentDuration.value = undefined
    }
    else {
      const startDate = parseISO(experimenterFormState.value.startDate).getTime()
      const endDate = parseISO(experimenterFormState.value.endDate).getTime()
      currentDuration.value = [startDate, endDate]
    }
  }
}, { immediate: true, deep: true })

defineExpose<FormExposedProperties>({
  dataIsComplete: isStepCompleted,
  submitHandler: async () => {
    const durationRange = currentType.value === 'calendared'
      ? {
          startDate: formatISO(new Date(currentDuration.value[0])),
          endDate: formatISO(new Date(currentDuration.value[1])),
        }
      : {
          startDate: null,
          endDate: null,
        }
    await updateSelectedTypeAndDuration({ type: currentType.value, ...durationRange })
  },
})
</script>

<template>
  <WizardFormPageLayout containerSize="small" headerSize="medium" title="Which of these best describes your experimenter?" subtitle="This setting cannot be changed once you launch your experimenter">
    <RadioCard
      :isChecked="currentType === 'evergreen'"
      value="evergreen"
      name="evergreen"
      label="Evergreen"
      description="Longer term campaign or evergreen journey. You can still launch or stop these experimenters at any time."
      @change="(e: Event) => currentType = (e.target as HTMLInputElement).value as ExperimenterType"
    />
    <RadioCard
      :isChecked="currentType === 'calendared'"
      value="calendared"
      name="calendared"
      label="Calendared"
      description="Short-term campaign you can schedule ahead of time. OfferFit recommends using this option for campaigns that will last two weeks or less."
      @change="(e: Event) => currentType = (e.target as HTMLInputElement).value as ExperimenterType"
    />
    <NDatePicker v-model:value="currentDuration" :disabled="currentType !== 'calendared'" type="daterange" clearable />
  </WizardFormPageLayout>
</template>
