<script setup lang="ts">
import VLazyImage from 'v-lazy-image'
import MasonryWallComponent from '@yeger/vue-masonry-wall'
import {
  getBunnyPlaceholderImage,
  getBunnySrcSet,
  getSrcSetSizes,
} from '@/level4/utils/thumbnails/bunnyImageOptimization'
import { LoadingStatus } from '@/level4/composables/useExperimenterFormState'

interface EmailTemplateGridItem {
  id: string
  thumbnailUrl: string
  templateTitle: string
}

interface Props {
  emailTemplates: EmailTemplateGridItem[]
  loading: string
}

withDefaults(defineProps<Props>(), {  })

const checkedEmailTemplatesIds = defineModel<string[]>('checkedEmailTemplatesIds', {
  required: true,
})

function toggleEmailTemplateSelection(templateId: string) {
  if (checkedEmailTemplatesIds.value?.includes(templateId)) {
    checkedEmailTemplatesIds.value = checkedEmailTemplatesIds.value?.filter(id => id !== templateId)
  }
  else {
    checkedEmailTemplatesIds.value = [...checkedEmailTemplatesIds.value, templateId]
  }
}

function templateIsSelected(templateId: string) {
  return checkedEmailTemplatesIds.value?.includes(templateId)
}

const erroredImagesTemplateIdMap = ref<Map<string, boolean>>(new Map())
</script>

<template>
  <div class="email-templates-select-grid">
    <div class="email-templates-grid">
      <div v-if="loading === LoadingStatus.LOADING" class="loading-skeleton" flex="~" justify="start" gap="4">
        <NSkeleton v-for="n in 4" :key="n" class="loading-skeleton-item" :sharp="false" width="180px" height="320px" />
      </div>
      <div v-else-if="loading === LoadingStatus.FAILED" class="loading-skeleton" flex="~" justify="start" gap="4">
        <p>Failed to load any templates for this campaign</p>
      </div>
      <MasonryWallComponent v-else :items="emailTemplates" :ssrColumns="4" :columnWidth="200" :gap="16">
        <template #default="{ item: emailTemplate }">
          <div
            v-if="emailTemplate" :key="emailTemplate.id" class="email-template-wrapper" position="relative"
            rounded="md" overflow="hidden" cursor="pointer" flex="~ col"
            :border="templateIsSelected(emailTemplate.id) ? '1 offerfit-bright-purple' : '1 gray-400 hover:1 hover:offerfit-bright-purple'"
            @click="toggleEmailTemplateSelection(emailTemplate.id)"
          >
            <NCheckbox :checked="templateIsSelected(emailTemplate.id)" position="absolute" top="2" left="2" />
            <div class="thumbnail-wrapper" w="full">
              <div
                v-if="erroredImagesTemplateIdMap.get(emailTemplate.id)" class="errored-image-fallback" bg="gray-100"
                h="[200px]" w="full" flex="center col" text="gray-400"
              >
                <div class="i-solar-gallery-remove-linear">
                  <p>No image</p>
                </div>
              </div>
              <VLazyImage
                v-else :srcset="getBunnySrcSet(emailTemplate.thumbnailUrl)"
                :srcPlaceholder="getBunnyPlaceholderImage(emailTemplate.thumbnailUrl)"
                :sizes="getSrcSetSizes()" h="full" w="full" object="cover" select="none"
                @error="erroredImagesTemplateIdMap.set(emailTemplate.id, true)"
              />
            </div>
            <div class="thumbnail-title" p="2" text="sm" font="bold" min="h-10" truncate>
              {{
                emailTemplate.templateTitle
              }}
            </div>
          </div>
        </template>
      </MasonryWallComponent>
    </div>
  </div>
</template>

<style scoped>
.v-lazy-image-loading {
  filter: blur(10px);
  transition: filter 0.7s;
}

.v-lazy-image {
  transition: filter 0.7s;
}
</style>
