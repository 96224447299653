import type { CreateAxiosDefaults } from 'axios'
import axios, { CanceledError } from 'axios'
import { store } from '@app/store'
import type { CacheAxiosResponse, InternalCacheRequestConfig } from 'axios-cache-interceptor'
import MockAdapter from 'axios-mock-adapter'
import type { AirflowDagGridDataModelType } from '@/dags/models/airflow/AirflowDagGridDataModel'
import type { AirflowDagListResponse } from '@/dags/models/airflow/AirflowDagModel'

const location = window.location
const API_PREFIX = 'api'

export const transactionId = Math.random().toString(36).slice(2, 9)
export const isWriteRequestPending = ref(false)

function isNetworkError(error: any) {
  return !!error.isAxiosError && !error.response
}

export function getApiPath() {
  const host = location.host || 'localhost:8080'
  if (import.meta.env.VITE_API_HOST) {
    return import.meta.env.VITE_API_HOST as string
  }

  if (host.startsWith('vercel')) {
    return `${location.protocol}//api.${host.replace('vercel.', '')}/${API_PREFIX}`
  }

  return `${location.protocol}//api.${host}/${API_PREFIX}`
}

export const mockAPIEnabled = import.meta.env.VITE_MOCK === 'true' // add VITE_MOCK to .env to make it work (see .env.example)

export const httpClientData: CreateAxiosDefaults = {
  baseURL: getApiPath(),
  withCredentials: true,
  headers: {
    'X-Transaction-ID': transactionId,
  },
}

if (import.meta.env.VITE_PERSONAL_STRAPI_TOKEN) {
  httpClientData.headers = {
    Authorization: `bearer ${import.meta.env.VITE_PERSONAL_STRAPI_TOKEN as string}`,
  }
}

export function requestInterceptor(request: InternalCacheRequestConfig<any, any>) {
  // @ts-expect-error
  const ignoreReadOnly = request.ignoreReadOnly
  const isWriteRequest = ['post', 'put', 'patch', 'delete'].includes(request.method as string)
  if (
    request
    && !ignoreReadOnly
    && store.getters.isReadonlyMode
    && isWriteRequest
  ) {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    request.cancelToken = source.token
    source.cancel('No permission to make this request')
  }

  isWriteRequestPending.value = isWriteRequest

  return request
}

export function responseInterceptor(response: CacheAxiosResponse<any, any>) {
  isWriteRequestPending.value = false
  return response
}

export function responseInterceptorError(error: any) {
  let errorData = error.message
  let errorObject = {}
  isWriteRequestPending.value = false
  // The request was made and the server responded with a status code
  // that falls out of the range of 2xx
  if (error?.response?.data?.detail) {
    errorData = error.response?.data?.detail
  }
  else if (error?.response?.data?.error?.message) {
    errorData = error.response.data.error.message
  }
  else if (error?.response?.data?.error_code) { // we expect that this field is in EventsHub response
    errorData = error.response.data.error_msg
    errorObject = {
      error_code: error.response.data.error_code,
      error_msg: error.response.data.error_msg,
    }
  }
  else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    errorData = error.request.toString()
  }

  if (!axios.isCancel(error) && error.code !== 'ERR_CANCELED') {
    if (error?.response?.status === 410) {
      // Global.showVersionUpdateRequest()
      return
    }

    if (isNetworkError(error)) {
      console.error('Please check the connection', error)
      return
    }

    const errorToThrow: any = new Error(errorData)
    errorToThrow.data = errorObject
    throw errorToThrow
  }
  else {
    throw new CanceledError()
  }
}

export async function initializeMockAPI() {
  if (mockAPIEnabled) {
    ;await (async () => {
      const mock = new MockAdapter(apiClient, { onNoMatch: 'passthrough' })

      const brinksDags = (await import('../../mock/json/brinks/brinks_dags').then(
        module => module.default,
      )) as unknown as AirflowDagListResponse

      const brinksDagGridData = (await import('../../mock/json/brinks/brinks_dag_tasks').then(
        module => module.default,
      )) as unknown as AirflowDagGridDataModelType

      mock.onGet('/airflow/dags').reply(200, brinksDags)
      mock.onGet(/\/airflow\/grid_data\/.*/).reply(200, brinksDagGridData)
    })()
  }
}
