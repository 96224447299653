<script setup lang="ts">
import { useExperimenterVariantsFormsStore } from '@/level4/stores/useExperimenterVariantsFormsStore'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { PLATFORM_DISPLAY_NAME } from '@/level4/data/l4_constants'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState';

const { experimenterFormState, loadImages } = useExperimenterFormState()
const experimenterVariantsStore = useExperimenterVariantsFormsStore()

const { selectedImagesForVariants } = storeToRefs(experimenterVariantsStore)

const currentSelectedImagesForVariants = ref<string[]>([])

syncRefs(selectedImagesForVariants, currentSelectedImagesForVariants, { immediate: true })

onMounted(async () => {
  await loadImages()
})


const availableImages = computed(() => {
  return experimenterFormState.value?.importedImages.concat(experimenterFormState.value?.linkedImages)
})

defineExpose<FormExposedProperties>({
  dataIsComplete: computed(() => !!currentSelectedImagesForVariants.value?.length),
  submitHandler: async () => {
    await experimenterVariantsStore.updateSelectedImagesForVariants(currentSelectedImagesForVariants.value)
  },
})
</script>

<template>
  <WizardFormPageLayout
    container-size="large"
    title="Let's choose hero image variants."
    :subtitle="`I found these images in your ${PLATFORM_DISPLAY_NAME[experimenterFormState.value?.platform || '']} account. Select the ones you'd like to use. If you want to upload more, please go to ${PLATFORM_DISPLAY_NAME[experimenterFormState.value?.platform || '']} and refresh this screen.`"
  >
    <div class="hero-images-list" w="full">
      <div v-if="!availableImages?.length" flex="~" justify="center">
        <NSpin size="large" />
      </div>
      <SelectableImagesGrid
        v-else
        v-model:selected-images-urls="currentSelectedImagesForVariants"
        :images="availableImages"
      />
    </div>
  </WizardFormPageLayout>
</template>

<style scoped>
</style>
