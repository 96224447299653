<script setup lang="ts">
import type { DataTableColumns } from 'naive-ui'
import { NButton, NTag } from 'naive-ui'
import TableEditableTextarea from '@shared/components/basic/TableEditableTextarea.vue'

interface SubjectLineListRow {
  id: string
  label: string
  tags: string[]
}

interface Props {
  data: SubjectLineListRow[]
  loading: boolean
  tokenizer: { regexp: RegExp, replacer?: string }
}

withDefaults(defineProps<Props>(), {  })

const emit = defineEmits<{
  delete: [id: string]
  updateRow: [id: string, label: string]
}>()

const editingRowId = ref<string>()
function createColumns(): DataTableColumns<SubjectLineListRow> {
  return [
    {
      title: 'Subject line',
      key: 'label',
      render(row) {
        return h(TableEditableTextarea, {
          modelValue: row.label,
          showInput: editingRowId.value === row.id,
          ghost: true,
          tokenizerRegex: __props.tokenizer.regexp,
          tokenizerReplacer: __props.tokenizer.replacer,
          onBlur: (value) => {
            emit('updateRow', row.id, value)
            editingRowId.value = undefined
          },
        })
      },
    },
    {
      title: 'Tags',
      key: 'tags',
      render(row) {
        return h(
          'div',
          { flex: '~ wrap', gap: '1' },
          row.tags.map(element =>
            h(NTag, { round: true, bordered: false, size: 'small' }, { default: () => element }),
          ),
        )
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render(row) {
        return h('div', { flex: '~ gap-2 justify-end' }, [
          h(NButton, {
            type: 'primary',
            quaternary: true,
            size: 'small',
            circle: true,
            onClick: () => editingRowId.value = row.id,
          }, {
            icon: () => h('div', { class: 'i-solar-pen-2-outline' }),
          }),
          h(NButton, {
            type: 'primary',
            quaternary: true,
            size: 'small',
            circle: true,
            onClick: () => emit('delete', row.id),
          }, {
            icon: () => h('div', { class: 'i-solar-trash-bin-trash-outline' }),
          }),
        ])
      },
    },
  ]
}
</script>

<template>
  <div class="subject-lines-list-wrapper">
    <NDataTable
      :data="data"
      :columns="createColumns()"
      :bordered="false"
      :loading="loading"
    />
  </div>
</template>

<style scoped>
:deep(.n-data-table-th) {
  @apply hidden;
}
</style>
