<script setup lang="ts">
import { getStandardizedString } from '@shared/utils/transformHelpers.ts'

interface GuardrailProps {
  periodOptions: Array<{
    label: string
    key: number
  }>
  timesOptions: Array<{
    label: string
    key: number
  }>
  period?: number
  times?: number
  invalidTimes?: boolean
  targetLabel: string
}

const props = defineProps<GuardrailProps>()

const emit = defineEmits(['update:selected', 'update:period', 'update:times', 'unselect'])

const selected = defineModel('selected', {
  type: Boolean,
  default: false,
})

const showDropdown = ref(false)

function handleSelectPeriod(option: {
  label: string
  key: number
}) {
  emit('update:period', option)
  emit('update:selected', true)
}

function handleSelectTime(option: {
  label: string
  key: number
}) {
  emit('update:times', option)
  emit('update:selected', true)
  showDropdown.value = false
}

function handleUnselect() {
  emit('unselect')
}

function getDisplayTextTime(value?: number) {
  return value
    ? value === 1
      ? `${value} time`
      : `${value} times`
    : 'select'
}

function getDisplayTextDays(value?: number) {
  return value
    ? value === 1
      ? `${value} day`
      : `${value} days`
    : 'select'
}

const targetLabelStandardized = computed(() => getStandardizedString(props.targetLabel))
</script>

<template>
  <NCard :segmented="{ content: true }" class="shadow-md" :data-test="`${targetLabelStandardized}-card`">
    <template #header>
      <div class="header-container" flex="~ wrap" items="center" gap="2">
        <div class="header-prefix" text="gray-300 sm">
          <div class="i-solar-letter-bold" text="brand-purple-light" />
        </div>
        <div class="header-text" flex="1" text="sm">
          {{ targetLabel }} send limits
        </div>
      </div>
    </template>
    <template #header-extra>
      <NTooltip>
        <template #trigger>
          <div class="i-solar-question-circle-outline" />
        </template>
        Set a limit on how often recipients can receive the same {{ targetLabel.toLowerCase() }}.
      </NTooltip>
    </template>
    <div class="flex flex-col">
      <div class="items-top mb-6 flex">
        <NRadio v-model:checked="selected" class="mr-2 mt-1" :data-test="`${targetLabelStandardized}-radio-selected`" />
        <div class="inline-block text-base">
          <span class="flex items-center">Within a period of
            <NDropdown trigger="click" :options="periodOptions" @select="handleSelectPeriod">
              <NTag :data-test="`${targetLabelStandardized}-period`" :class="{ checked: selected }" class="m-1" round :bordered="false">
                <span class="flex items-center gap-1">
                  {{ getDisplayTextDays(period) || 'select' }}
                  <div class="i-solar-alt-arrow-down-line-duotone" text="xs" />
                </span>
              </NTag>
            </NDropdown>
            a recipient should </span>
          <span class="flex items-center">
            not receive the same <b class="ml-1 mr-1">{{ targetLabel.toLowerCase() }}</b> more than
            <NDropdown :show="showDropdown" :onClickoutside="() => showDropdown = false" :scrollable="true" class="h-40" size="small" :options="timesOptions" @select="handleSelectTime">
              <NTooltip :disabled="!!period">
                <template #trigger>
                  <NTag :data-test="`${targetLabelStandardized}-times`" :disabled="!period" :class="{ checked: selected && !invalidTimes, invalid: invalidTimes }" :type="invalidTimes ? 'warning' : ''" class="m-1" round :bordered="false" @click="() => showDropdown = !showDropdown">
                    <span class="flex items-center gap-1">
                      {{ getDisplayTextTime(times) }}
                      <div class="i-solar-alt-arrow-down-line-duotone" text="xs" />
                    </span>
                    <template #icon>
                      <div v-if="invalidTimes" class="i-solar-danger-triangle-bold text-amber-500" text="xs" />
                    </template>
                  </NTag>
                </template>
                Please select a period first.
              </NTooltip>
            </NDropdown>
          </span>
        </div>
      </div>
      <NRadio :checked="!selected" :data-test="`${targetLabelStandardized}-radio-not-selected`" @click="handleUnselect">
        No restrictions on how often a recipient can receive the same <b>{{ targetLabel.toLowerCase() }}</b>
      </NRadio>
    </div>
  </NCard>
</template>

<style scoped>
.n-tag {
  &.checked {
    @apply font-bold text-brand-purple-bright border-brand-purple-bright bg-brand-purple-bright/15;
  }

  &.invalid {
    @apply text-black bg-amber-50;
  }
}
</style>
