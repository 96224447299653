<script setup lang="ts">
interface Props {
  images: string[]
}

withDefaults(defineProps<Props>(), {  })

const selectedImagesUrls = defineModel<string[]>('selectedImagesUrls', { required: true })

const allImagesSelected = ref(false)

function toggleImageSelection(url: string) {
  const idx = selectedImagesUrls.value?.indexOf(url)
  if (idx === -1) { selectedImagesUrls.value = [url, ...selectedImagesUrls.value ] }
  else { selectedImagesUrls.value?.splice(idx, 1) }
}

function imageIsSelected(url: string) {
  return selectedImagesUrls.value?.includes(url)
}

function toggleAllImagesSelection() {
  if (allImagesSelected.value) {
    selectedImagesUrls.value = __props.images
  }
  else {
    selectedImagesUrls.value = []
  }
}
</script>

<template>  
  <div class="selectable-images-grid-wrapper">
    <div class="top-actions" flex="~" justify="between" p="y-4">
      <NCheckbox v-model:checked="allImagesSelected" @update:checked="toggleAllImagesSelection">Select All</NCheckbox>
      <NButton quaternary type="primary" strong>Refresh
        <template #icon>
          <div class="i-solar-refresh-outline"></div>
        </template>
      </NButton>
    </div>
    <div class="images-grid">
      <div class="image-wrapper" v-for="(image) in images" :key="image" position="relative" aspect="1/1" :border="imageIsSelected(image) ? '2 offerfit-bright-purple' : '2 gray-400 hover:2 hover:offerfit-bright-purple'" :bg="imageIsSelected(image) ? 'offerfit-bright-purple/10' : ''" rounded="md" overflow="hidden" cursor="pointer" flex="~" items="center" justify="center" style="max-width: 200px;">
        <NCheckbox position="absolute" top="2" left="2" :checked="imageIsSelected(image)"></NCheckbox>
        <NImage class="zoom-area outline-none ring-0 focus:outline-none hover:outline-none focus:ring-0 hover:ring-0" src="/images/zoom-in.png" :preview-src="image"></NImage>
        <div class="bg-transparent w-full h-full absolute" z-index="99" @click="toggleImageSelection(image)"></div><img :src="image" loading="lazy" object="scale-down" select="none">
      </div>
    </div>
  </div>
</template>

<style scoped>
.images-grid {
  @apply grid gap-4 grid-auto-rows-a;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-flow: dense;
}

.image-wrapper {
  background-image: linear-gradient(45deg, #D2D2D2 25%, transparent 25%), linear-gradient(-45deg, #D2D2D2 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #D2D2D2 75%), linear-gradient(-45deg, transparent 75%, #D2D2D2 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;

  .zoom-area {
    border-radius: 0 !important;
    outline: 0 !important;
    border: 0 !important;
    --un-ring-width: 0 !important;
    --un-ring-offset-width: 0 !important;
    --un-ring-color: transparent !important;
    --un-ring-shadow: 0 !important;
    --un-ring-offset-shadow: 0 !important;
  }

  &:hover .zoom-area {
    opacity: 0.3;
  }
}

.zoom-area {
  z-index: 100;
  width: 60px;
  height: 100%;
  position: absolute;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.8 !important;
  }
}
</style>
