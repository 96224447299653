import type { EventChannel } from '@shared/data/constants'
import type { CustomerGroupType, DimensionName, NullString } from '@shared/utils/Types'
import type { UseCaseCfgFinalRecsFeatures } from '@/level4/utils/guardrails/utils.ts'
import type { INTEGRATION_PLATFORM } from '@/level4/data/l4_constants'
import type { AudienceLogicConfigModel } from '@/usecases/models/AudienceModel'
import type { CustomerGroupConfigModel } from '@/usecases/models/CustomerGroupModel'
import type { DimensionConfigModel } from '@/usecases/models/DimensionConfigModel'
import type { TypeEventMap } from '@/usecases/models/EventsModel'
import type { ExperiencesModel } from '@/usecases/models/ExperiencesModel'
import type { CommonFilter } from '@/filtering/models/FilterModel'
import type { GuardrailConfigModel } from '@/usecases/models/GuardrailModel'
import type { ConfigDimensionActionBank } from '@/usecases/models/server/ActionModel'
import type { SendProgressReportSendsModel } from '@/reporting/models/server/ReportingModel'

export type DimensionConfig = Record<string, DimensionConfigModel>

export interface GuardrailConfig {
  augmentation: {
    from_data_assets: any[]
    function_callbacks: any[]
  }
  filter_based: {
    comparators: CommonFilter[]
    logic: GuardrailConfigModel[]
  }
  comparators?: CommonFilter[]
  logic?: GuardrailConfigModel[]
}

export type DeliverRecFileFormat = 'csv' | 'csv.gz' | 'parquet'
export type DeliverRecStrategy = 'bucket' | 'api'

export interface DeliverRecsConfig {
  strategy: DeliverRecStrategy
  rec_file_format: DeliverRecFileFormat
}

export interface ActionAugmentations {
  [dimension: string]: string[]
}

export interface RecsAugmentationConfig {
  actions?: ActionAugmentations
  features?: string[]
}

export interface FilterAugmentRecsWrapper {
  recs_filter?: {
    total_cust_num?: number
    percents_map?: {
      [key: DimensionName]: number
    }
    customer_limit_map?: {
      [key: DimensionName]: number
    }
  }
  augmentation?: RecsAugmentationConfig
  delay_days_to_activation: number
}

export interface EventSequenceConfigModel {
  channel: EventChannel
  sequence: string[]
  required_event_for_conversion: string | undefined
}

export interface OfferfitGroupGuardrailsConfig {
  pandas_assignment_query?: 'fill'
  prediction_method: 'offerfit'
  guardrails: { [key: DimensionName]: string[] }
}

export type ExceptOfferfit = Omit<string, 'offerfit'>

export interface SendProgressReportConfigModel {
  staging_recipients: Array<string>
  sends: SendProgressReportSendsModel[]
}

export interface ScheduleActivationEventsModel {
  active: boolean
  config: {
    braze?: {
      api_campaign: {
        app_id: string
        email_from: string
        template_static: null | string
        template_dynamic: null | string
        message_variation_static: string
        message_variation_dynamic: null | string
      }
      campaign_static: string
      campaign_dynamic: null | string
      external_user_id: string
      custom_attributes: unknown
    }
    sfmc?: unknown // TODO
    klaviyo?: {
      flow: {
        // Klaviyo flows are triggered through API events
        // Only one of the 2 can be specified
        flow_api_event_dynamic: null | string
        flow_api_event_static: null | string // OFE_<USECASE>_API_EVENT_TRIGGER
        flow_data: {
          profile_id: string // "customer_id"
          rec_id: string // "rec_id"
          subject_line: string // "email_subject_text" If subject line is experimented on
          cta_text: string // "email_cta_text" If CTA is experimented on
          image_url: string // "email_image_url" # If hero image is experimented on
          activation_dt: string // "activation_time"
        }
      }

    }
    customer_id: string
    api_batch_size: number
    recs_batch_size: number
    recs_table_suffix: string
    activation_timezone: string
    activation_time_column: string
  }
  event_type: string
  target_platform: string
}

export interface ReportingSettingsModel {
  dates_to_exclude?: string[]
  start_date?: NullString
  data_delay_in_days?: number
  use_approximate_count_distinct?: boolean
}

export interface ConfigKPI {
  id?: string
  name?: string
  /**
   * @deprecated The field should not be used
   */
  left?: string
  /**
   * @deprecated The field should not be used
   */
  right?: string
  operation?: 'ratio' | 'numerator only' | string
  left_aggregation?: 'sum' | 'count distinct' | 'mean' | 'count' | null
  right_aggregation?: 'sum' | 'count distinct' | 'mean' | 'count'
  constant_multiplier?: string
  description?: string
  invert_colors_direction?: boolean
  internal_only?: boolean
  left_metric_id?: string
  right_metric_id?: string
}

export interface ConfigDriverTreeList {
  id: string
  name: string
  kpi_nodes: ConfigDriverTreeKpiNode[]
}
export interface ConfigDriverTreeKpiNode {
  kpi_id: string
  children: string
}

export interface ConfigMetric {
  id?: string
  name?: string
  /**
   * @deprecated The field should not be used
   */
  event?: string
  event_id?: string
  aggregation?: 'count' | 'sum' | 'min' | 'max' | 'count distinct' | 'mean'
  description?: string
  attributed?: boolean
  identifier?: 'customer_id' | 'rec_id'
  reporting_value?: string
}

export interface ReportingUsecaseSectionModel {
  kpis: ConfigKPI[]
  metrics: ConfigMetric[]
  driver_trees: ConfigDriverTreeList[]
}

export interface UseCaseConfigModel {
  path?: any
  actionBanks?: ConfigDimensionActionBank[]
  channels: EventChannel[]
  usecase_action_features?: UseCaseCfgFinalRecsFeatures
  prediction: {
    audience?: {
      filter: {
        comparators: any[]
        logic: AudienceLogicConfigModel[]
      }
    }
    regime: Partial<Record<CustomerGroupType, CustomerGroupConfigModel | OfferfitGroupGuardrailsConfig>>
    dimension: DimensionConfig
    guardrails: GuardrailConfig
    schedule: {
      shap_calculation_frequency: string
      daily_training: boolean
    }
    features_train_predict_omit: string[]
    no_intervention_group: NullString
    batch_size: number
  }
  training: {
    target_metric: {
      metric_name: NullString
    }
    secondary_enabled: boolean
    legacy_reward_calculation: boolean
    use_target_metric_as_reward: boolean
    nb_days_back_for_historical_average: number
    experiences: ExperiencesModel[]
    event_sequences: EventSequenceConfigModel[]
    attribution_type: TouchType
  }
  send_progress_report: SendProgressReportConfigModel
  events: TypeEventMap
  filter_and_augment_recs: FilterAugmentRecsWrapper
  deliver_recs: DeliverRecsConfig
  reporting_settings: ReportingSettingsModel
  reporting?: ReportingUsecaseSectionModel
  marketing_platform_connection?: {
    platform: INTEGRATION_PLATFORM | ''
    platform_params: {
      campaign_id?: string
      flow_name?: string
      flow_id?: string
      journey_id?: string
      journey_name?: string
      skip_template_upsert?: boolean
    }
  }
  schedule_activation_events?: ScheduleActivationEventsModel
  clientType?: 'ofe' | 'manual' | 'level4'
}

export type UseCaseConfigModelWithoutActionBank = Omit<UseCaseConfigModel, 'actionBanks'>

export enum TouchType {
  LAST_TOUCH = 'last',
  MULTI_TOUCH = 'all',
}
