<script setup lang="ts">
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState.ts'
import NameExternalKeyPairTable
  from '@/level4/views/modules/launch-sfmc/sfmc-data-queries/NameExternalKeyPairTable.vue'
import { useUseCaseConfig } from '@/usecases/composables/useUseCaseConfig'

const { experimenterFormState } = useExperimenterFormState()
const config = useUseCaseConfig()

const journeyName = computed<string>(() => {
  return config.localUseCaseConfig.value?.marketing_platform_connection?.platform_params?.journey_name || `OFE_${experimenterFormState.value?.name?.toUpperCase()}_JOURNEY`
})
</script>

<template>
  <p>
    1.1 Open Salesforce Marketing Cloud and find Journey Builder in the menu. Choose “Journey Builder” from the list of options.
    <NImage src="/integration-instructions/sfmc/launch-sfmc/finish-journey/01_open_journey_builder.png" />
  </p>
  <p>
    1.2 Select the Journey that matches the following name in the list.
    <NameExternalKeyPairTable :data="[{ name: journeyName }]" />
    <NImage src="/integration-instructions/sfmc/launch-sfmc/finish-journey/02_select_matching_journey.png" />
  </p>
</template>
