<script setup lang="ts">
import hljs from "highlight.js/lib/common";
import hljsVuePlugin from "@highlightjs/vue-plugin";
interface Props {
  content: string;
  language?: string;
}

withDefaults(defineProps<Props>(), {  })
const emit = defineEmits<{}>();
const HljsComponent = hljsVuePlugin.component

const { copy, copied, isSupported } = useClipboard()
</script>

<template>
  <div class="code-block-wrapper" relative="~">
    <div class="copy-button-wrapper" v-if="isSupported" absolute="~" top="4" right="4" z="1">
      <NButton type="primary" size="tiny" color="white" ghost @click="copy(content)">{{ copied ? 'Copied!' : 'Copy' }}
        <template #icon>
          <div class="i-solar-copy-bold-duotone"></div>
        </template>
      </NButton>
    </div>
    <div class="hljs-wrapper" relative="~" z="0">
      <HljsComponent :code="content" :language="language" autodetect z="0"></HljsComponent>
    </div>
  </div>
</template>

<style scoped>
.hljs-wrapper:deep(pre) {
  @apply p-0;
}
</style>
