<script setup lang="ts">
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'
import KlaviyoFinishFlow from './klaviyo-finish-flow/Form.vue'
import KlaviyoValidateResults from './klaviyo-validate-results/Form.vue'
import { LaunchKlaviyoSteps } from '@/level4/data/forms'

const formSteps = [
    {
        name: LaunchKlaviyoSteps.KLAVIYO_FINISH_FLOW,
        component: KlaviyoFinishFlow,
    },
    {
        name: LaunchKlaviyoSteps.KLAVIYO_VALIDATE_RESULTS,
        component: KlaviyoValidateResults,
    },
]
</script>

<template>
  <div>
    <L4WizardContainer :steps="formSteps" />
  </div>
</template>
