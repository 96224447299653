<script setup lang="ts">
export interface HelpStep {
  label: string
  content: VNode
}
interface Props {
  header?: string
  helpSteps: HelpStep[]
  introductoryStep?: HelpStep
  fullMode?: boolean
}

withDefaults(defineProps<Props>(), {  })

const selectedStep = ref(0)
const currentContent = computed(() => {
  if (selectedStep.value === -1) { return __props.introductoryStep?.content }
  else { return __props.helpSteps[selectedStep.value].content }
})
const [opened, toggleOpen] = useToggle(false)
const contentRef = ref<HTMLElement | null>(null)

onMounted(() => {
  if (__props.fullMode) {
    opened.value = true
  }
  if (__props.introductoryStep) {
    selectedStep.value = -1
  }
})

function handleNextStep() {
  if (selectedStep.value < __props.helpSteps.length - 1) {
    selectedStep.value++
  }
}

function handlePrevStep() {
  if (selectedStep.value > 0) {
    selectedStep.value--
  }
}
</script>

<template>
  <NCard ref="contentRef" w="full" embedded>
    <template #header>
      <div class="cursor-pointer p-1 text-sm font-normal hover:underline" @click="toggleOpen()">
        {{ header }}
      </div>
      <hr>
    </template>
    <div v-if="opened" class="multiple-steps-help" flex="~" gap="8">
      <aside w="60" min="w-60">
        <div v-if="introductoryStep" class="step-select" :class="selectedStep === -1 ? 'bg-gray-100 font-semibold' : ''" p="2" cursor="pointer" rounded="md" @click="selectedStep = -1">
          {{ introductoryStep.label }}
        </div>
        <div v-for="(step, index) in helpSteps" :key="index" class="step-select" :class="selectedStep === index ? 'bg-gray-100 font-semibold' : ''" p="2" cursor="pointer" rounded="md" @click="selectedStep = index">
          {{ index + 1 }}. {{ step.label }}
        </div>
      </aside>
      <main class="w-[calc(100%-17rem)]">
        <div class="max-w-full">
          <component :is="currentContent" />
        </div>
        <div class="toolbar flex justify-between gap-4 p-4">
          <NButton v-if="selectedStep > 0" type="primary" ghost @click="handlePrevStep">
            Prev. Step
          </NButton>
          <NButton v-if="selectedStep < helpSteps.length - 1" type="primary" @click="handleNextStep">
            Next Step
          </NButton>
        </div>
      </main>
    </div>
  </NCard>
</template>

<style scoped>
</style>
