import { getApiPath } from '@app/utils/common-http-client'

export default class AuthService {
  static getSignInLocation = () => {
    const apiPath = getApiPath()
    // Use one of the following methods to determine the preview branch.
    interface OfferfitPreviewBranchMethod { branch: string }
    interface PlatformPreviewMethod { platform_origin: string, platform_url: string }
    type UrlState = OfferfitPreviewBranchMethod | PlatformPreviewMethod | object

    let state: UrlState = {}

    const auth0Url = new URL(`${apiPath}/connect/auth0`)

    // Vercel preview branch.
    if (import.meta.env.VITE_VERCEL_ENV === 'preview') {
      const vercelBranchUrl = import.meta.env.VITE_VERCEL_BRANCH_URL || ''
      const vercelCommitUrl = import.meta.env.VITE_VERCEL_URL || ''
      const vercelUrl = location.host.includes('-git-')
        ? vercelBranchUrl
        : vercelCommitUrl
      state = {
        platform_origin: 'vercel',
        platform_url: vercelUrl,
      }
    }

    if (Object.keys(state).length > 0) {
      auth0Url.searchParams.set('state', JSON.stringify(state))
    }

    return auth0Url.toString()
  }
}
