<script lang="tsx">
const STATUS_VALIDATING = 'validating'
const STATUS_PASSED = 'passed'
const STATUS_FAILED = 'failed'
</script>
<script setup lang="tsx">
import { NButton } from 'naive-ui'
import router from '@router/index'
import type { RouteLocationRaw } from 'vue-router'
import SFMCValidationStatusTag from './SFMCValidationStatusTag.vue'
import { LaunchSFMCSteps, Level4Module, SFMCSteps } from '@/level4/data/forms'
import TokensMinimapSfmcLaunch from '@/level4/views/modules/launch-sfmc/TokensMinimapSfmcLaunch.vue'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'

import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

const props = defineProps<{
  stepName: string
}>()

export interface validationResponse {
  result: {
    journey: {
      status: boolean
      execution_mode: boolean
      api_event_trigger: boolean
      multi_criteria_decision: boolean
      email_template_nodes: boolean
    }
    base_data_extension: boolean
    engagement_data_extension: boolean
    recommendations_data_extension: boolean
  }
  error_code: string
  error_msg: string
}

interface ValidationTableDataModel {
  type: string
  status: 'passed' | 'failed' | 'validating'
  description: string
  how_to_fix: string
  how_to_fix_action_component: string
}

const { validateIntegration } = useExperimenterFormState()
const validationResponseData = ref<validationResponse>()
const loading = ref<boolean>(false)

onMounted(async () => {
  await setValidationData()
})

async function setValidationData() {
  loading.value = true
  validationResponseData.value = await validateIntegration()
  loading.value = false
}





function returnToButton(label: string, args: RouteLocationRaw) {
  return (
    <NButton quaternary type="primary" strong onClick={() => router.push(args)}>
      {{
        default: `Return to ${label}`,
        icon: <div class="i-solar-undo-left-round-bold" />,
      }}
    </NButton>
  )
}

const howToFixActionsComponentMap = {
  returnToFinishJourney: returnToButton('Finish Journey', {
    name: Level4Module.LAUNCH_SFMC,
    query: { step: LaunchSFMCSteps.SFMC_FINISH_JOURNEY },
  }),
  returnToPlatformConnection: returnToButton('Platform Connection', {
    name: Level4Module.SFMC,
    query: { step: SFMCSteps.PLATFORM_CONFIGURATION_SMFC_CLIENT_ID },
  }),
  returnSetupDataQueries: returnToButton('Set up data queries', {
    name: Level4Module.LAUNCH_SFMC,
    query: { step: LaunchSFMCSteps.SFMC_DATA_QUERIES },
  }),
  contactOfferfit: () => (
    <NButton quaternary type="primary" strong onClick={() => window.open('https://www.offerfit.io/contact-us')}>
      {{
        default: 'Contact OfferFit',
        icon: <div class="i-solar-question-circle-outline" />,
      }}
    </NButton>
  ),
}

const validationData = computed<ValidationTableDataModel[]>(() => {
  return [
    {
      type: 'Journey',
      description: 'Has been successfully published by the user.',
      status: loading.value ? STATUS_VALIDATING : validationResponseData.value?.result.journey.status ? STATUS_PASSED : STATUS_FAILED,
      how_to_fix: 'Return to the "Finish journey" step and ensure you have activated the journey.',
      how_to_fix_action_component: howToFixActionsComponentMap.returnToFinishJourney,
    },
    {
      type: 'Journey',
      description: 'Has been successfully provisioned in the Production mode.',
      status: loading.value ? STATUS_VALIDATING : validationResponseData.value?.result.journey.execution_mode ? STATUS_PASSED : STATUS_FAILED,
      how_to_fix: 'Please contact OfferFit for support.',
      // how_to_fix_action_component: howToFixActionsComponentMap.contactOfferfit,
    },
    {
      type: 'API Event',
      description: 'Has been successfully associated as the main trigger for the created Journey.',
      status: loading.value ? STATUS_VALIDATING : validationResponseData.value?.result.journey.api_event_trigger ? STATUS_PASSED : STATUS_FAILED,
      how_to_fix: 'Check API Event Trigger settings and ensure correct association.',
      how_to_fix_action_component: howToFixActionsComponentMap.returnToPlatformConnection,
    },
    {
      type: 'Multi-criteria decision node',
      description: 'Has been successfully configured with the right email template ID values.',
      status: loading.value ? STATUS_VALIDATING : validationResponseData.value?.result.journey.multi_criteria_decision ? STATUS_PASSED : STATUS_FAILED,
      how_to_fix: 'Verify email template IDs in multi-criteria decision node configuration.',
      how_to_fix_action_component: howToFixActionsComponentMap.returnToFinishJourney,
    },
    {
      type: 'Email nodes',
      description: 'Have been successfully configured with the required template ID values.',
      status: loading.value ? STATUS_VALIDATING : validationResponseData.value?.result.journey.email_template_nodes ? STATUS_PASSED : STATUS_FAILED,
      how_to_fix: 'Ensure all email nodes have the correct template ID values assigned.',
      how_to_fix_action_component: howToFixActionsComponentMap.returnToFinishJourney,
    },
    {
      type: 'Query and automation',
      description: 'Has been successfully configured and run once to populate OfferFit Base Data Extension.',
      status: loading.value ? STATUS_VALIDATING : validationResponseData.value?.result.base_data_extension ? STATUS_PASSED : STATUS_FAILED,
      how_to_fix: 'Run the automation for populating OfferFit Base Data Extension once.',
      // how_to_fix_action_component: howToFixActionsComponentMap.contactOfferfit,
    },
    {
      type: 'Query and automation',
      description: 'Has been successfully configured and run once to populate OfferFit Engagement Data Extension.',
      status: loading.value ? STATUS_VALIDATING : validationResponseData.value?.result.engagement_data_extension ? STATUS_PASSED : STATUS_FAILED,
      how_to_fix: 'Execute the process to populate the OfferFit Engagement Data Extension.',
      how_to_fix_action_component: howToFixActionsComponentMap.returnSetupDataQueries,
    },
    {
      type: 'OfferFit Recommendations Data Extension',
      description: 'Has been successfully provisioned by the OfferFit Portal.',
      status: loading.value ? STATUS_VALIDATING : validationResponseData.value?.result.recommendations_data_extension ? STATUS_PASSED : STATUS_FAILED,
      how_to_fix: 'Contact OfferFit support for provisioning issues.',
      how_to_fix_action_component: howToFixActionsComponentMap.returnSetupDataQueries,
    },
  ]
})

defineExpose<FormExposedProperties>({
  nextFormButtonText: 'Finish setup',
})
</script>

<template>
  <WizardFormPageLayout
    containerSize="full"
    title="The experimenter will be ready to go live after completing the last few SFMC steps."
  >
    <TokensMinimapSfmcLaunch :stepName="props.stepName" />
    <div class="re-run" flex="~" justify="end" p="2">
      <NButton
        secondary
        :loading="loading"
        @click="setValidationData"
      >
        <template #icon>
          <div class="i-solar-refresh-bold" />
        </template>
        Refresh
      </NButton>
    </div>
    <div class="validation-list" grid="~" gap="2">
      <div
        v-for="validationItem in validationData"
        :key="validationItem.description"
        class="validation-item"
      >
        <NCard
          rounded="!lg"
          :hoverable="validationItem.status === STATUS_FAILED"
          :embedded="validationItem.status === STATUS_PASSED"
          :segmented="{ content: true }"
        >
          <template #header>
            <div class="item-validation-wrapper" flex="~" gap="4" items="start" :opacity="validationItem.status === STATUS_PASSED ? '70' : '100'">
              <div
                flex="~ 1 col"
              >
                <h2 font="bold" text="sm" flex="~" gap="2" items="center">
                  <NBadge
                    dot
                    :type="validationItem.status === STATUS_PASSED ? 'success' : 'warning'"
                  />
                  {{ validationItem.type }}
                </h2>
                <p
                  font="normal"
                  text="sm capitalize"
                >
                  {{ validationItem.description }}
                </p>
              </div>
              <SFMCValidationStatusTag
                :status="validationItem.status"
              />
            </div>
          </template>
          <NCollapseTransition :show="validationItem.status === STATUS_FAILED">
            <div
              flex="~ wrap"
              gap="2"
              items="center"
              text="brand-purple-bright base"
            >
              <div class="left flex flex-1 gap-2">
                <div class="i-solar-sledgehammer-bold-duotone text-sm" />
                <span font="bold">How to fix?</span>
                <span font="regular">{{ validationItem.how_to_fix }}</span>
              </div>
              <component :is="validationItem.how_to_fix_action_component" v-if="validationItem.how_to_fix_action_component" />
            </div>
          </NCollapseTransition>
        </NCard>
      </div>
    </div>
  </WizardFormPageLayout>
</template>
