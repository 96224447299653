<script setup lang="ts">
interface Props {
    row: any
  }
const emit = defineEmits<{"update:template-description": [campaignTemplateId: string, description: string]}>()
const props = defineProps<Props>()
const description = ref<string>(props.row.description)
const debounced = useDebounce(description, 400)
watch(debounced, ()=>{
    emit('update:template-description', props.row.id, debounced.value)
    console.log('debounceee')
})
</script>
<template>
    <div class="grid gap-1">
        <span class="font text-md font-semibold">
            {{ row.title }}
        </span>
        <NInput
        class="override-ghost -ml-3"
        placeholder="Description"
        :value="description"
        :onInput="(_description) => description = _description"
            >
        </NInput>
    </div>
</template>