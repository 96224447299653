<script setup lang="ts">
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { PLATFORM_DISPLAY_NAME } from '@/level4/data/l4_constants'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'
import ResourcesToUseInstructions
  from '@/level4/views/modules/launch-klaviyo/klaviyo-finish-flow/ResourcesToUseInstructions.vue'

const { experimenterFormState, updateExperimenterFormState } = useExperimenterFormState()

const pageTitle = experimenterFormState.value?.platformNeedsUpdateFlag
  ? `You've made changes to your templates, please make sure you make the appropriate changes in ${PLATFORM_DISPLAY_NAME[experimenterFormState.value?.platform || '']}`
  : `Please complete a few last ${PLATFORM_DISPLAY_NAME[experimenterFormState.value?.platform || '']} steps <br/> and you’re ready to go live!`

defineExpose<FormExposedProperties>({
  submitHandler: async () => {
    await updateExperimenterFormState({ sfmcJourneySubmitClicked: true })
  },
  nextFormButtonText: 'Validate results',
})
</script>

<template>
  <WizardFormPageLayout
    containerSize="full"
    :title="pageTitle"
  >
    <template #thirdSection>
      <BasicLink
        href="https://app.tango.us/app/workflow/Creating-a-Klaviyo-Flow-for-OfferFit-Email-9e156d69fca4467bba2500c49dda0f85"
        target="_blank"
        class="cursor-pointer text-link"
        withExternalIcon
      >
        How to create a Klaviyo Flow for OfferFit?
      </BasicLink>
    </template>
    <ResourcesToUseInstructions />
  </WizardFormPageLayout>
</template>
