<script setup lang="ts">
import BlueInfoMessage from '@shared/components/basic/static/BlueInfoMessage.vue'
import { validateEmailString } from '@shared/utils/helpers'
import { useIntegrationsSync } from '@/level4/composables/useIntegrationsSync'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

const { experimenterFormState, setBrazeEmailFrom } = useExperimenterFormState()
const { createDataInVault } = useIntegrationsSync()

const displayName = ref('')
const returnEmail = ref('')

const isValidUrl = computed(() => validateEmailString(returnEmail.value))

const fullyDefinedEmail = computed(() => {
  if (displayName.value && isValidUrl.value) {
    const email = `${displayName.value} <${returnEmail.value}>`
    return email
  }
  return ''
})

watch(() => experimenterFormState.value?.brazeEmailFrom, (brazeEmailFrom) => {
  if (brazeEmailFrom) {
    const splitFullEmail = brazeEmailFrom.split('<')
    if (splitFullEmail?.length === 2) {
      displayName.value = splitFullEmail[0].trim()
      returnEmail.value = splitFullEmail[1].replace('>', '').trim()
    }
  }
}, { immediate: true })

// this step is the last in chain, so we should submit the data anyway. Because integration data could have changed.

defineExpose({
  dataIsComplete: computed(() => !!fullyDefinedEmail.value && fullyDefinedEmail.value.split('<').length === 2),
  submitHandler: async () => {
    await setBrazeEmailFrom(fullyDefinedEmail.value)
    await createDataInVault()
  },
})
</script>

<template>
  <WizardFormPageLayout containerSize="small" headerSize="small" footerSize="large" supertitle="One more step to complete the integration!" title="Please provide the <span class='font-bold'>display name</span> and <span class='font-bold'>email address</span> that customers will see.">
    <template #thirdSection>
      <BasicLink
        href="https://app.tango.us/app/workflow/Locating-your-Braze-email-display-name-and-From-address-02828b7089664594a69fa094d9678955"
        target="_blank"
        class="cursor-pointer text-link"
        withExternalIcon
      >
        How to find your Braze display name and from address
      </BasicLink>
    </template>
    <form flex="~ col" gap="4" items="center" w="full" max="w-sm" class="mb-6">
      <BasicInput v-model="displayName" label="From display name" placeholder="No Reply" />
      <p v-if="!displayName" class="text-xs text-red-600">
        The email address must have a display name
      </p>
      <BasicInput v-model="returnEmail" label="From email address" placeholder="no-reply@company.com" data-sentry-mask />
      <p v-if="!isValidUrl && returnEmail" class="text-xs text-red-600">
        The email address must have an '@' and should be
        managed by your company.
      </p>
      <p v-if="fullyDefinedEmail" class="text xs text-black:70">
        {{ fullyDefinedEmail }}
      </p>
    </form>
    <BlueInfoMessage>
      <div class="text-sm font-normal">
        The From display name and From email address you enter here must exactly match the Braze Display name address you'd like to use
      </div>
    </BlueInfoMessage>
  </WizardFormPageLayout>
</template>

<style scoped></style>
