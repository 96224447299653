<script setup lang="ts">
import Choose from './choose/Form.vue'
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'
import { FrequencySteps } from '@/level4/data/forms'

const formSteps = [
  {
    name: FrequencySteps.CHOOSE,
    component: Choose,
  },
]
</script>

<template>
  <div>
    <L4WizardContainer :steps="formSteps" />
  </div>
</template>
