<script setup lang="tsx">
import type { DataTableColumns } from 'naive-ui'
import CopyableContent from '@shared/components/basic/utilitites/CopyableContent.vue'
import { NImage } from 'naive-ui'
import { BaseEmailTemplate, useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'
import type { ImportedEmailTemplate } from '@/level4/composables/useExperimenterFormState'

interface BaseEmailEmailTemplatePairTableRow {
  baseEmail: {
    thumbnailUrl?: string
    name: string
  }
  emailTemplateId: string
}

const { experimenterFormState } = useExperimenterFormState()

const templatesTableData = computed<BaseEmailEmailTemplatePairTableRow[]>(() => {
  if (!experimenterFormState.value?.offerfitCreatedTemplates || !experimenterFormState.value?.currentImportedTemplates) return []

  return Object.entries(experimenterFormState.value?.offerfitCreatedTemplates)
    .filter(([key, value]: [string, BaseEmailTemplate]) => !value.deleted)
    .map(([key, value]: [string, BaseEmailTemplate]) => {
      const template = experimenterFormState.value?.currentImportedTemplates?.find((template: ImportedEmailTemplate) => template.id === key)
      return {
        baseEmail: {
          thumbnailUrl: template?.thumbnail_url,
          name: value?.name,
        },
        emailTemplateId: value?.id,
      } satisfies BaseEmailEmailTemplatePairTableRow
  })
})

const createColumns = (): DataTableColumns<BaseEmailEmailTemplatePairTableRow> => {
  return [
    {
      title: 'Base Email',
      key: 'baseEmail',
      render: (row) => {
        return <div
          flex="~"
          gap="2"
        >
          <NImage
            src={row.baseEmail.thumbnailUrl}
            w="20"
            object-fit="cover"
            img-props={{w: 'full'}}
          />
          <CopyableContent>{row.baseEmail.name}</CopyableContent>
        </div>
      },
    },
    {
      title: 'Email Template ID',
      key: 'emailTemplateId',
      render: (row) => {
        return <CopyableContent>{row.emailTemplateId}</CopyableContent>
      },
    }
  ]
}
</script>
<template>
  <NDataTable class="not-prose" :columns="createColumns()" :data="templatesTableData" m="y-4">
    <template #empty>
      <p>No templates found. Make sure variants are configured.</p>
    </template>
  </NDataTable>
</template>
