<script setup lang="ts">
import ChooseTemplate from './choose/Form.vue'
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'
import { TemplatesSelectionSteps } from '@/level4/data/forms'

const formSteps = [
  {
    name: TemplatesSelectionSteps.CHOOSE,
    component: ChooseTemplate,
  },
]
</script>

<template>
  <div>
    <L4WizardContainer :steps="formSteps" />
  </div>
</template>
