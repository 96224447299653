<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import { Level4Module } from '../data/forms'
import type { FormExposedProperties } from '../utils/level4Forms'
import type { WizardStep } from './useWizard'
import { useWizard } from './useWizard'
import FormWrapperL4 from '@/level4/components/layout/FormWrapperL4.vue'

const props = defineProps<{
  steps: WizardStep[] | (() => Promise<WizardStep[]>)
  layout?: Component
  redirectAfterLastStep?: (() => Promise<RouteLocationRaw>)
  redirectOnQuit?: (() => Promise<RouteLocationRaw>)
}>()

const currentStepRef = ref<FormExposedProperties | null>(null)

const {
  next,
  skip,
  prev,
  stepSkippable,
  stepIsCompleted,
  currentStep,
  wizardDirection,
  isFirstStep,
} = useWizard(
  props.steps,
  currentStepRef,
  props.redirectAfterLastStep,
  props.redirectOnQuit,
)

const route = useRoute()

const currentModule = route.name as Level4Module

const submitPending = ref(false)

const transitionName = computed(() => {
  return wizardDirection.value === 'forward' ? 'slide-fade-left' : 'slide-fade-right'
})

const submitButtonText = computed(() => {
  if (currentStepRef.value && 'nextFormButtonText' in currentStepRef.value) {
    return currentStepRef.value.nextFormButtonText
  }

  return 'Save & Continue'
})

const backButtonText = computed(() => {
  if (currentStepRef.value && 'backFormButtonText' in currentStepRef.value) {
    return currentStepRef.value.backFormButtonText
  }
  return 'Back'
})

async function nextHandler() {
  submitPending.value = true
  try {
    await next()
  }
  catch (error) {
    console.error(error)
  }
  submitPending.value = false
}

function skipHandler() {
  skip()
}

function prevHandler() {
  prev()
}
</script>

<template>
  <Component :is="props.layout || FormWrapperL4" :completed="stepIsCompleted" stickyHeader>
    <template v-if="Object.values(Level4Module).includes(currentModule)" #header-content>
      <div flex="~" justify="center" p="x-2">
        <TestEmailsMinimap v-if="currentModule === Level4Module.TEST_EMAILS" :stepName="currentStep?.name || ''" />
        <ExperimenterMinimap v-else />
      </div>
    </template>
    <div class="h-full w-full flex flex-col gap-4">
      <Transition :name="transitionName" mode="out-in" :appear="isFirstStep">
        <div :key="`key-${currentStep?.name}`">
          <div class="h-full w-full flex flex-col gap-4">
            <Component :is="currentStep?.component" ref="currentStepRef" :stepName="currentStep?.name" @submit="nextHandler" @back="prevHandler" />
          </div>
          <div v-if="!currentStepRef?.areControlsDisabled" class="pb-10">
            <div class="flex items-center justify-center gap-4">
              <SimpleButton :text="backButtonText" @click="prevHandler" />
              <SimpleButton v-if="stepSkippable" primary :text="submitButtonText" @click="skipHandler" />
              <SimpleButton v-else :disabled="!stepIsCompleted" primary :text="submitButtonText" :loading="submitPending" @click="nextHandler" />
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </Component>
</template>

<style scoped>
/* Slide+fade transition */
.slide-fade-left-leave-active,
.slide-fade-right-leave-active {
    transition: transform 0.3s cubic-bezier(0.73, 0, 0.75, 1.02),
        opacity 0.25s cubic-bezier(0.73, 0, 0.75, 1.02);
}

.slide-fade-right-enter-active,
.slide-fade-left-enter-active {
    transition: transform 0.3s cubic-bezier(0.43, 0.01, 0.13, 1),
        opacity 0.25s cubic-bezier(0.43, 0.01, 0.13, 1);
}

.slide-fade-left-enter-to,
.slide-fade-right-enter-to {
    transform: translateX(0);
    opacity: 1;
}

.slide-fade-right-leave-to,
.slide-fade-left-leave-to {
    opacity: 0;
}

.slide-fade-right-leave-to,
.slide-fade-left-enter-from {
    transform: translateX(10%);
}

.slide-fade-left-leave-to,
.slide-fade-right-enter-from {
    transform: translateX(-10%);
}
</style>
