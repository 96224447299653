import { store } from '@app/store'
import type { MetadataModel } from '@/pipelines/models/server/PipelineModel.ts'
import type { INTEGRATION_PLATFORM } from '@/level4/data/l4_constants'
import type { PipelineConfigModel } from '@/pipelines/models/PipelineConfigModel.ts'

export function useLvl4PipelineConfig() {
  const pipelineConfig = computed<PipelineConfigModel | undefined>(() => store.getters['pipeline/pipeline']?.config)
  const pipelineMetadata = computed<MetadataModel | undefined>(() => store.getters['pipeline/pipeline']?.metadata)

  const getAudienceIds = (platform: INTEGRATION_PLATFORM, experimenter: string) => {
    const audiencesObj: { [key: string]: string[] } = pipelineMetadata.value?.ingestion?.[platform]?.base_audiences ?? {}
    return Object.keys(audiencesObj).filter((key) => {
      const array = audiencesObj[key]
      return Array.isArray(array) && array.includes(experimenter)
    })
  }

  const getAudienceDataExtensions = (platform: INTEGRATION_PLATFORM) => {
    return pipelineConfig.value?.ingestion?.[platform]?.data_extensions ?? []
  }

  return {
    ...pipelineConfig,
    getAudienceIds,
    getAudienceDataExtensions,
  }
}
