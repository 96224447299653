<script setup lang="ts">
interface Option {
  key: string
  label: string
  content: VNode
}
interface Props {
  options: Option[]
}

defineProps<Props>()

const selected = defineModel<Options['key']>({ required: true })
</script>

<template>  
  <div class="grid-toggle-cards grid grid-cols-2 items-stretch gap-4">
    <div class="toggle-card cursor-pointer" v-for="option in options" :key="option.key" @click="selected = option.key" :selected="selected === option.key" rounded="md" relative="~">
      <div class="i-solar-check-circle-outline" v-if="selected === option.key" absolute="~" top="2" left="2" z="10" text="offerfit-bright-purple"></div>
      <NCard :segmented="{ footer: true }" h="full" rounded="!md" hoverable>
        <div class="option-content p-4">
          <component class="h-14 w-full" :is="option.content"></component>
        </div>
        <template #footer>
          <div class="label text-center">{{ option.label }}</div>
        </template>
      </NCard>
    </div>
  </div>
</template>

<style scoped>
.toggle-card {
  &[selected="true"] {
    @apply ring-offerfit-bright-purple ring-1;
    .label {
      @apply text-offerfit-bright-purple font-bold;
    }
  }
}
</style>
