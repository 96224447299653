<script setup lang="ts">
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'
import ConfigureKeys from './configure-keys/Form.vue'
import { SFMCSteps } from '@/level4/data/forms'

const formSteps = [
    {
        name: SFMCSteps.PLATFORM_CONFIGURATION_SMFC_CONFIGURE_KEYS,
        component: ConfigureKeys,
    },
]

</script>

<template>
  <div>
    <L4WizardContainer :steps="formSteps" />
  </div>
</template>
