<script lang="ts">
import type { NullString } from '@shared/utils/Types'

export default defineComponent({
  name: 'BlueInfoMessage',
  props: {
    title: String as PropType<NullString>,
    subtitle: String as PropType<NullString>,
    extendedSubtitle: String as PropType<NullString>,
    link: String as PropType<NullString>,
    closable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ['dismiss'],
  setup(props, { emit }) {
    const expanded = ref<boolean>(false)
    const dismiss = () => {
      emit('dismiss', true)
    }
    return {
      dismiss,
      expanded,
    }
  },
})
</script>

<template>
  <div class="background rounded-md p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <div class="icon i-solar-info-circle-bold" />
      </div>
      <div class="ml-3">
        <div v-if="title" class="text-sm font-medium">{{ title }}</div>
        <div v-if="subtitle" class="mt-2 text-sm text-gray-500">
          <div>{{ subtitle }}</div>
          <div v-if="expanded && extendedSubtitle">
            {{ extendedSubtitle }}
            <div v-if="!!link" class="flex justify-start">
              <a :href="link" target="_blank" class="cursor-pointer text-offerfit-bright-purple underline">Learn more</a>
            </div>
          </div>
        </div>
        <slot />
      </div>
      <div v-if="closable" class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button
            type="button"
            class="inline-flex rounded-md bg-blue-50 p-1 text-offerfit-purple hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue focus:ring-offset-violet-50"
            @click="dismiss"
          >
            <span class="sr-only">Dismiss</span>
            <span class="i-solar-close-circle-outline text-gray-800"></span>
          </button>
        </div>
      </div>
    </div>
    <div v-if="extendedSubtitle" class="flex justify-end text-sm">
      <span class="cursor-pointer text-offerfit-bright-purple underline" @click="expanded = !expanded">{{ expanded ? 'Read less' : 'Read more' }}</span>
    </div>
  </div>
</template>

<style scoped>
.background {
  background-color: #F4F9FF;
}
.icon {
  color: #549DF1;
}
</style>
