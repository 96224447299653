<script setup lang="tsx">
import type { DataTableColumns } from 'naive-ui'
import CopyableContent from '@shared/components/basic/utilitites/CopyableContent.vue'

interface NameExternalKeyPairTableRow {
  name: string
  externalKey: string
}
interface Props {
  data?: NameExternalKeyPairTableRow[]
}

withDefaults(defineProps<Props>(), { data: () => ([{
    name: '[Name dynamically populated by OF]',
    externalKey: '[Name dynamically populated by OF]',
  }]), })
const emit = defineEmits<{}>()

const createColumns = (): DataTableColumns<NameExternalKeyPairTableRow> => {
  const rows = [
    {
      title: 'Name',
      key: 'name',
      render: (row) => {
        return <CopyableContent>{row.name}</CopyableContent>
      },
    }
  ]
  if (!!__props.data?.[0]?.externalKey) {
    rows.push({
      title: 'External Key',
      key: 'externalKey',
      render: (row) => {
        return <CopyableContent>{row.externalKey}</CopyableContent>
      },
    })
  }
  return rows
}
</script>

<template>
  <NDataTable class="not-prose" :columns="createColumns()" :data="data" m="y-4"></NDataTable>
</template>
