<script lang="ts" setup>
import { INTEGRATION_PLATFORM } from '@/level4/data/l4_constants'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { Level4Module } from '@/level4/data/forms'

const { experimenterFormState, setMarketingPlatform } = useExperimenterFormState()

const platforms = [{
  key: Level4Module.BRAZE,
  label: 'Braze',
  content: h('div', { class: 'i-logos-braze' }),
}, {
  key: Level4Module.SFMC,
  label: 'Salesforce Marketing Cloud',
  content: h('div', { class: 'i-logos-salesforce' }),
}, {
  key: Level4Module.KLAVIYO,
  label: 'Klaviyo',
  content: h('div', { class: 'i-custom:klaviyo-logo' }),
}]

const currentPlatform = ref<undefined | INTEGRATION_PLATFORM>(undefined)
const dataSharingAgreement = ref(false)
syncRefs(() => experimenterFormState.value?.platform, currentPlatform, { immediate: true })

const platformDataSharingAgreement = computed(() => {
  if (currentPlatform.value === INTEGRATION_PLATFORM.BRAZE) {
    return experimenterFormState.value?.brazeDataSharingAgreement ?? false
  }
  else if (currentPlatform.value === INTEGRATION_PLATFORM.SFMC) {
    return experimenterFormState.value?.sfmcDataSharingAgreement ?? false
  }
  else if (currentPlatform.value === INTEGRATION_PLATFORM.KLAVIYO) {
    return experimenterFormState.value?.klaviyoDataSharingAgreement ?? false
  }
  return false
})

watch(platformDataSharingAgreement, () => {
  dataSharingAgreement.value = platformDataSharingAgreement.value
}, { immediate: true })

const showThankYouScreen = ref(false)

defineExpose<FormExposedProperties>({
  dataIsComplete: computed(() => experimenterFormState.value?.platform !== currentPlatform?.value && !!currentPlatform?.value && dataSharingAgreement.value),
  skipIsAvailable: computed(() => !!experimenterFormState.value?.platform && platformDataSharingAgreement.value),
  submitHandler: async () => {
    await setMarketingPlatform(currentPlatform.value!)
  },
})
</script>

<template>
  <section class="grid mx-auto max-w-3xl items-center justify-center gap-10">
    <template v-if="!showThankYouScreen">
      <div class="header-info text-center">
        <h2 class="pre-header">
          Let’s design your experimenter! First, we need some emails.
        </h2>
        <h1 class="header text-2xl font-bold">
          What platform do you use to send emails to your customers?
        </h1>
      </div>
      <GridToggleCards v-model="currentPlatform" :options="platforms" />
      <div class="different-platform-cta flex flex-col items-center justify-center gap-4">
        <NCheckbox v-if="currentPlatform === INTEGRATION_PLATFORM.BRAZE" v-model:checked="dataSharingAgreement"
          label="I agree to share data from Braze to OfferFit. " />
        <NCheckbox v-if="currentPlatform === INTEGRATION_PLATFORM.SFMC" v-model:checked="dataSharingAgreement"
          label="I agree to share data from Salesforce Marketing Cloud (SFMC) to OfferFit. " />
        <NCheckbox v-if="currentPlatform === INTEGRATION_PLATFORM.KLAVIYO" v-model:checked="dataSharingAgreement"
          label="I agree to share data from Klaviyo to OfferFit. " />
      </div>
    </template>
    <template v-else>
      <div class="header-info flex flex-col gap-4 text-center">
        <h1 class="header text-2xl">
          Thank you for sharing with us!<br>We will notify you when OfferFit Email will support your platform!
        </h1>
        <h2 class="pre-header">
          Meanwhile, you can schedule a demo of the full version of OfferFit, which integrates with any platform, <br> or
          check out demo experimenter to explore all OfferFit Email possibilities.
        </h2>
      </div>
      <div class="actions" flex="~ col" gap="2" items="center">
        <NButton quaternary type="primary" font="!semibold">
          Schedule a full version demo
          <template #icon>
            <div class="i-solar-calendar-add-outline" />
          </template>
        </NButton>
        <NButton quaternary type="primary" font="!semibold">
          Explore OfferFit Email demo experimenter
          <template #icon>
            <div class="i-solar-square-top-down-outline" />
          </template>
        </NButton>
      </div>
      <div class="footer" flex="~ col" items="center">
        <NButton type="primary">
          Back to home
        </NButton>
      </div>
    </template>
</section></template>
