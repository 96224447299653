<script setup lang="ts">
import { useExperimenterFormState } from '../../composables/useExperimenterFormState'
import { PLATFORM_DISPLAY_NAME } from '@/level4/data/l4_constants'

const { experimenterFormState } = useExperimenterFormState()
</script>

<template>
  <NAlert
    type="error"
    title="You’ve reached the maximum of 1000 email variants per experimenter."
  >
    You can create new variants of these emails, import additional base emails from {{ PLATFORM_DISPLAY_NAME[experimenterFormState.value?.platform || ''] }}, or experiment with these variants. Each experimenter can include up to 500 variants.
  </NAlert>
</template>

<style scoped>

</style>
